import React, { FC } from 'react';

import { Empty } from 'antd';
import cn from 'classnames';
import SectionTitle from 'component/section-title/sectionTitle';
import { formatNumber } from 'tools/formatNumbers';
import { Typography } from 'ui';

import ProgressCircle from './components/progress-circle';

import { TicketDataType } from 'types/events/eventTypes';

import s from './DashboardTicketSoldTable.module.scss';

interface DashboardTicketSoldTableProps {
  ticketTypes?: TicketDataType[];
}

const DashboardTicketSoldTable: FC<DashboardTicketSoldTableProps> = (props) => {
  const { ticketTypes } = props;

  if (ticketTypes?.length === 0) {
    return (
      <div className={cn(s.wrapper)}>
        <div className={s.header}>
          <div>
            <SectionTitle content="Ticket Types Sold" className={s.title} withOutMargin />
            <Typography type="main" color="grey">
              Issued tickets overview
            </Typography>
          </div>
        </div>
        <div className={s.emptyContent}>
          <Empty description="No data available" />
        </div>
      </div>
    );
  }

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div>
          <SectionTitle content="Ticket Types Sold" className={s.title} withOutMargin />
          <Typography type="main" color="grey">
            Overview of tickets sold per ticket type
          </Typography>
        </div>
      </div>
      <div className={s.content}>
        {ticketTypes?.map((type) => {
          const hasMaxTickets = type.maximumNumberOfTickets !== 0;
          const percentage = hasMaxTickets
            ? (type.qtyOfTicketsSold / type.maximumNumberOfTickets) * 100
            : 0;
          return (
            <div key={type.type} className={s.item}>
              <ProgressCircle size={40} radius={32} stroke={8} percentage={percentage} />
              <div className={s.totalValue}>
                <Typography type="main" className={s.title}>
                  {type.qtyOfTicketsSold}
                </Typography>
                <Typography type="small" color="grey">
                  {type.type}
                </Typography>
              </div>
              <div className={s.totalValue}>
                <Typography type="main" className={s.title}>
                  {type.numberOfComplimentaries}
                </Typography>
                <Typography type="small" color="grey">
                  Complimentary
                </Typography>
              </div>
              <div>
                <Typography type="main" className={s.title}>
                  € {formatNumber(type.totalAmount)}
                </Typography>
                <Typography type="small" color="grey">
                  Total Amount
                </Typography>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DashboardTicketSoldTable;
