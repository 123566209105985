import React, { FC, useEffect, useMemo } from 'react';

import cn from 'classnames';
import { Form, FormInstance, Select, Typography } from 'ui';

import { useLazyGetSeatingPlansQuery } from 'services/events/eventsApiService';

import s from 'pages/events/events-create-event-form/event-create-form-basic-info/BasicInfo.module.scss';

interface EventCreateFormBasicInfoSeatingPlanProps {
  form: FormInstance;
}

const EventCreateFormBasicInfoSeatingPlan: FC<EventCreateFormBasicInfoSeatingPlanProps> = ({
  form,
}) => {
  const [getSeatingPlan, { data: seatingPlan }] = useLazyGetSeatingPlansQuery();
  const eventType = Form.useWatch('eventType', form);
  const selectOptions = useMemo(() => {
    if (seatingPlan?.length) {
      return seatingPlan.map((plan) => ({
        label: plan.name,
        value: plan.id,
      }));
    } else {
      return [];
    }
  }, [seatingPlan]);

  useEffect(() => {
    getSeatingPlan();
  }, [getSeatingPlan]);

  return (
    <div>
      <div>
        <Typography type="small" className={cn(s.label)}>
          Event type
        </Typography>
        <Form.Item name="eventType" initialValue={{ value: 'standing' }}>
          <Select
            options={[
              { label: 'Standing event', value: 'standing' },
              { label: 'Seated event', value: 'seated' },
            ]}
            placeholder="Choose event type"
          />
        </Form.Item>
      </div>
      {eventType?.value === 'seated' && (
        <div style={{ marginTop: 14 }}>
          <Typography type="small" className={cn(s.label)}>
            Seating plan
          </Typography>
          <Form.Item name="seatingPlan">
            <Select options={selectOptions} placeholder="Choose seating plan" />
          </Form.Item>
        </div>
      )}
    </div>
  );
};

export default EventCreateFormBasicInfoSeatingPlan;
