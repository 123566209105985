import { FC } from 'react';

import { DatePicker as DatePickerAntd, DatePickerProps as DatePickerAntdProps } from 'antd';
import 'antd/lib/date-picker/style/index';
import cn from 'classnames';

import s from './DatePicker.module.scss';

export type DatePickerProps = DatePickerAntdProps & {
  fullWidth?: boolean;
};
export type DatePickerOnChangeParams = Parameters<NonNullable<DatePickerProps['onChange']>>;

const DatePicker: FC<DatePickerProps> = (props) => {
  const { className = '', fullWidth, ...otherProps } = props;

  return (
    <DatePickerAntd
      className={cn(s.wrapper, { [className]: className, [s.fullWidth]: fullWidth })}
      {...otherProps}
    />
  );
};

export default DatePicker;
