import i18n from 'i18next';
import LngDetector from 'i18next-browser-languagedetector';
import en from 'locales/en.json';
import { initReactI18next } from 'react-i18next';

const resources = {
  en,
};

const FALLBACK_LANGUAGE = 'en';

i18n.use(initReactI18next).use(LngDetector).init({
  fallbackLng: FALLBACK_LANGUAGE,
  resources,
});

export const t = (
  key: string,
  params?: Record<string, string | number | boolean | undefined>,
): string => i18n.t(key, params || {});

// TEST ONLY
// export const t = (
//   key: string,
//   // eslint-disable-next-line @typescript-eslint/no-unused-vars
//   params?: Record<string, string | number | boolean | undefined>,
// ): string => key;

export default i18n;
