import { FC, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, Modal, Typography } from 'ui';

import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';

import s from './WelcomeModal.module.scss';

interface WelcomeModalProps {
  // Define your component props here
}

const WelcomeModal: FC<WelcomeModalProps> = () => {
  const [open, setOpen] = useState<boolean>();
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const goToSettings = () => {
    window.localStorage.setItem('onboarding', 'true');
    navigate('/organiser-details');
  };

  useEffect(() => {
    getCurrentUser().then(({ data }) => {
      if (data?.promoters[0]?.promoterId || data?.hasEvents) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    });
  }, [getCurrentUser]);

  return (
    <Modal handleClose={handleClose} open={open} footer={false}>
      <Modal.Header>
        <Typography type="h4">👋 Hey there!</Typography>
      </Modal.Header>
      <Modal.Content>
        <div className={s.wrapper}>
          <Typography type="main" color="grey" className={s.text}>
            Welcome to ShowsManger!
          </Typography>
          <div>
            <Typography type="main" color="grey" className={s.text}>
              Use this tool to create and publish your event. You can start preparing everything and
              publish later.
            </Typography>
            <Typography type="main" color="grey" className={s.text}>
              To start working with our service, please fill in the data about the organizer
            </Typography>
          </div>
          <Typography type="main" color="grey" className={s.text}>
            {`Click 'Get Started' to fill in your organiser details before creating your first event.`}
          </Typography>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button fullWidth onClick={goToSettings}>
          Get Started
        </Button>
        <Button fullWidth color="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WelcomeModal;
