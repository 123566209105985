import Option, { OptionProps } from './option';
import SelectParent, { SelectProps, THandleSelectValue } from './select';

export type SelectParentType = typeof SelectParent;

export interface SelectType extends SelectParentType {
  Option: typeof Option;
}

const Select = SelectParent as SelectType;

Select.Option = Option;
export type { SelectProps, OptionProps, THandleSelectValue };
export default Select;
