import { FC, useState } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import { extractFlyerName } from 'tools/extractFlyerName';
import { formatNumber } from 'tools/formatNumbers';
import { Icon, Typography } from 'ui';

import EventItemsTools from 'pages/events/event-list-block-view/event-item-tools';

import EventsEmptyState from 'containers/events-empty-state';

import { EventWithMetricsType } from 'types/events/eventTypes';

import { CURRENCIES_SETTING } from '../../../constants/core-constants';
// import EventListActionsDropdown from '../event-list-actions-dropdown';
import s from './EventsListBlockView.module.scss';

interface EventListBlockViewProps {
  list?: EventWithMetricsType[];
}
const EventListBlockView: FC<EventListBlockViewProps> = (props) => {
  const { list } = props;
  const [isShowedAllDates, setIsShowedAllDates] = useState(false);
  const [itemOpenStates, setItemOpenStates] = useState({});

  const toggleItemOpenState = (eventId) => {
    setItemOpenStates((prevState) => ({
      ...prevState,
      [eventId]: !prevState[eventId],
    }));
  };

  if (!list?.length) {
    return (
      <div className={s.empty}>
        <EventsEmptyState />
      </div>
    );
  }

  return (
    <div className={s.wrapper}>
      {list?.map((event) => {
        const metricEvent = event?.metrics;

        return (
          <div key={event.eventId}>
            <div className={s.eventItem}>
              {extractFlyerName(event?.eventFlyer) ? (
                <img
                  src={event?.eventFlyer}
                  alt="eventFlyer"
                  className={cn(s.eventFlyer, {
                    [s.fullHeight]: isShowedAllDates && event?.dates?.length > 1,
                  })}
                />
              ) : (
                <div className={s.emptyFlyer}>
                  <Icon name="littleLogo" size={50} />
                </div>
              )}

              <div className={s.eventDetails}>
                <div className={s.header}>
                  <div>
                    <Typography type="h4">{event?.eventName}</Typography>
                    <Typography type="main" color="grey">
                      Owner: <b>{event?.promoter?.email}</b>
                    </Typography>
                  </div>
                  {/*<EventListActionsDropdown event={event} />*/}
                  <div className={s.actions}>
                    <a href={event?.url} target="_blank" className={cn(s.action, s.view)}>
                      <div className={s.icon}>
                        <Icon name="goTo" size={16} />
                      </div>
                      <Typography className={s.text} type="main">
                        View Event
                      </Typography>
                    </a>
                    <button
                      className={s.showButton}
                      onClick={() => toggleItemOpenState(event.eventId)}>
                      <Icon name="setting" size={18} />
                      <Typography type="main" weight={500}>
                        Tools
                      </Typography>
                    </button>
                  </div>
                </div>
                <div className={s.locations}>
                  <div>
                    <Typography type="main" weight={500} className={s.dateField}>
                      {event?.dates?.length <= 1 ? (
                        dayjs(event?.dates[0]?.date).format('DD MMMM YYYY')
                      ) : (
                        <>
                          {dayjs(event?.dates[0]?.date).format('DD MMMM YYYY')}{' '}
                          <button
                            className={s.showButton}
                            onClick={() => setIsShowedAllDates(!isShowedAllDates)}>
                            <Typography type="small" color="grey" className={s.block}>
                              {isShowedAllDates ? 'Hide' : 'Show'} all dates{' '}
                              <Icon
                                name="arrowDropdown"
                                size={14}
                                className={cn({ [s.active]: isShowedAllDates })}
                              />
                            </Typography>
                          </button>
                        </>
                      )}
                    </Typography>
                    <div className={s.dates}>
                      {isShowedAllDates &&
                        event?.dates?.slice(1)?.map((date) => (
                          <Typography type="main" weight={500} key={date?.date}>
                            {dayjs(date?.date).format('DD MMMM YYYY')}
                          </Typography>
                        ))}
                    </div>
                  </div>
                  <Typography type="main" color="grey" className={s.dateField}>
                    <div className={s.marker}>
                      <Icon name="mapMarker" size={14} />
                    </div>
                    {event?.venue?.name || '-'}
                  </Typography>
                </div>
                <div className={s.metricsBlock}>
                  <div className={s.metric}>
                    <Typography type="main" color="grey" className={s.title}>
                      Total Revenue
                    </Typography>
                    <Typography type="h4" className={s.subtitle}>
                      {CURRENCIES_SETTING[metricEvent?.currency?.toUpperCase() || 'EUR']?.symbol}{' '}
                      {formatNumber(metricEvent?.totalSales?.allTime)}
                    </Typography>
                    {!!metricEvent?.totalSales?.today && (
                      <Typography type="main" color="grey" className={s.today}>
                        <span className={s.green}>
                          + € {formatNumber(metricEvent?.totalSales?.today)}
                        </span>
                        today
                      </Typography>
                    )}
                  </div>
                  <div className={s.metric}>
                    <Typography type="main" color="grey" className={s.title}>
                      Tickets Sold
                    </Typography>
                    <Typography type="h4" className={s.subtitle}>
                      {metricEvent?.totalTicketsSold?.allTime}
                    </Typography>
                    {!!metricEvent?.totalTicketsSold?.today && (
                      <Typography type="main" color="grey" className={s.today}>
                        <span className={s.green}>+ {metricEvent?.totalTicketsSold?.today}</span>{' '}
                        today
                      </Typography>
                    )}
                  </div>
                  <div className={cn(s.metric, s.mobileHide)}>
                    <Typography type="main" color="grey" className={s.title}>
                      Complimentary
                    </Typography>
                    <Typography type="h4" className={s.subtitle}>
                      {metricEvent?.totalComplimentariesIssued?.allTime}
                    </Typography>
                    {!!metricEvent?.totalComplimentariesIssued?.today && (
                      <Typography type="main" color="grey" className={s.today}>
                        <span className={s.green}>
                          + {metricEvent?.totalComplimentariesIssued?.today}
                        </span>{' '}
                        today
                      </Typography>
                    )}
                  </div>
                  {/*<NavLink to="/dashboard" className={classNames(s.stats)}>*/}
                  {/*  <Icon name="stats" size={24} />*/}
                  {/*  <Typography type="main" weight={500}>*/}
                  {/*    more stats*/}
                  {/*  </Typography>*/}
                  {/*</NavLink>*/}
                </div>
              </div>
            </div>
            <EventItemsTools
              open={itemOpenStates[event.eventId] || list?.length <= 3}
              event={event}
            />
          </div>
        );
      })}
    </div>
  );
};

export default EventListBlockView;
