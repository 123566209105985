import { forwardRef, memo } from 'react';

import { Checkbox as CheckboxAntd } from 'antd';
import type { CheckboxGroupProps as CheckboxGroupPropsAntd } from 'antd/lib/checkbox/Group';
import cn from 'classnames';

import s from './CheckboxGroup.module.scss';

export interface CheckboxGroupProps extends CheckboxGroupPropsAntd {
  column?: boolean;
}

const CheckboxGroup = forwardRef<HTMLDivElement, CheckboxGroupProps>((props, ref) => {
  const {
    value = [],
    options = [],
    defaultValue,
    onChange,
    disabled = false,
    column = false,
    className = '',
  } = props;

  return (
    <CheckboxAntd.Group
      className={cn({ [s.column]: column, [className]: className })}
      options={options}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
      ref={ref}
    />
  );
});

export default memo(CheckboxGroup);
