import { FC, useCallback, useEffect, useState } from 'react';

import Pagination from 'component/pagination';
import SectionTitle from 'component/section-title/sectionTitle';
import { Form, TableProps } from 'ui';

import { useLazyGetEventOrdersQuery } from 'services/events/eventsApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';

import EventPopover from 'containers/event-popover';

import { UserEventType } from 'types/core/userTypes';

import s from './Orders.module.scss';
import OrdersHeader from './orders-header';
import OrdersList from './orders-list';
import { OrdersFilterType, initialFilter } from './orders.utils';

const Orders: FC = () => {
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const [getEventOrders, { data: orders }] = useLazyGetEventOrdersQuery();
  const [activeEvent, setActiveEvent] = useState<UserEventType>();
  const [requestFilter, setRequestFilter] = useState<OrdersFilterType>(initialFilter);
  const [form] = Form.useForm();
  const searchString = Form.useWatch('eventName', form);

  const showedElements =
    Number(orders?.orders?.length) +
    (Number(orders?.pageNumber) - 1) * Number(requestFilter.PageSize);

  const handlePageChange = useCallback((PageNumber: number) => {
    setRequestFilter((prevState) => ({
      ...prevState,
      PageNumber,
    }));
  }, []);

  const handleTableChange = useCallback<NonNullable<TableProps['onChange']>>(
    (_pag, _filter, sorter) => {
      if (!Array.isArray(sorter)) {
        if (sorter.order) {
          setRequestFilter((prevState) => ({
            ...prevState,
            SortType: sorter.order === 'ascend' ? 'Ascending' : 'Descending',
            SortByColumn: sorter.field === 'orderDate' ? 'byDate' : undefined,
          }));
        } else {
          setRequestFilter((prevState) => ({
            ...prevState,
            SortType: '',
            SortByColumn: '',
          }));
        }
      }
    },
    [],
  );

  const handleSetActiveEvent = useCallback((event: UserEventType) => {
    setActiveEvent(event);
  }, []);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  useEffect(() => {
    if (activeEvent?.eventId && requestFilter) {
      getEventOrders({ ...requestFilter, id: activeEvent?.eventId });
    }
  }, [activeEvent?.eventId, requestFilter, getEventOrders]);

  useEffect(() => {
    if (currentUser?.events?.length) {
      setActiveEvent(currentUser?.events[0]);
    }
  }, [currentUser, setActiveEvent]);

  useEffect(() => {
    if (searchString?.length > 3) {
      setRequestFilter((prevState) => ({
        ...prevState,
        filter: searchString,
      }));
    } else if (searchString?.length === 0) {
      setRequestFilter((prevState) => ({
        ...prevState,
        filter: '',
      }));
    }
  }, [searchString]);

  return (
    <div className={s.wrapper}>
      <div className={s.row}>
        <SectionTitle label="common_tickets_orders" className={s.title} />

        <EventPopover handleEvent={handleSetActiveEvent} />
      </div>
      <Form form={form}>
        <OrdersHeader />
      </Form>

      <div className={s.list}>
        <OrdersList ordersList={orders?.orders} handleTableChange={handleTableChange} />
        {showedElements > 0 && (
          <Pagination
            totalElements={orders?.totalRecords}
            showedElements={showedElements}
            currentPage={requestFilter?.PageNumber || 0}
            total={orders?.totalPages}
            handlePageChange={handlePageChange}
            handleSizeChange={(size) =>
              setRequestFilter((prevValue) => ({ ...prevValue, PageSize: size }))
            }
          />
        )}
      </div>
    </div>
  );
};

export default Orders;
