import InternalCheckbox, { CheckboxProps } from './checkbox';
import CheckboxGroup, { CheckboxGroupProps } from './checkbox-group';

export type CompoundedComponent = typeof InternalCheckbox & {
  Group: typeof CheckboxGroup;
};

const Checkbox = InternalCheckbox as CompoundedComponent;
Checkbox.Group = CheckboxGroup;

export type { CheckboxProps, CheckboxGroupProps };
export default Checkbox;
