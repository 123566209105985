export const LOGIN_ROUTE = '/login';
export const REGISTER_ROUTE = '/register';

export const FORGOT_PASSWORD_ROUTE = '/reset-password';

export const REDIRECT_ROUTE = '/redirect';

export const USER_SETTING_ROUTE = '/organiser-details';

export const USER_SETTING_BANK_DETAILS_ROUTE = '/bank-details';
