import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { useQuery, useSetQuery } from 'tools/hooks';
import { t } from 'tools/i18n';
import { Button, Icon, Layout, Typography, message } from 'ui';

import { createEventFormEditingEventIdSelector } from 'selectors/eventsSlice.selector';
import {
  useLazyGetEventDetailQuery,
  usePauseEventMutation,
  useRestartEventMutation,
} from 'services/events/eventsApiService';
import { setActiveStep } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store';

import AppNavigationEventInfo from 'containers/app-navigation/app-navigation-event-info';
import PublishEventModal from 'containers/publish-event-modal/publishEventModal';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';

import s from './CreateEventNavigationTemplate.module.scss';

interface CreateEventNavigationTemplateProps {
  handleLogout: () => void;
  sameString: (str: string) => boolean;
  handleNavigate: (path: string) => void;
  isOpenedSidebar: boolean;
  apiVersion?: { versionNumber: string };
}

const CreateEventNavigationTemplate: FC<CreateEventNavigationTemplateProps> = (props) => {
  const { handleLogout, isOpenedSidebar, apiVersion } = props;
  const [openedPublishEventModal, setOpenedPublishEventModal] = useState(false);
  const [publishEvent, { isLoading: isPublishLoading }] = useRestartEventMutation();
  const [pauseEvent, { isLoading }] = usePauseEventMutation();
  const [getEventDetail, { data: activeEvent }] = useLazyGetEventDetailQuery();
  const activeEventId = useAppSelector(createEventFormEditingEventIdSelector);
  const dispatch = useAppDispatch();
  const setQuery = useSetQuery();
  const query = useQuery();
  const stepsBeforeActive = useMemo(() => {
    const index: number = Object.keys(CREATE_EVENT_FORM_STEPS_ENUM).indexOf(
      query.get('step') as CREATE_EVENT_FORM_STEPS_ENUM,
    );
    return Object.keys(CREATE_EVENT_FORM_STEPS_ENUM).slice(0, index);
  }, [query]);
  const buttonSettings = {
    publish: ['draft', 'acceptedTerms'].includes(activeEvent?.basicInfo?.status || ''),
    pause: ['published', 'approved'].includes(activeEvent?.basicInfo?.status || ''),
    replay: ['paused'].includes(activeEvent?.basicInfo?.status || ''),
  };

  const handleChangeStep = useCallback(
    (step: string) => {
      if (activeEvent?.eventId) {
        setQuery(step);
        dispatch(setActiveStep(step as CREATE_EVENT_FORM_STEPS_ENUM));
      }
    },
    [activeEvent?.eventId, dispatch, setQuery],
  );

  const handlePauseEvent = useCallback(async () => {
    const res = await pauseEvent({ id: activeEvent?.eventId });

    if ('data' in res) {
      message.success('Event successfully paused!');
    }
  }, [activeEvent?.eventId, pauseEvent]);

  const handlePublishEvent = useCallback(async () => {
    const res = await publishEvent({
      id: activeEvent?.eventId,
    });

    if ('data' in res) {
      message.success('Event successfully restarted!');
    }
  }, [activeEvent?.eventId, publishEvent]);

  useEffect(() => {
    if (activeEventId) {
      getEventDetail(activeEventId);
    }
  }, [activeEventId, getEventDetail]);

  useEffect(() => {
    if (!query.get('step')) {
      setQuery(CREATE_EVENT_FORM_STEPS_ENUM.BASIC_INFO);
    }
  }, [query, setQuery]);

  return (
    <Layout.Sider opened={isOpenedSidebar} theme="light" type="setting">
      <div className={cn(s.wrapper, s.light)}>
        <div className={s.logoLight}>
          <Icon name="logo" width={252} height={40} />
        </div>
        <AppNavigationEventInfo />
        <div className={cn(s.content, s.setting)}>
          {Object.keys(CREATE_EVENT_FORM_STEPS_ENUM).map((key, i) => (
            <button
              key={key}
              className={cn(s.navLink, s.creation, {
                [s.active]: key === query.get('step'),
                [s.linked]: activeEvent?.eventId,
                [s.permissions]: key === CREATE_EVENT_FORM_STEPS_ENUM.PERMISSIONS,
                [s.filled]: stepsBeforeActive.includes(key),
              })}
              onClick={() => handleChangeStep(key)}>
              {stepsBeforeActive.includes(key) ? (
                <Icon name="finish" size={18} />
              ) : key === CREATE_EVENT_FORM_STEPS_ENUM.PERMISSIONS ? (
                <Icon name="permission" size={isOpenedSidebar ? 16 : 22} />
              ) : key === CREATE_EVENT_FORM_STEPS_ENUM.PROMOTE ? (
                <Icon name="promote" size={16} />
              ) : (
                <div className={s.count}>{i + 1}</div>
              )}
              <div className={s.label}>{t(`common_${key.toLowerCase()}`)}</div>
            </button>
          ))}
        </div>
        <div className={s.actionBlock}>
          {buttonSettings.publish && (
            <Button fullWidth onClick={() => setOpenedPublishEventModal(true)} size="large">
              {t('common_publish_event')}
            </Button>
          )}
          {buttonSettings.pause && (
            <Button
              fullWidth
              onClick={handlePauseEvent}
              size="large"
              color="white"
              isLoading={isLoading}>
              <Icon name="pause" />
              {t('event_pause_event')}
            </Button>
          )}
          {buttonSettings.replay && (
            <Button fullWidth onClick={handlePublishEvent} size="large" loading={isPublishLoading}>
              <Icon name="play" />
              {t('common_publish_event')}
            </Button>
          )}

          <button onClick={handleLogout} className={s.actions}>
            <Typography type="main" weight={500} className={s.logout}>
              <Icon name="logout" />
              {isOpenedSidebar && <span className={s.text}>{t('common_log_out')}</span>}
            </Typography>
          </button>
          <div className={s.version}>{`ShowsManager ${apiVersion?.versionNumber || 'v.1.0'}`}</div>
        </div>
      </div>
      <PublishEventModal
        open={openedPublishEventModal}
        handleClose={() => setOpenedPublishEventModal(false)}
      />
    </Layout.Sider>
  );
};

export default CreateEventNavigationTemplate;
