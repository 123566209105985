import { FC, PropsWithChildren, memo } from 'react';

import cn from 'classnames';

import s from './Content.module.scss';

interface ContentProps {
  className?: string;
  isAuth?: boolean;
}

const Content: FC<PropsWithChildren<ContentProps>> = (props) => {
  const { children, className = '', isAuth } = props;

  return (
    <div id="main" className={cn(s.notAuth, { [s.content]: isAuth, [className]: className })}>
      {children}
    </div>
  );
};

export default memo(Content);
