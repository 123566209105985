export const SERVICES_TAGS: { [key: string]: string } = {
  CREATE_ASSETS: 'CREATE_ASSETS',
  GET_ACCOUNTS: 'GET_ACCOUNTS',
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  GET_USER: 'GET_USER',
  GET_USERS: 'GET_USERS',
  UPDATE_PROMOTER: 'UPDATE_PROMOTER',
  PROMOTER_FULL_INFO: 'PROMOTER_FULL_INFO',
  SET_PERMISSION: 'SET_PERMISSION',
  GET_USERS_EVENT: 'GET_USERS_EVENT',
};
