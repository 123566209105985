import { FC } from 'react';

import { Trans } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { t } from 'tools/i18n';
import { Form, Icon, Label, TextArea, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { EVENT_CREATE_FORM_DETAILS_ENUM } from '../eventCreateFormDetails.utils';
import s from './EventCreateFormDetailsDescription.module.scss';
import { formats, modules } from './eventCreateFormDetailsDescription.utils';

const EventCreateFormDetailsDescription: FC = () => {
  return (
    <div className={s.wrapper}>
      <Typography type="h2" className={s.title}>
        {t('common_description')}
      </Typography>
      <div className={s.form}>
        <div className={s.content}>
          <div>
            <Label>{t('common_summary')}</Label>
            <Form.Item name={EVENT_CREATE_FORM_DETAILS_ENUM.SUMMARY}>
              <TextArea
                name="summary"
                maxLength={400}
                placeholder={t('event_create_form_summary_placeholder')}
              />
            </Form.Item>
          </div>

          <div>
            <Label required>{t('common_full_description')}</Label>
            <Form.Item
              name={EVENT_CREATE_FORM_DETAILS_ENUM.FULL_DESCRIPTION}
              rules={[formRules.required]}>
              <ReactQuill
                theme="snow"
                modules={modules}
                formats={formats}
                className={s.editor}
                placeholder={t('event_create_form_full_description_placeholder')}
              />
            </Form.Item>
          </div>
        </div>
        <div className={s.content}>
          <div className={s.hint}>
            <div>
              <Icon name="ideaColor" />
            </div>
            <Trans i18nKey="event_create_form_description_first_tip" components={{ b: <b /> }} />
          </div>
          <div className={s.hint}>
            <div>
              <Icon name="ideaColor" />
            </div>
            <Trans i18nKey="event_create_form_description_second_tip" components={{ b: <b /> }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCreateFormDetailsDescription;
