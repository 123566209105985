import React, { FC } from 'react';

import { Empty } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from 'tools/formatNumbers';
import { Typography } from 'ui';

import { TicketType } from 'types/tickets/tickets.types';

import SectionTitle from '../../../component/section-title/sectionTitle';
import { TICKETS_ORDERS_ROUTE, getOrdersFullRoute } from '../../../routes/tikets/list';
import s from './DashboardTicketOrdersTable.module.scss';

interface DashboardTicketOrdersTableProps {
  orders?: TicketType[];
}

const DashboardTicketOrdersTable: FC<DashboardTicketOrdersTableProps> = (props) => {
  const { orders } = props;
  const navigate = useNavigate();

  const handleClickRow = (order: TicketType) => {
    navigate(getOrdersFullRoute(order.orderId));
  };

  const goToOrders = () => {
    navigate(TICKETS_ORDERS_ROUTE);
  };

  if (orders?.length === 0) {
    return (
      <div className={cn(s.wrapper)}>
        <div className={s.header}>
          <div>
            <SectionTitle content="Ticket Types Sold" className={s.title} withOutMargin />
            <Typography type="main" color="grey">
              Issued tickets overview
            </Typography>
          </div>
        </div>
        <div className={s.emptyContent}>
          <Empty description="No data available" />
        </div>
      </div>
    );
  }

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div>
          <SectionTitle content="Ticket Orders" className={s.title} withOutMargin />
          <Typography type="main" color="grey">
            Latest ticket orders
          </Typography>
        </div>
      </div>
      <div className={s.content}>
        {orders?.map((order) => (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
          <div key={order.orderId} className={s.item} onClick={() => handleClickRow(order)}>
            <Typography type="main">{dayjs(order.orderDate).format('DD MMM, HH:mm')}</Typography>
            <Typography type="main">{order.orderNo}</Typography>
            <Typography type="main">{order.numberOfPersons} tickets</Typography>
            <Typography type="main" weight={600}>
              € {formatNumber(order.orderSubTotal)}
            </Typography>
          </div>
        ))}
        <div className={s.buttonWrapper}>
          <button className={s.button} onClick={goToOrders}>
            View all
          </button>
        </div>
      </div>
    </div>
  );
};

export default DashboardTicketOrdersTable;
