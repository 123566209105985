import React from 'react';

import Background from 'component/background';

import s from './Login.module.scss';
import BenefitsContent from './benefits-content';
import LoginForm from './login-form';

const Login = () => {
  return (
    <Background>
      <div className={s.wrapper}>
        <BenefitsContent />
        <LoginForm />
      </div>
    </Background>
  );
};

export default Login;
