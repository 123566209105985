import { RootState } from 'store';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { EventCreatedType } from 'types/events/eventTypes';

export const eventsSliceSelector = (state: RootState) => state.eventsSlice;

export const createEventFormActiveStepSelector = (state: RootState): CREATE_EVENT_FORM_STEPS_ENUM =>
  eventsSliceSelector(state).activeStep;

export const createEventFormActiveEventSelector = (
  state: RootState,
): EventCreatedType | undefined => eventsSliceSelector(state)?.activeEvent;

export const createEventFormEditingEventIdSelector = (state: RootState): string | undefined =>
  eventsSliceSelector(state)?.editingEventId;

export const isPublishingEventSelector = (state: RootState): boolean | undefined =>
  eventsSliceSelector(state)?.isPublishingEvent;
