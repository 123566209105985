import { Dispatch, FC, SetStateAction, useCallback, useContext, useState } from 'react';

import { t } from 'tools/i18n';
import { Button, Checkbox, Typography } from 'ui';

import MultipleDateList from 'pages/events/events-create-event-form/event-create-form-basic-info/event-create-form-basic-info-dates/multiple-dates/multiple-date-list';

import { MultipleDatesType } from 'types/events/eventTypes';

import { createEventFormBasicInfoContext } from '../../basicInfo.utils';
import SingleDate from '../single-date';
import s from './MultipleDates.module.scss';
import { prepareMultipleDates, validateFields } from './multipleDates.utils';

interface MultipleDatesProps {
  setShowsDates: Dispatch<SetStateAction<MultipleDatesType[]>>;
  multipleDates: MultipleDatesType[];
}
const MultipleDates: FC<MultipleDatesProps> = ({ setShowsDates, multipleDates }) => {
  const { form } = useContext(createEventFormBasicInfoContext);
  const [editIndex, setEditIndex] = useState<number>(-1);

  const handleClick = useCallback(() => {
    form
      ?.validateFields(validateFields)
      .then(({ showDates }) => {
        if (showDates) {
          const result = prepareMultipleDates(showDates);
          if (editIndex >= 0) {
            setShowsDates((prevState) => {
              const updatedDates = [...prevState];
              updatedDates[editIndex] = { ...updatedDates[editIndex], ...result };
              return updatedDates;
            });
            setEditIndex(-1);
          } else {
            setShowsDates((prevState) => (!prevState?.length ? [result] : [...prevState, result]));
          }

          form?.resetFields(validateFields);
        }
      })
      .catch((err) => console.log(err));
  }, [editIndex, form, setShowsDates]);

  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <div>
          <Typography type="main">{t('date_type_multiple_title')}</Typography>
          <Typography type="main">{t('date_type_multiple_text')}</Typography>
        </div>

        <div>
          <div style={{ marginBottom: 14 }}>
            <Checkbox>
              <Typography type="main" weight={600}>
                Make the event hidden for that date?
              </Typography>
            </Checkbox>
          </div>
          <SingleDate validateStartDate={!!multipleDates?.length} />
          <Button fullWidth variant="outline" color="grey" className={s.add} onClick={handleClick}>
            <div className={s.plus}>+</div>{' '}
            <div className={s.text}>{editIndex >= 0 ? 'Edit dates' : 'Add dates'}</div>
          </Button>
        </div>
      </div>
      <div className={s.content}>
        <MultipleDateList
          data={multipleDates}
          setShowsDates={setShowsDates}
          setEditIndex={setEditIndex}
          form={form}
        />
      </div>
    </div>
  );
};

export default MultipleDates;
