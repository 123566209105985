import { CSSProperties, FC, useState } from 'react';

import { rectSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { t } from 'tools/i18n';
import { Dropdown, Icon, Typography } from 'ui';

import { EventTicketType } from 'types/events/eventTypes';

import s from '../TicketsList.module.scss';

interface TicketItemProps {
  ticket: EventTicketType;
  index: number;
  id: string;
  handleDeleteItem: (id: number) => void;
  currency: string;
  setActiveEditTicket: (ticket: EventTicketType) => void;
}

const DROPDOWN_ITEMS = [
  { key: 'edit', label: <div className={s.dropdownItem}>Edit</div> },
  { key: 'delete', label: <div className={s.dropdownItem}>Delete</div> },
];

const TicketItem: FC<TicketItemProps> = ({
  ticket,
  index,
  handleDeleteItem,
  currency,
  setActiveEditTicket,
}) => {
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: `${ticket.name}_${ticket.price}_${index}`,
    strategy: rectSortingStrategy,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
      /translate3d\(([^,]+),/,
      'translate3d(0,',
    ),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  const handleClickDropdownItems = (event: { key: string }) => {
    if (event.key === 'delete') {
      setOpenedDropdown(false);
      handleDeleteItem(index);
    }

    if (event.key === 'edit') {
      setOpenedDropdown(false);
      setActiveEditTicket(ticket);
    }
  };

  const renderTicketStatus = (hidden?: boolean) => {
    if (!hidden) {
      return (
        <div className={s.status}>
          <span className={s.dot} />
          <Typography type="main" color="grey">
            {t('common_on_sold')}
          </Typography>
        </div>
      );
    }
  };

  return (
    <div ref={setNodeRef} style={style} className={s.item}>
      {/* Your existing ticket item rendering code, except for the Icon component */}
      <div {...listeners} {...attributes} ref={setActivatorNodeRef} className={s.drag}>
        <Icon name="drag" size={36} />
      </div>
      <div>
        <Typography type="h4">{ticket?.name}</Typography>
        {renderTicketStatus(ticket?.showRemainingTickets)}
      </div>
      <div>
        <Typography type="h4">{ticket.maxNumberOfTicketsToBeSold}</Typography>
        <Typography type="main" color="grey">
          {t('common_quantity')}
        </Typography>
      </div>
      <div>
        <Typography type="h4">
          {currency} {ticket.price}
        </Typography>
        <Typography type="main" color="grey">
          {t('common_face_value')}
        </Typography>
      </div>
      <Dropdown
        items={DROPDOWN_ITEMS}
        placement="bottomRight"
        handleItemClick={handleClickDropdownItems}
        open={openedDropdown}
        onOpenChange={setOpenedDropdown}>
        <div className={s.dropdownButton}>
          <Icon name="verticalDots" color="#7d7da2" />
        </div>
      </Dropdown>
    </div>
  );
};

export default TicketItem;
