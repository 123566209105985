import { FC, ReactElement, memo } from 'react';

import { Select as SelectAntd, SelectProps as SelectPropsAntd } from 'antd';
import 'antd/lib/select/style/index';
import cn from 'classnames';

import Icon from '../icon';
import Typography from '../typography';
import s from './Select.module.scss';

type TOption = {
  disabled: boolean;
  key: string;
  label: ReactElement;
  title: string;
  value: string | number;
};

export type THandleSelectValue = string | number | TOption | Array<TOption>;

export interface SelectProps extends Omit<SelectPropsAntd, 'onChange'> {
  fullWidth?: boolean;
  isLoading?: boolean;
  handleSelect?: (option: THandleSelectValue) => void;
  label?: string;
  transparent?: boolean;
}

const Select: FC<SelectProps> = (props) => {
  const {
    className = '',
    isLoading = false,
    fullWidth = false,
    labelInValue = true,
    transparent = false,
    handleSelect,
    label = '',
    suffixIcon = <Icon className={s.suffixIcon} name="arrowDropdown" />,
    menuItemSelectedIcon = <Icon className={s.selectedIcon} name="checkWide" />,
    ...otherProps
  } = props;

  return (
    <div className={cn({ [className]: className, [s.fullWidth]: fullWidth })}>
      {label && (
        <Typography color="secondary" type="small" className={s.label}>
          {label}
        </Typography>
      )}
      <SelectAntd
        suffixIcon={suffixIcon}
        menuItemSelectedIcon={menuItemSelectedIcon}
        onChange={handleSelect}
        loading={isLoading}
        labelInValue={labelInValue}
        className={cn(s.wrapper, {
          [className]: className,
          [s.fullWidth]: fullWidth,
          [s.transparent]: transparent,
        })}
        {...otherProps}
      />
    </div>
  );
};

export default memo(Select);
