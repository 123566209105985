import { EVENT_CREATE_FORM_DETAILS_ENUM } from '../eventCreateFormDetails.utils';

export const socialsFields = [
  {
    name: EVENT_CREATE_FORM_DETAILS_ENUM.FACEBOOK_LINK,
    label: 'field_social_link_facebook',
    placeholder: 'https://www.facebook.com/',
  },
  // {
  //   name: EVENT_CREATE_FORM_DETAILS_ENUM.TWITTER_LINK,
  //   label: 'field_social_link_twitter',
  //   placeholder: 'https://www.twitter.com/',
  // },
  {
    name: EVENT_CREATE_FORM_DETAILS_ENUM.INSTAGRAM_LINK,
    label: 'field_social_link_instagram',
    placeholder: 'https://www.instagram.com/',
  },
];
