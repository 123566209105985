import {
  BaseQueryApi,
  BaseQueryExtraOptions,
  BaseQueryFn,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import axios from 'axios';
import { LOGIN_ROUTE } from 'routes/user-management/list';
import { getAccessToken, getRefreshToken, removeTokenCookies } from 'tools/cookieTools';

const AUTH_ERROR_CODES = [401];
export const baseQueryWithReauth =
  (config: FetchBaseQueryArgs) =>
  async (
    args: FetchArgs | string,
    api: BaseQueryApi,
    extraOptions: BaseQueryExtraOptions<BaseQueryFn>,
  ) => {
    const accessToken = getAccessToken();
    const refreshToken = getRefreshToken();
    let result = await fetchBaseQuery(config)(args, api, extraOptions);

    if (result.error && AUTH_ERROR_CODES.includes(result.error.status as number)) {
      try {
        const isUpdated = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/users/refresh-token`,
          { accessToken, refreshToken },
        );
        if (isUpdated) {
          result = await fetchBaseQuery(config)(args, api, extraOptions);
        } else {
          if (removeTokenCookies()) {
            window.location.href = LOGIN_ROUTE;
          }
        }
      } catch {
        if (removeTokenCookies()) {
          window.location.href = LOGIN_ROUTE;
        }
      }
    }

    return result;
  };

export const BASE_QUERY_WITH_AUTH = baseQueryWithReauth({
  baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
  // credentials: 'include',
  credentials: 'same-origin',
  mode: 'cors',
  prepareHeaders: (headers) => {
    const token = getAccessToken();
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    headers.set('Access-Control-Allow-Origin', '*');
    headers.set('appkey', `${process.env.REACT_APP_KEY}`);

    return headers;
  },
});
