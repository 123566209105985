import React, { useEffect, useRef } from 'react';

import { Empty } from 'antd';
import { Chart } from 'chart.js';
import cn from 'classnames';
import SectionTitle from 'component/section-title/sectionTitle';
import { Typography } from 'ui';
import { colors } from 'utils/colors';

import { TicketDataType } from 'types/events/eventTypes';

import s from './DashboardBookingCapasityChart.module.scss';

interface ChartProps {
  data?: TicketDataType[];
}

const DashboardBookingCapasityChart: React.FC<ChartProps> = ({ data }) => {
  const chartRef = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);
  const totalValue = data?.reduce((a, b) => a + b.qtyOfTicketsSold, 0);
  const maxValue = data?.reduce((a, b) => Math.max(a, b.maximumNumberOfTickets), 0);

  useEffect(() => {
    if (chartRef && chartRef.current) {
      const labels = data?.map((d) => d.type);
      const qtyOfTicketsSold = data?.map((d) => d.qtyOfTicketsSold);
      const backgroundColors = data?.map((_, i) => colors[i % colors.length]);

      // If there's an existing chart instance, destroy it before creating a new one
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const chartData = {
        labels: labels,
        datasets: [
          {
            label: 'Tickets Sold',
            data: qtyOfTicketsSold || [],
            backgroundColors: backgroundColors || [],
            // backgroundColor: 'rgba(75,192,192,0.2)',
            // borderColor: 'rgba(75,192,192,1)',
            borderWidth: 1,
          },
        ],
      };

      chartInstance.current = new Chart(chartRef.current, {
        type: 'doughnut',
        data: chartData,
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false, // Hide the legend
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              border: {
                width: 0,
              },
              grid: {
                display: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
            x: {
              beginAtZero: true,
              border: {
                width: 0,
              },
              grid: {
                display: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
          },
        },
      });
    }
  }, [data]);

  if (data?.length === 0) {
    return (
      <div className={cn(s.wrapper, s.empty)}>
        <div className={s.header}>
          <div>
            <SectionTitle content="Booking Capacity" className={s.title} withOutMargin />
            <Typography type="main" color="grey">
              Issued tickets overview
            </Typography>
          </div>
        </div>

        <div className={s.emptyContent}>
          <Empty description="No data available" />
        </div>
      </div>
    );
  }

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div>
          <SectionTitle content="Booking Capacity" className={s.title} withOutMargin />
          <Typography type="main" color="grey">
            Issued tickets overview
          </Typography>
        </div>
        <div className={s.totalBlock}>
          <Typography type="h3" className={s.totlal}>
            {totalValue}
          </Typography>
          <Typography type="main" weight={500}>
            <span className={s.green}>{maxValue}</span> maximum
          </Typography>
        </div>
      </div>
      <div className={s.chartContainer}>
        <canvas ref={chartRef} style={{ width: 264, height: 264 }} />
      </div>
    </div>
  );
};

export default DashboardBookingCapasityChart;
