import { FC } from 'react';

import classNames from 'classnames';

import s from './EventTypeSwitcher.module.scss';

interface EventTypeSwitcherProps {
  value?: boolean;
  onChange?: (value: boolean) => void;
}

const EventTypeSwitcher: FC<EventTypeSwitcherProps> = (props) => {
  const { value, onChange, ...otherProps } = props;
  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  return (
    <div className={classNames(s.switchContainer)}>
      <label className={classNames(s.switch)}>
        <span
          className={classNames(s.switchLabel, {
            [s.active]: !value,
          })}>
          <div>Public</div>
        </span>
        <input type="checkbox" checked={value} onChange={handleChange} {...otherProps} />
        <span className={classNames(s.slider, { [s.checked]: value })}></span>
        <span
          className={classNames(s.switchLabel, {
            [s.active]: value,
          })}>
          <div>Private</div>
        </span>
      </label>
    </div>
  );
};

export default EventTypeSwitcher;
