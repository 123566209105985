import { PropsWithChildren } from 'react';

import { Modal as ModalAntd, ModalProps as ModalPropsAntd } from 'antd';
import 'antd/lib/modal/style/index';
import cn from 'classnames';

import Typography from '../typography';
import s from './Modal.module.scss';

const ROOT = document.getElementById('root') as HTMLElement;

export interface ModalProps extends Omit<ModalPropsAntd, 'onCancel'> {
  handleClose: () => void;
}

const Modal = (props: ModalProps) => {
  const {
    open,
    handleClose,
    closable = false,
    children,
    className = '',
    getContainer,
    ...otherProps
  } = props;
  return (
    <ModalAntd
      className={cn(s.modal, { [className]: className })}
      open={open}
      closable={closable}
      onCancel={handleClose}
      getContainer={getContainer || ROOT}
      maskClosable
      centered
      {...otherProps}>
      {children}
    </ModalAntd>
  );
};

interface DefaultProps extends PropsWithChildren {
  className?: string;
}

interface HeaderProps extends DefaultProps {
  label?: string | number;
}

Modal.Header = (props: HeaderProps) => {
  const { className = '', children, label } = props;

  return (
    <div className={cn(s.headerWrapper, { [className]: className })}>
      {!!label && <Typography type="h4">{label}</Typography>}
      {children}
    </div>
  );
};

Modal.Content = (props: DefaultProps) => {
  const { className = '', children } = props;

  return <div className={cn(s.contentWrapper, { [className]: className })}>{children}</div>;
};

Modal.Actions = (props: DefaultProps) => {
  const { className = '', children } = props;

  return <div className={cn(s.actionWrapper, { [className]: className })}>{children}</div>;
};

export default Modal;
