import { DASHBOARD_ROUTE } from 'routes/dashboard/list';
import { CREATE_EVENT_FORM_ROUTE, MY_EVENTS_ROUTE } from 'routes/events/list';
import { TICKETS_ORDERS_ROUTE } from 'routes/tikets/list';
import { USER_SETTING_BANK_DETAILS_ROUTE, USER_SETTING_ROUTE } from 'routes/user-management/list';
import { t } from 'tools/i18n';

export const NAVIGATIONS_CONFIG = [
  {
    to: DASHBOARD_ROUTE,
    label: t('common_dashboard'),
    icon: 'dashboard',
  },
  // {
  //   to: CALENDAR_ROUTE,
  //   label: t('common_calendar'),
  //   icon: 'calendar',
  // },
  {
    to: MY_EVENTS_ROUTE,
    label: t('common_my_events'),
    icon: 'myEvents',
  },
  {
    to: TICKETS_ORDERS_ROUTE,
    label: t('common_tickets_orders'),
    icon: 'ticketOrders',
  },
  // {
  //   to: TICKETS_SCANS_ROUTE,
  //   label: t('common_tickets_scan'),
  //   icon: 'ticketScan',
  // },
  // {
  //   to: OUTLETS_ROUTE,
  //   label: t('common_outlets'),
  //   icon: 'ticketKiosk',
  // },
  // {
  //   to: SELLERS_ROUTE,
  //   label: t('common_sellers'),
  //   icon: 'ticketSeller',
  // },
];

export const isSettingRoute = (pathname: string): boolean =>
  [USER_SETTING_ROUTE, USER_SETTING_BANK_DETAILS_ROUTE].includes(pathname);

export const isCreateEventRoute = (pathname: string): boolean => {
  if (pathname.includes('/edit-event/')) {
    return true;
  }
  return [CREATE_EVENT_FORM_ROUTE].includes(pathname);
};
