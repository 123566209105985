import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { EventCreatedType } from 'types/events/eventTypes';

export type EventsSliceType = {
  activeStep: CREATE_EVENT_FORM_STEPS_ENUM;
  activeEvent?: EventCreatedType;
  editingEventId?: string;
  isPublishingEvent?: boolean;
};

export const initialState: EventsSliceType = {
  activeStep: CREATE_EVENT_FORM_STEPS_ENUM.BASIC_INFO,
};

export const eventsSlice = createSlice({
  name: 'eventsSlice',
  initialState,
  reducers: {
    setActiveStep: (state, action: PayloadAction<CREATE_EVENT_FORM_STEPS_ENUM>) => {
      state.activeStep = action.payload;
    },
    setActiveEvent: (state, action: PayloadAction<EventCreatedType>) => {
      state.activeEvent = action.payload;
    },
    setEventFlyer: (state, action: PayloadAction<string>) => {
      if (state?.activeEvent?.basicInfo.eventFlyer) {
        state.activeEvent.basicInfo.eventFlyer = action.payload;
      }
    },
    setEditingEventId: (state, action: PayloadAction<string>) => {
      state.editingEventId = action.payload;
    },
    removeActiveEvent: (state) => {
      state.activeEvent = undefined;
    },
    setIsPublishingEvent: (state, action: PayloadAction<boolean>) => {
      state.isPublishingEvent = action.payload;
    },
  },
});

export const {
  setActiveStep,
  setActiveEvent,
  setEventFlyer,
  setEditingEventId,
  removeActiveEvent,
  setIsPublishingEvent,
} = eventsSlice.actions;
