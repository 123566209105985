import { Dispatch, FC, SetStateAction, useCallback } from 'react';

import LayoutSwitcher from 'component/layout-switcher';
import { useNavigate } from 'react-router-dom';
import { CREATE_EVENT_FORM_ROUTE } from 'routes/events/list';
import { t } from 'tools/i18n';
import { Button, Form, Icon, Input } from 'ui';

import { setEditingEventId } from 'slices/eventsSlice';
import { useAppDispatch } from 'store/index';

import s from './EventsHeader.module.scss';

interface EventsHeaderProps {
  isBlockView: boolean;
  setIsBlockView: Dispatch<SetStateAction<boolean>>;
}

const EventsHeader: FC<EventsHeaderProps> = (props) => {
  const { isBlockView, setIsBlockView } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleNavigateToCreateEventForm = useCallback(() => {
    dispatch(setEditingEventId(''));
    navigate(CREATE_EVENT_FORM_ROUTE);
  }, [dispatch, navigate]);

  return (
    <div className={s.header}>
      <div className={s.searchBlock}>
        <Form.Item name="eventName" fullWidth>
          <Input name="eventName" placeholder="Event name" icon="search" fullWidth />
        </Form.Item>
      </div>

      <div className={s.actions}>
        <Button onClick={handleNavigateToCreateEventForm}>
          <Icon name="plus" />
          {t('common_create_event')}
        </Button>
        <div className={s.row}>
          <Button color="tertiary">
            <Icon name="refreshArrows" />
          </Button>
          <LayoutSwitcher value={isBlockView} onChange={setIsBlockView} />
        </div>
      </div>
    </div>
  );
};

export default EventsHeader;
