import React, { FC, useEffect, useState } from 'react';

import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import type { UploadProps } from 'antd/es/upload/interface';
import 'antd/lib/modal/style/index';
import 'antd/lib/slider/style/index';
import { getBase64 } from 'tools/base64';
import { t } from 'tools/i18n';
import { Icon, Typography } from 'ui';

import { activePromoterSelector } from 'selectors/systemSlice.selector';
import { useUploadLogoMutation } from 'services/promoter/promoterApiService';
import { setEditingPromoter } from 'slices/systemSlice';
import { useAppDispatch, useAppSelector } from 'store';

import s from './UploadLogo.module.scss';

const { Dragger } = Upload;

const UploadLogo: FC = () => {
  const [imageUrl, setImageUrl] = useState<string>();
  const activePromoter = useAppSelector(activePromoterSelector);
  const [uploadLogo] = useUploadLogoMutation();
  const dispatch = useAppDispatch();
  const [logoUrl, setLogoUrl] = useState<string | undefined>();

  const props: UploadProps = {
    onRemove: () => {
      setImageUrl('');
    },
    beforeUpload: (file) => {
      getBase64(file, (url) => {
        setImageUrl(url);
      });

      return false;
    },
    multiple: false,
    maxCount: 1,
    showUploadList: false,
  };

  const handleDeleteLogo = () => {
    setLogoUrl('');
  };

  useEffect(() => {
    if (imageUrl || activePromoter?.logo) {
      setLogoUrl(imageUrl || activePromoter?.logo);
    } else {
      setLogoUrl(undefined);
    }
  }, [imageUrl, activePromoter, setLogoUrl]);

  useEffect(() => {
    if (imageUrl && activePromoter) {
      uploadLogo({ logo: imageUrl, promoterId: activePromoter?.id }).then((res) => {
        if (res) {
          dispatch(setEditingPromoter(true));
          setImageUrl('');
        }
      });
    }
  }, [imageUrl, activePromoter, uploadLogo, dispatch]);

  return (
    <div>
      <ImgCrop
        rotationSlider
        modalProps={{ okButtonProps: { block: true }, cancelButtonProps: { block: true } }}>
        <Dragger {...props} className={s.dragger} disabled={!!logoUrl}>
          {logoUrl ? (
            <img src={logoUrl} alt="avatar" style={{ width: '100%' }} />
          ) : (
            <div className={s.hint}>
              <Icon name="thumbnail" size={40} />

              <Typography type="small" className={s.uploadHint}>
                {t('settings_upload_logo')}
              </Typography>
              <Typography type="small" className={s.uploadHintType}>
                {t('setting_upload_logo_type')}
              </Typography>
            </div>
          )}
          {logoUrl && (
            <button onClick={handleDeleteLogo} className={s.delete}>
              <Icon name="trashBox" />
            </button>
          )}
        </Dragger>
      </ImgCrop>
    </div>
  );
};

export default UploadLogo;
