import { FC, useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { Button, Dropdown, Icon, Input, Label, Typography } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import {
  useDeleteUserEventPermissionsMutation,
  useLazyGetEventPermissionsQuery,
  useSetUserEventPermissionsMutation,
} from 'services/events/eventsApiService';
import { useLazySearchUserQuery } from 'services/user-management/userManagementApiService';
import { setEditingEventId } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import { ROLE_CODE_ENUM } from 'pages/events/events-create-event-form/event-create-form-permission/eventCreateFormPermission.utils';

import { UserProfileType } from 'types/core/userTypes';

import s from './EventCreateFormPermission.module.scss';

const DROPDOWN_ITEMS = Object.keys(ROLE_CODE_ENUM).map((key) => ({
  key: key,
  label: <Typography type="main">{ROLE_CODE_ENUM[key]}</Typography>,
}));
const EventCreateFormPermission: FC = () => {
  const [email, setEmail] = useState('');
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);
  const dispatch = useAppDispatch();
  const [searchUser, { isFetching: isSearchFetching }] = useLazySearchUserQuery();
  const [getEventPermissions, { data: eventPermission }] = useLazyGetEventPermissionsQuery();
  const [setEventPermissions] = useSetUserEventPermissionsMutation();
  const [deleteEventPermission] = useDeleteUserEventPermissionsMutation();
  const [user, setUser] = useState<UserProfileType[]>();
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const [selectedRole, setSelectedRole] = useState('PRM');
  const { eventId } = useParams();

  const handleSetEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSearchUser = useCallback(async () => {
    const res = await searchUser({ term: email });

    if ('data' in res) {
      setUser(res.data);
      setEmail('');
    }
  }, [email, searchUser]);

  const handleSetRole = useCallback(async () => {
    if (activeEvent?.eventId && user?.length) {
      const res = await setEventPermissions({
        eventId: activeEvent.eventId,
        email: user?.[0]?.email,
        roleCode: selectedRole,
      });

      if ('data' in res) {
        setUser([]);
      }
    }
  }, [activeEvent, selectedRole, setEventPermissions, user]);

  const handleDeletePermission = useCallback(
    async (id: string) => {
      if (activeEvent?.eventId) {
        const res = await deleteEventPermission(id);
        console.log(res);
      }
    },
    [activeEvent?.eventId, deleteEventPermission],
  );

  useEffect(() => {
    if (eventId || activeEvent?.eventId) {
      getEventPermissions(eventId || activeEvent?.eventId || '');
    }
    if (activeEvent?.eventId) {
      dispatch(setEditingEventId(activeEvent.eventId));
    }
  }, [activeEvent, dispatch, eventId, getEventPermissions]);

  return (
    <div className={s.wrapper}>
      <Typography type="h2" className={s.title}>
        {t('common_permissions')}
      </Typography>
      <div className={s.block}>
        <div className={s.content}>
          <Typography type="main">{t('event_create_permissions_first_text')}</Typography>
          <Typography type="main">{t('event_create_permissions_second_text')}</Typography>
          <div>
            <Label>{t('common_email')}</Label>

            <div className={s.formField}>
              <Input
                name="email"
                placeholder="Enter email address"
                value={email}
                onChange={handleSetEmail}
              />
              <Button
                variant="outline"
                color="grey"
                onClick={handleSearchUser}
                loading={isSearchFetching}>
                Add
              </Button>
            </div>
          </div>

          {!!user?.length &&
            user.map((item) => (
              <div key={item?.userId} className={cn(s.permissionItem, s.search)}>
                <div className={s.userIcon}>
                  <Icon name="user" />
                </div>
                <div>
                  <Typography type="main" weight={600}>
                    {item?.fullName}
                  </Typography>
                  <Typography type="small" color="grey">
                    {item.email}
                  </Typography>
                </div>
                <div>
                  <Typography type="small" color="grey">
                    Role
                  </Typography>

                  <Dropdown
                    items={DROPDOWN_ITEMS}
                    handleItemClick={(e) => {
                      setOpenedDropdown(false);
                      setSelectedRole(e.key);
                    }}
                    open={openedDropdown}
                    onOpenChange={setOpenedDropdown}
                    placement="bottomCenter">
                    <button className={s.dropdownButton}>
                      <Typography type="main" weight={600}>
                        {ROLE_CODE_ENUM[selectedRole]}
                      </Typography>
                      <Icon name="arrowDropdown" />
                    </button>
                  </Dropdown>
                </div>
                <div className={s.actions}>
                  <button className={s.action} onClick={handleSetRole}>
                    <Icon name="checkWide" size={16} />
                  </button>
                  <button className={s.action}>
                    <Icon name="trashBox" size={16} />
                  </button>
                </div>
              </div>
            ))}

          {eventPermission?.length && (
            <ul className={s.permissions}>
              {eventPermission?.map((permission) => (
                <li key={permission?.permissionId} className={s.permissionItem}>
                  <div className={s.userIcon}>
                    <Icon name="user" />
                  </div>
                  <div>
                    <Typography type="main" weight={600}>
                      {permission?.user?.fullName}
                    </Typography>
                    <Typography type="small" color="grey">
                      {permission?.user.email}
                    </Typography>
                  </div>
                  <div>
                    <Typography type="small" color="grey">
                      Role
                    </Typography>
                    <Typography type="main" weight={600}>
                      {permission?.roleName}
                    </Typography>
                  </div>
                  <button
                    className={s.action}
                    onClick={() => handleDeletePermission(permission.permissionId)}>
                    <Icon name="trashBox" size={16} />
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className={s.content}>
          <div className={s.hint}>
            <div>
              <Icon name="ideaColor" />
            </div>
            <div className={s.contentHint}>
              <div className={s.title}>
                <Typography type="main" weight={700}>
                  {t('event_create_access_role')}
                </Typography>
              </div>
              <div style={{ fontSize: 14 }}>
                <Trans i18nKey="event_create_promoter_role" components={{ b: <b /> }} />
              </div>
              <div style={{ fontSize: 14 }}>
                <Trans i18nKey="event_create_report_viewer_role" components={{ b: <b /> }} />
              </div>
              <div style={{ fontSize: 14 }}>
                <Trans i18nKey="event_create_ticket_scanner_role" components={{ b: <b /> }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCreateFormPermission;
