export enum BANK_DETAILS_FORM_ENUM {
  BILLING_NAME = 'billingName',
  BILLING_ADDRESS = 'billingAddress',
  BILLING_TOWN = 'billingTown',
  BILLING_POSTCODE = 'billingPostcode',
  BILLING_COUNTRY = 'billingCountry',
  BILLING_ADDRESS_SAME_AS_CONTACT = 'billingAddressSameAsContact',
  BILLING_VAT_NUMBER = 'billingVATNumber',
  BANK_HOLDER_NAME = 'bankHolderName',
  BANK_IBAN_NUMBER = 'ibanNumber',
  BANK_STRIPE_ACCOUNT_NUMBER = 'paymentGatewaySettings',
}

export type BANK_DETAILS_FORM_TYPE = Record<BANK_DETAILS_FORM_ENUM, string> | {};

export const createPaymentGatewaySettingObj = (
  type: 'Stripe' | 'iban' = 'Stripe',
  value: string,
): { gatewayType: string; settingType: string; value: string } => ({
  gatewayType: type,
  settingType: 'stripeMerchantAccountId',
  value: value,
});
