export const initialFilter = {
  PageNumber: 1,
  PageSize: 10,
  SortByColumn: 'ByDate',
  SortType: 'Descending',
  id: '',
  filter: '',
};

export type OrdersFilterType = {
  PageNumber?: number;
  PageSize?: number;
  SortByColumn?: string;
  SortType?: string;
  id?: string;
  filter?: string;
};
