export function formatNumber(num) {
  if (num === undefined) {
    return;
  }

  if (num % 1 !== 0) {
    //Round to 2 decimal places if value is not a whole number
    num = parseInt(num, 10).toFixed(2);
  }
  //Adds comma as thousands separator
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}
