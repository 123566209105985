export const getImageFormatFromBase64 = (
  base64String: string,
): { format: string; base64: string } | null => {
  // Remove the data URL prefix and extract only the base64 string
  const base64 = base64String.replace(/^data:image\/\w+;base64,/, '');

  // Decode the base64 string
  const decodedData = atob(base64);

  // Determine the image format by checking the first few bytes of the decoded data
  let format;
  const header = decodedData.substring(0, 4);
  if (header === '\xFF\xD8\xFF\xE0' || header === '\xFF\xD8\xFF\xE1') {
    format = 'jpeg';
  } else if (header === '\x89PNG') {
    format = 'png';
  } else if (header === 'GIF8') {
    format = 'gif';
  } else if (header === 'BM\x00') {
    format = 'bmp';
  } else {
    format = null;
  }

  return { format, base64 };
};
