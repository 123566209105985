import { PromoterType, UserProfileType } from 'types/core/userTypes';

export type EventType = {
  id: string;
  name: string;
};

export type EventTagType = {
  id: string;
  name: string;
  slug: string;
};

export type EventTagsResponseType = EventTagType[];

export type EventShowDateType = {
  startDate: string;
  startTime: string;
  endDate?: string;
  endTime?: string;
  doorsOpen?: string;
  duration?: { hours: number; minutes: number };
  showId?: string;
};

export type MultipleDatesType = Omit<EventShowDateType, 'duration'> & {
  duration: number;
  showId?: string;
};

export type EventDatesType = {
  startDate: string;
  startTime: string;
  endDate?: string;
  endTime?: string;
  hoursWhenSalesEnd?: number;
  beforeOrAfterEvent?: string;
  isEndTimeApproximate?: boolean;
  caption?: string;
  doorsOpen?: string;
  isShowHidden?: boolean;
  isSoldOut?: boolean;
  useSeatingAlgorithm?: boolean;
  showId?: string;
};

export type EventBasicInfoType = {
  promoterId: string;
  lastShowDate?: string;
  dateType: string;
  eventName: string;
  eventFlyer?: string;
  summary?: string;
  slug?: string;
  url: string;
  startSellingAt?: string;
  privateEvent?: boolean;
  categories: EventTagType[];
  tags: EventTagType[];
  venueType: string;
  currency?: string;
  status: string;
  location: {
    venueName: string;
    locationLink?: string;
    locationComment?: string;
    streetAddress: string;
    postCode?: string;
    country?: string;
    town?: string;
    fullAddress?: string;
    latLng: {
      lat: string;
      lng: string;
    };
  };
  eventType?: string;
  seatingPlanId?: string;
  showDates?: EventDatesType[];
};

export type EventCreateFormDetailsType = {
  contactEmail: string;
  contactNumber: string;
  summary?: string;
  fullDescription: string;
  minimumAge: string;
  freeUpToAge?: string;
  agesRequiringTicket?: string;
  childTicketRequired?: boolean;
  idRequired?: boolean;
  videoLink?: string;
  facebookLink?: string;
  twitterLink?: string;
  instagramLink?: string;
  fAQs?: Record<string, string>;
  promoterTerms?: string;
  showsHappeningTerms?: string;
};

export type EventCreateFormDetailsPropsType = {
  eventId: string;
  otherDetails: EventCreateFormDetailsType;
};

export type EventTicketType = {
  name: string;
  maxNumberOfTicketsToBeSold: number;
  price: number;
  description?: string;
  minQtyPerOrder?: number;
  maxQtyPerOrder?: number;
  showRemainingTickets?: boolean;
  hideFromPublic?: boolean;
  sortOrder?: number;
  id?: string;
};

export type EventCreatedType = {
  eventId: string;
  basicInfo: EventBasicInfoType;
  otherDetails: EventCreateFormDetailsType;
  taxDetails: {
    refundPolicy: string;
    permitNumber: string | null;
    vATRate: number;
  };
  media: {
    flyer: string | null;
    watermarkedFlyer: string | null;
  };
  ticketTypes: EventTicketType[];
};

export enum REFUND_POLICY_TYPE {
  DAY1_BEFORE_EVENT = 'Day1BeforeEvent',
  DAY7_BEFORE_EVENT = 'Day7BeforeEvent',
  DAY3_BEFORE_EVENT = 'Day3BeforeEvent',
  NO_REFUNDS = 'NoRefunds',
  DO_NOT_SPECIFY_REFUND = 'DoNotSpecifyRefund',
}

export type SingleEventType = {
  disableUserReviews: boolean;
  lastShowDate: string;
  firstShowDate: string;
  nextShowDate: string;
  promoter: PromoterType;
  tags: EventTagType[];
  venue: {
    name: string;
    address: string;
    googleLocation: string;
  };
  description: string;
  summary: string;
  scheduleType: string;
  permissions: string[];
  resources: string[];
  media: string[];
  socials: string[];
  canEdit: boolean;
  dates: [
    {
      dateId: string;
      date: string;
      timeFrom: string;
      dateTo: string;
      timeTo: string;
      doorsOpenAt: string;
    },
  ];
  ticketTypes: string[];
  maximumNumberOfTickets: number;
  eventFlyer: string;
  eventName: string;
  eventId: string;
};

export type TicketDataType = {
  type: string;
  qtyOfTicketsSold: number;
  maximumNumberOfTickets: number;
  totalAmount: number;
  averageTicketPrice: number | string;
  numberOfComplimentaries: number;
};

export type EventMetricsType = {
  ticketTypes: TicketDataType[];
  currency?: string;
  eventId: string;
  eventName: string;
  totalTicketsSold: {
    today: number;
    allTime: number;
  };
  totalSales: {
    today: number;
    allTime: number;
  };
  totalComplimentariesIssued: {
    today: number;
    allTime: number;
  };
};

export type EventPermissionsType = {
  permissionId: string;
  roleCode: string;
  roleName: string;
  user: UserProfileType;
};

export type EventWithMetricsType = {
  metrics: EventMetricsType;
  dates: {
    dateId: string;
    date: string;
    timeFrom: string;
    dateTo: string;
    timeTo: string;
    doorsOpenAt: string;
  }[];
  allDates: string;
  venue: {
    name: string;
    address: string;
    googleLocation: string;
  };
  permissions: string[];
  status: string;
  lastShowDate: string;
  scheduleType: string;
  promoter: PromoterType;
  eventFlyer: string;
  eventName: string;
  eventId: string;
  url: string;
};
