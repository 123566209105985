import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { REGISTER_ROUTE } from 'routes/user-management/list';
import { t } from 'tools/i18n';
import { Button, Icon, Typography } from 'ui';

import { CORE_URLS } from 'constants/coreUrls';

import s from './BenefitsContent.module.scss';
import { benefitsContent } from './benefitsContent.utils';

const BenefitsContent: FC = () => {
  const navigate = useNavigate();

  const goToRegister = () => {
    navigate(REGISTER_ROUTE);
  };

  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <Icon name="logo" width={320} height={50} className={s.logo} />
        <div className={s.title}>
          <Typography type="h1" className={s.titleText}>
            {t('auth_benefits_title')}
          </Typography>
        </div>
        <div className={s.textContent}>
          {benefitsContent.map((item) => (
            <div key={item.label} className={s.item}>
              <Icon name={item.icon} className={s.icon} />

              <div className={s.benefitsText}>{t(item.label)}</div>
            </div>
          ))}
        </div>
        <div className={s.actions}>
          <Button>
            <a href={CORE_URLS.TOUR} target="_blank">
              {t('common_take_tour')}
            </a>
          </Button>
          <Button variant="outline" onClick={goToRegister}>
            {t('auth_sing_up')}
          </Button>
        </div>
        <Typography type="main" className={s.helpText}>
          Need help?{' '}
          <a href="mailto:hello@showshappening.com" className={s.bold}>
            Contact Us
          </a>
        </Typography>
      </div>
    </div>
  );
};

export default BenefitsContent;
