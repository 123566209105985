import { FC, useCallback, useMemo } from 'react';

import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Icon, Typography } from 'ui';

import { useDeleteEventMutation } from 'services/events/eventsApiService';
import { setEditingEventId } from 'slices/eventsSlice';
import { useAppDispatch } from 'store/index';

import { EVENT_STATUS_CONFIG } from 'pages/events/event-list-block-view/event-item-tools/eventItemTools.utils';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { EventWithMetricsType } from 'types/events/eventTypes';

import { DASHBOARD_ROUTE } from '../../../../routes/dashboard/list';
import {
  getAttendeesRoute,
  getEditEventFormRoute,
  getTaxReportRoute,
  getZreadingRoute,
} from '../../../../routes/events/list';
import { TICKETS_ORDERS_ROUTE } from '../../../../routes/tikets/list';
import s from './EventItemsTools.module.scss';

interface EventItemsToolsProps {
  open: boolean;
  event?: EventWithMetricsType;
}

const EventItemsTools: FC<EventItemsToolsProps> = ({ open, event }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [deleteEvent] = useDeleteEventMutation();
  const goToEdit = useCallback(() => {
    if (event) {
      dispatch(setEditingEventId(event?.eventId));
      navigate(getEditEventFormRoute(event?.eventId));
    }
  }, [dispatch, event, navigate]);

  const deleteEventHandler = useCallback(() => {
    if (event) {
      deleteEvent(event?.eventId);
    }
  }, [deleteEvent, event]);

  const getAttendeesHandler = useCallback(() => {
    if (event) {
      navigate(getAttendeesRoute(event?.eventId));
    }
  }, [event, navigate]);

  const getZreadingHandler = useCallback(() => {
    if (event) {
      navigate(getZreadingRoute(event?.eventId));
    }
  }, [navigate, event]);

  const getTaxReportHandler = useCallback(() => {
    if (event) {
      navigate(getTaxReportRoute(event?.eventId));
    }
  }, [navigate, event]);

  const goToPermissions = useCallback(() => {
    if (event) {
      dispatch(setEditingEventId(event?.eventId));
      navigate(
        `${getEditEventFormRoute(event?.eventId)}?step=${CREATE_EVENT_FORM_STEPS_ENUM.PERMISSIONS}`,
      );
    }
  }, [dispatch, event, navigate]);

  const goToReports = useCallback(() => {
    if (event) {
      navigate(`${DASHBOARD_ROUTE}?event=${event.eventId}`);
    }
  }, [event, navigate]);

  const goToTicketsOrders = useCallback(() => {
    if (event) {
      navigate(`${TICKETS_ORDERS_ROUTE}?event=${event.eventId}`);
    }
  }, [event, navigate]);

  const buttonSettings: { title?: string; className?: string; type?: string; icon?: string } =
    useMemo(() => {
      const status = {
        publish: ['draft', 'acceptedTerms'].includes(event?.status || ''),
        pause: ['published', 'approved'].includes(event?.status || ''),
        replay: ['paused'].includes(event?.status || ''),
      };

      if (!status.publish && !status.pause && !status.replay) {
        return {};
      } else if (status.publish) {
        return EVENT_STATUS_CONFIG.publish;
      } else if (status.pause) {
        return EVENT_STATUS_CONFIG.pause;
      } else {
        return EVENT_STATUS_CONFIG.replay;
      }
    }, [event]);

  if (!open) {
    return null;
  }

  return (
    <div className={s.wrapper}>
      <div className={s.item}>
        <Typography type="h4" className={s.title}>
          Event Setup
        </Typography>
        <div className={s.actions}>
          <button className={s.action} onClick={goToEdit}>
            <div className={s.icon}>
              <Icon name="editEvent" />
            </div>
            <Typography className={s.text} type="main">
              Edit
            </Typography>
          </button>
          <button className={s.action} onClick={goToPermissions}>
            <div className={s.icon}>
              <Icon name="user" size={20} />
            </div>
            <Typography className={s.text} type="main">
              Manage Permission
            </Typography>
          </button>
          {!!buttonSettings.type && (
            <button className={cn(s.action, s[buttonSettings?.className || ''])}>
              <div className={s.icon}>
                <Icon name={buttonSettings?.icon || ''} size={20} />
              </div>
              <Typography className={s.text} type="main">
                {buttonSettings.title}
              </Typography>
            </button>
          )}
          <button className={cn(s.action, s.delete)} onClick={deleteEventHandler}>
            <div className={s.icon}>
              <Icon name="trashBox" size={16} />
            </div>
            <Typography className={s.text} type="main">
              Delete Event
            </Typography>
          </button>
        </div>
      </div>
      <div className={s.item}>
        <Typography type="h4" className={s.title}>
          Ticket Sales
        </Typography>
        <div className={s.actions}>
          <button className={cn(s.action)} onClick={goToTicketsOrders}>
            <div className={s.icon}>
              <Icon name="ticketOrders" size={18} />
            </div>
            <Typography className={s.text} type="main">
              Ticket Orders
            </Typography>
          </button>
        </div>
      </div>
      <div className={s.item}>
        <Typography type="h4" className={s.title}>
          Reporting Tools
        </Typography>
        <div className={s.actions}>
          <button className={cn(s.action)} onClick={goToReports}>
            <div className={s.icon}>
              <Icon name="report" />
            </div>
            <Typography className={s.text} type="main">
              Event Report
            </Typography>
          </button>
          <button className={cn(s.action)} onClick={getAttendeesHandler}>
            <div className={s.icon}>
              <Icon name="users" />
            </div>
            <Typography className={s.text} type="main">
              Event Attendees
            </Typography>
          </button>
          <button className={cn(s.action)} onClick={getZreadingHandler}>
            <div className={s.icon}>
              <Icon name="topUp" />
            </div>
            <Typography className={s.text} type="main">
              Financial Report
            </Typography>
          </button>
          <button className={cn(s.action)} onClick={getTaxReportHandler}>
            <div className={s.icon}>
              <Icon name="taxReport" />
            </div>
            <Typography className={s.text} type="main">
              Tax Report
            </Typography>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventItemsTools;
