import { FC, useEffect, useState } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';
import { Button, Icon, Typography } from 'ui';

import {
  EVENT_CREATE_FORM_BASIC_INFO_ENUM,
  EVENT_CREATE_FORM_BASIC_INFO_TYPE,
} from 'pages/events/events-create-event-form/event-create-form-basic-info/basicInfo.utils';
import { EventCreateTaxesFormType } from 'pages/events/events-create-event-form/event-create-form-taxes/eventCreateFormTaxes.utils';
import { BANK_DETAILS_FORM_TYPE } from 'pages/user-management/user-setting/bank-details/bankDetails.utils';
import { ORGANISER_DETAILS_FORM_TYPE } from 'pages/user-management/user-setting/organiser-details/organiserDetailsForm.utils';

import s from './FormSubmit.module.scss';
import {
  BANK_DETAILS_FORM_REQUIRED_FIELDS,
  EVENT_CREATE_FORM_BASIC_REQUIRED_FIELDS,
  EVENT_CREATE_FORM_DETAILS_REQUIRED_FIELDS,
  EVENT_CREATE_FORM_TAXES_REQUIRED_FIELDS,
  ORGANISER_DETAILS_FORM_REQUIRED_FIELDS,
} from './formSubmit.utils';

interface FormSubmitProps {
  isEditing: boolean;
  editingList?:
    | ORGANISER_DETAILS_FORM_TYPE
    | BANK_DETAILS_FORM_TYPE
    | EVENT_CREATE_FORM_BASIC_INFO_TYPE
    | EventCreateTaxesFormType;
  type?: 'organiser' | 'bank' | 'eventBasic' | 'eventDetails' | 'taxesDetails' | 'empty';
  onClick?: () => void;
  fetching?: boolean;
}

const requiredFieldsTypes = {
  organiser: ORGANISER_DETAILS_FORM_REQUIRED_FIELDS,
  bank: BANK_DETAILS_FORM_REQUIRED_FIELDS,
  eventBasic: EVENT_CREATE_FORM_BASIC_REQUIRED_FIELDS,
  eventDetails: EVENT_CREATE_FORM_DETAILS_REQUIRED_FIELDS,
  taxesDetails: EVENT_CREATE_FORM_TAXES_REQUIRED_FIELDS,
  empty: [],
};

const FormSubmit: FC<FormSubmitProps> = (props) => {
  const { isEditing, editingList, type = 'organiser', onClick, fetching } = props;
  const [neededFields, setNeededFields] = useState<string[] | undefined>();
  const requiredFields = requiredFieldsTypes[type];

  useEffect(() => {
    if (isEditing && editingList) {
      let data: string[] = [];
      if (
        type === 'eventBasic' &&
        ['SINGLE_DATE'].includes(editingList[EVENT_CREATE_FORM_BASIC_INFO_ENUM.DATE_TYPE])
      ) {
        data = [...requiredFields, 'startDate', 'startTime'];
      } else if (
        ['NO_DATES', 'RECURRING_DATES'].includes(
          editingList[EVENT_CREATE_FORM_BASIC_INFO_ENUM.DATE_TYPE],
        )
      ) {
        data = [...requiredFields, 'lastDate', 'lastTime'];
      } else {
        data = [...requiredFields];
      }

      const result = data.map((field) => {
        if (!editingList[field]) {
          return field;
        }
      });
      const filteredResult = result.filter(Boolean);

      setNeededFields(filteredResult as string[]);
    }
  }, [isEditing, editingList, requiredFields, type]);

  return (
    <div className={cn(s.wrapper, isEditing && s.show)}>
      <div style={{ flex: 1 }}>
        {!!neededFields?.length && (
          <div className={s.hint}>
            <Icon name="warning" />
            <div>
              <b>Note.</b> Please enter all required information in the fields marked with an{' '}
              <span>*</span> to proceed to the next step. Missing fields:{' '}
              {neededFields?.map((field) => field && <b key={field}> {t(`field_${field}`)}, </b>)}
            </div>
          </div>
        )}
      </div>
      <div className={s.buttonWrapper}>
        <Button
          htmlType="submit"
          size="large"
          block
          disabled={!!neededFields?.length}
          onClick={onClick}
          isLoading={fetching}>
          <Typography type="main" className={s.button}>
            Save
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default FormSubmit;
