import { FC, useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import { useSetQuery } from 'tools/hooks';
import { t } from 'tools/i18n';
import { Button, Input, Switch, Typography } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import { useAddedTicketTypeMutation } from 'services/events/eventsApiService';
import { useGetAvailableCurrenciesQuery } from 'services/system/systemApiService';
import { setActiveEvent } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import FormSubmit from 'containers/form-submit';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { EventTicketType } from 'types/events/eventTypes';

import s from './EventCreateFormTickets.module.scss';
import CreateTicketModal from './create-ticket-modal';
import TicketsList from './tickets-list';

const EventCreateFormTickets: FC = () => {
  const [openedCreateModal, setOpenedCreateModal] = useState<boolean>(false);
  const [tickets, setTickets] = useState<EventTicketType[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isShowMaxAttendees, setIsShowMaxAttendees] = useState<boolean>(false);
  const [maxAttendeesAmount, setMaxAttendeesAmount] = useState<string>();
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);
  const [createTickets, { isLoading }] = useAddedTicketTypeMutation();
  const dispatch = useAppDispatch();
  const { data: currencies } = useGetAvailableCurrenciesQuery();
  const activeCurrency = currencies?.find((item) => item.code === activeEvent?.basicInfo?.currency);
  const setQuery = useSetQuery();
  const [activeEditTicket, setActiveEditTicket] = useState<EventTicketType>();

  const handleSubmitTickets = useCallback(async () => {
    if (tickets?.length) {
      const res = await createTickets({
        ticketTypes: tickets,
        ...(!!maxAttendeesAmount && { maxNumberOfAttendees: Number(maxAttendeesAmount) }),
        eventId: activeEvent?.eventId || '',
      });

      if ('data' in res) {
        setIsEditing(false);
        dispatch(setActiveEvent(res?.data));
        setQuery(CREATE_EVENT_FORM_STEPS_ENUM.TAXES);
      }
    }
  }, [activeEvent?.eventId, createTickets, dispatch, maxAttendeesAmount, setQuery, tickets]);

  const handleChangeMaxAttendees = (e) => {
    const value = e.target.value;
    const res = value?.replace(/^0/g, '').replace(/[^0-9]/g, '');

    setMaxAttendeesAmount(res);
  };

  useEffect(() => {
    if (tickets?.length) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [tickets]);

  useEffect(() => {
    if (activeEvent?.ticketTypes?.length) {
      setTickets(activeEvent.ticketTypes);
    }
  }, [activeEvent]);

  useEffect(() => {
    if (activeEditTicket?.name) {
      setOpenedCreateModal(true);
    }
  }, [activeEditTicket]);

  return (
    <div>
      <div className={s.header}>
        <Typography type="h2" className={s.title}>
          {t('common_tickets')}
        </Typography>
        {!!tickets?.length && (
          <Button onClick={() => setOpenedCreateModal(true)}>
            {t('event_create_form_create_ticket')}
          </Button>
        )}
      </div>
      <div className={s.wrapper}>
        <div className={s.maxAttendeesBlock}>
          <div className={s.block}>
            <Switch size="small" onChange={setIsShowMaxAttendees} value={isShowMaxAttendees} />
            <Typography type="main">Add the maximum number of attendees?</Typography>
          </div>

          {isShowMaxAttendees && (
            <div className={s.maxNumber}>
              <Input
                name="maxAttendees"
                min={0}
                style={{ width: '100%' }}
                placeholder="0"
                value={maxAttendeesAmount}
                onChange={handleChangeMaxAttendees}
              />
            </div>
          )}
        </div>
        {tickets && (
          <TicketsList
            list={tickets}
            setList={setTickets}
            currency={activeCurrency?.symbol || '€'}
            setActiveEditTicket={setActiveEditTicket}
          />
        )}
        {!tickets?.length && (
          <button className={cn(s.create)} onClick={() => setOpenedCreateModal(true)}>
            + {t('event_create_form_create_ticket')}
          </button>
        )}
      </div>

      <FormSubmit isEditing={isEditing} onClick={handleSubmitTickets} fetching={isLoading} />

      <CreateTicketModal
        open={openedCreateModal}
        onClose={setOpenedCreateModal}
        setTickets={setTickets}
        currency={activeCurrency?.symbol || '€'}
        activeEditTicket={activeEditTicket}
        setActiveEditTicket={setActiveEditTicket}
      />
    </div>
  );
};

export default EventCreateFormTickets;
