import initialApiService from 'services/initialApiService';
import { AvailableCountries, AvailableCurrencies } from 'services/system/systemApiService.type';

const systemApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getApiVersion: builder.query<{ versionNumber: string }, void>({
      query: () => ({
        url: '/version',
        method: 'GET',
      }),
    }),
    getAvailableCurrencies: builder.query<AvailableCurrencies, void>({
      query: () => ({
        url: 'common/currencies',
        method: 'GET',
      }),
    }),
    getAvailableCounties: builder.query<AvailableCountries, void>({
      query: () => ({
        url: 'common/countries',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetApiVersionQuery,
  useGetAvailableCountiesQuery,
  useGetAvailableCurrenciesQuery,
} = systemApiService;
