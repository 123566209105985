import { FC, useEffect, useState } from 'react';

import SectionTitle from 'component/section-title/sectionTitle';

import {
  useLazyGetEventMetricsQuery,
  useLazyGetEventOrdersQuery,
  useLazyGetEventTicketMetricsQuery,
} from 'services/events/eventsApiService';

import DashboardBookingCapasityChart from 'pages/dashboard/dashboard-booking-capasity-chart';
import DashboardTicketOrdersTable from 'pages/dashboard/dashboard-ticket-orders-table';
import DashboardTicketSoldTable from 'pages/dashboard/dashboard-ticket-sold-table';
import DashboardTicketsRevenueCharts from 'pages/dashboard/dashboard-tickets-revenue-chart';
import DashboardTicketsTypeSoldChart from 'pages/dashboard/dashboard-tickets-type-sold-chart';

import EventPopover from 'containers/event-popover';

import { UserEventType } from 'types/core/userTypes';

import WelcomeModal from '../../component/welcome-modal';
import s from './Dashboard.module.scss';
import DashboardSummaryStats from './dashboard-summary-stats';
import { initialOrderFilter } from './dashboard.utils';

const Dashboard: FC = () => {
  const [activeEvent, setActiveEvent] = useState<UserEventType>();
  const [getTicketsSold, { data: ticketsSoldData }] = useLazyGetEventTicketMetricsQuery();
  const [getEventMetrics, { data: eventMetricData }] = useLazyGetEventMetricsQuery();
  const [getEventOrders, { data: orders }] = useLazyGetEventOrdersQuery();
  const [selectedSoldChartDays, setSelectedSoldChartDays] = useState(14);

  const handleSelectEvent = (event: UserEventType) => {
    setActiveEvent(event);
  };

  useEffect(() => {
    if (activeEvent?.eventId) {
      getTicketsSold({ EventId: activeEvent.eventId, NumberOfPastDays: selectedSoldChartDays });
    }
  }, [activeEvent, getTicketsSold, selectedSoldChartDays]);

  useEffect(() => {
    if (activeEvent?.eventId) {
      getEventMetrics(activeEvent.eventId);
    }
  }, [activeEvent, getEventMetrics]);

  useEffect(() => {
    if (activeEvent?.eventId) {
      getEventOrders({ ...initialOrderFilter, id: activeEvent?.eventId });
    }
  }, [activeEvent, getEventOrders]);

  return (
    <div className={s.wrapper}>
      <div className={s.row}>
        <SectionTitle label="common_dashboard" className={s.title} />
        <EventPopover handleEvent={handleSelectEvent} />
      </div>
      <DashboardSummaryStats activeEvent={activeEvent} eventMetricData={eventMetricData} />

      <DashboardTicketsTypeSoldChart
        data={ticketsSoldData}
        soldToday={eventMetricData?.totalTicketsSold.today || 0}
        setDays={setSelectedSoldChartDays}
        selectedDays={selectedSoldChartDays}
      />

      <div className={s.group}>
        <DashboardTicketsRevenueCharts
          data={ticketsSoldData}
          todayValue={eventMetricData?.totalSales.today || 0}
          setDays={setSelectedSoldChartDays}
          selectedDays={selectedSoldChartDays}
        />

        <DashboardBookingCapasityChart data={eventMetricData?.ticketTypes} />
      </div>

      <div className={s.block}>
        <DashboardTicketSoldTable ticketTypes={eventMetricData?.ticketTypes} />
        <DashboardTicketOrdersTable orders={orders?.orders} />
      </div>

      <WelcomeModal />
    </div>
  );
};

export default Dashboard;
