import { FC } from 'react';

import ComingSoon from 'component/coming-soon';

const Calendar: FC = () => {
  return (
    <div>
      <ComingSoon />
    </div>
  );
};

export default Calendar;
