import { EVENT_CREATE_FORM_BASIC_INFO_ENUM } from 'pages/events/events-create-event-form/event-create-form-basic-info/basicInfo.utils';
import { EVENT_CREATE_FORM_DETAILS_ENUM } from 'pages/events/events-create-event-form/event-create-form-details/eventCreateFormDetails.utils';
import { TAXES_FORM_ENUM } from 'pages/events/events-create-event-form/event-create-form-taxes/eventCreateFormTaxes.utils';
import { BANK_DETAILS_FORM_ENUM } from 'pages/user-management/user-setting/bank-details/bankDetails.utils';
import { ORGANISER_DETAILS_FORM } from 'pages/user-management/user-setting/organiser-details/organiserDetailsForm.utils';

export const ORGANISER_DETAILS_FORM_REQUIRED_FIELDS = [
  ORGANISER_DETAILS_FORM.COMPANY_NAME,
  ORGANISER_DETAILS_FORM.COMPANY_EMAIL,
  ORGANISER_DETAILS_FORM.COMPANY_URL,
  ORGANISER_DETAILS_FORM.COMPANY_ADDRESS,
  ORGANISER_DETAILS_FORM.COMPANY_PHONE,
  ORGANISER_DETAILS_FORM.COMPANY_TOWN,
  ORGANISER_DETAILS_FORM.COMPANY_CURRENCY,
  // ORGANISER_DETAILS_FORM.COMPANY_POSTCODE,
];

export const BANK_DETAILS_FORM_REQUIRED_FIELDS = [BANK_DETAILS_FORM_ENUM.BILLING_VAT_NUMBER];

export const EVENT_CREATE_FORM_BASIC_REQUIRED_FIELDS = [
  EVENT_CREATE_FORM_BASIC_INFO_ENUM.EVENT_TITLE,
  EVENT_CREATE_FORM_BASIC_INFO_ENUM.PAGE_URL,
  EVENT_CREATE_FORM_BASIC_INFO_ENUM.CATEGORIES,
  EVENT_CREATE_FORM_BASIC_INFO_ENUM.LOCATION,
];

export const EVENT_CREATE_FORM_DETAILS_REQUIRED_FIELDS = [
  EVENT_CREATE_FORM_DETAILS_ENUM.CONTACT_EMAIL,
  EVENT_CREATE_FORM_DETAILS_ENUM.CONTACT_PHONE,
  EVENT_CREATE_FORM_DETAILS_ENUM.FULL_DESCRIPTION,
  EVENT_CREATE_FORM_DETAILS_ENUM.MINIMUM_AGES,
];

export const EVENT_CREATE_FORM_TAXES_REQUIRED_FIELDS = [
  TAXES_FORM_ENUM.VAT_RATE,
  TAXES_FORM_ENUM.PERMIT_NUMBER,
];
