import { FC, useCallback } from 'react';

import { Empty } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from 'ui';

import { CREATE_EVENT_FORM_ROUTE } from '../../routes/events/list';
import s from './EventsEmptyState.module.scss';

const EventsEmptyState: FC = () => {
  const navigate = useNavigate();

  const goToCreateEvent = useCallback(() => {
    navigate(CREATE_EVENT_FORM_ROUTE);
  }, [navigate]);

  return (
    <Empty
      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
      imageStyle={{ height: 260 }}
      description={<Typography type="h3">No Event Found</Typography>}
      className={s.empty}>
      <Button type="primary" size="large" onClick={goToCreateEvent}>
        Create Event
      </Button>
    </Empty>
  );
};

export default EventsEmptyState;
