import { Dispatch, FC, ReactElement, SetStateAction, useCallback, useMemo, useState } from 'react';

import { Input } from 'antd';
import cn from 'classnames';
import { t } from 'tools/i18n';
import { Button, Form, Icon, Label, Modal, Typography } from 'ui';

import { useAddedTaxesEventMutation } from 'services/events/eventsApiService';
import { useEditPromoterInfoMutation } from 'services/promoter/promoterApiService';

import { PublishEventModalStepsType } from 'containers/publish-event-modal/publishEventModal.utils';

import { UserProfileType } from 'types/core/userTypes';
import { EventCreatedType } from 'types/events/eventTypes';
import { PromoterFullInfoType } from 'types/promoters/promoterTypes';

import s from './PublishEventModalPromoterStep.module.scss';
import PromoterStepVatInfo from './promoter-step-vat-info';

interface PublishEventModalPromoterStepProps {
  setActiveStep: Dispatch<SetStateAction<PublishEventModalStepsType>>;
  promoter?: PromoterFullInfoType;
  currentUser?: UserProfileType;
  isFetchingPromoter: boolean;
  activeEvent?: EventCreatedType;
  isChargeVat: boolean;
  setIsChargeVat: Dispatch<SetStateAction<boolean>>;
}

const PublishEventModalPromoterStep: FC<PublishEventModalPromoterStepProps> = (props) => {
  const {
    setActiveStep,
    promoter,
    currentUser,
    isFetchingPromoter,
    activeEvent,
    isChargeVat,
    setIsChargeVat,
  } = props;
  const [isEditingPromoter, setIsEditingPromoter] = useState(false);
  const [editPromoter, { isLoading }] = useEditPromoterInfoMutation();
  const [editTaxesDetails, { isLoading: isTaxDetailsLoading }] = useAddedTaxesEventMutation();
  const [form] = Form.useForm();
  const isLoadPromoter = isLoading || isFetchingPromoter || isTaxDetailsLoading;
  const [isFormDirty, setIsFormDirty] = useState(false);

  const renderUserLogo = useMemo((): ReactElement => {
    if ((promoter && promoter?.logo) || currentUser?.promoters[0]?.logo) {
      return (
        <img
          className={s.logo}
          src={promoter?.logo || currentUser?.promoters[0]?.logo}
          alt={promoter?.name}
        />
      );
    } else {
      return (
        <div className={s.logoTextWrapper}>
          <div className={s.logoText}>
            {promoter ? promoter?.name[0] : currentUser?.firstName[0]}
          </div>
        </div>
      );
    }
  }, [promoter, currentUser]);

  const handleEditPromoter = useCallback(() => {
    form.submit();
    setIsEditingPromoter(false);
  }, [form]);

  const handleSubmit = useCallback(
    async (data: {
      address: string;
      permitNumber: string;
      billingVatNumber: string;
      email: string;
      name: string;
      mobileNumber: string;
    }) => {
      if (isFormDirty) {
        if (data && promoter) {
          const res = await editPromoter({ ...promoter, ...data });

          if ('data' in res) {
            setIsFormDirty(false);
          }
        }
        if (data.permitNumber !== activeEvent?.taxDetails?.permitNumber) {
          const res = await editTaxesDetails({
            eventId: activeEvent?.eventId || '',
            taxDetails: {
              permitNumber: data.permitNumber,
            },
          });

          if ('data' in res) {
            setIsFormDirty(false);
          }
        }
      }
    },
    [
      activeEvent?.eventId,
      activeEvent?.taxDetails?.permitNumber,
      editPromoter,
      editTaxesDetails,
      isFormDirty,
      promoter,
    ],
  );

  return (
    <div>
      <Modal.Header>
        <Typography type="h3" className={s.modalText}>
          {t('publish_event_modal_promoter_title')}
        </Typography>
        <Typography type="main" color="grey">
          {`Make sure the promoter's and bank information is correct before publishing the event.`}
        </Typography>
      </Modal.Header>

      <Modal.Content>
        <Form form={form} onFinish={handleSubmit} onValuesChange={() => setIsFormDirty(true)}>
          <div className={s.promoterWrapper}>
            <Typography type="main" weight={600} className={s.hint}>
              <Icon name="warning" size={18} />
              <div>This information will be displayed on the event page and on tickets</div>
            </Typography>

            <div className={s.promoterInfo}>
              <div>
                <Label className={s.label}>Logo</Label>
                {renderUserLogo}
              </div>
              <div className={s.info}>
                <div className={cn(s.block, { [s.editing]: isEditingPromoter })}>
                  <div className={s.item}>
                    <Label className={s.label}>Name</Label>

                    {isEditingPromoter ? (
                      <Form.Item
                        name="name"
                        initialValue={promoter?.name}
                        rules={[{ required: true, message: '' }]}>
                        <Input bordered={false} />
                      </Form.Item>
                    ) : (
                      <Typography type="main" weight={600}>
                        {promoter?.name}
                      </Typography>
                    )}
                  </div>
                  <div className={s.item}>
                    <Label className={s.label}>Email</Label>
                    {isEditingPromoter ? (
                      <Form.Item
                        name="email"
                        initialValue={promoter?.email}
                        rules={[{ required: true, message: '' }]}>
                        <Input bordered={false} />
                      </Form.Item>
                    ) : (
                      <Typography type="main" weight={600}>
                        {promoter?.email}
                      </Typography>
                    )}
                  </div>
                </div>
                <div>
                  <Label className={s.label}>Phone</Label>
                  {isEditingPromoter ? (
                    <Form.Item
                      name="mobileNumber"
                      initialValue={promoter?.mobileNumber}
                      rules={[{ required: true, message: '' }]}>
                      <Input bordered={false} />
                    </Form.Item>
                  ) : (
                    <Typography type="main" weight={600}>
                      {`+${promoter?.mobileNumber}`}
                    </Typography>
                  )}
                </div>
                <div>
                  <Label className={s.label}>Address</Label>
                  {isEditingPromoter ? (
                    <Form.Item
                      name="address"
                      initialValue={promoter?.address}
                      rules={[{ required: true, message: '' }]}>
                      <Input bordered={false} />
                    </Form.Item>
                  ) : (
                    <Typography type="main" weight={600}>
                      {promoter?.address}
                    </Typography>
                  )}
                </div>
                {/*{!isEditingPromoter ? (*/}
                {/*  <button*/}
                {/*    className={s.editButton}*/}
                {/*    onClick={() => setIsEditingPromoter(true)}*/}
                {/*    type="button">*/}
                {/*    <Icon*/}
                {/*      name={isLoadPromoter ? 'loader' : 'edit'}*/}
                {/*      size={isLoadPromoter ? 18 : 32}*/}
                {/*      color={isLoadPromoter ? '#256eff' : ''}*/}
                {/*    />*/}
                {/*  </button>*/}
                {/*) : (*/}
                {/*  <button className={s.editButton} onClick={handleEditPromoter} type="button">*/}
                {/*    <Icon name="checkWide" size={18} color="#00bc4b" />*/}
                {/*  </button>*/}
                {/*)}*/}
              </div>
            </div>
          </div>

          <PromoterStepVatInfo
            isChargeVat={isChargeVat}
            isEditingPromoter={isEditingPromoter}
            setIsChargeVat={setIsChargeVat}
            promoter={promoter}
            activeEvent={activeEvent}
          />
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <div className={s.actions}>
          {!isEditingPromoter ? (
            <Button onClick={() => setIsEditingPromoter(true)} fullWidth loading={isLoadPromoter}>
              Edit Info
            </Button>
          ) : (
            <Button onClick={handleEditPromoter} fullWidth>
              Save Info
            </Button>
          )}
          <Button type="primary" fullWidth onClick={() => setActiveStep('first')}>
            Next Step
          </Button>
        </div>
      </Modal.Actions>
    </div>
  );
};

export default PublishEventModalPromoterStep;
