import { Dispatch, FC, SetStateAction } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, Modal, Typography } from 'ui';

import { CREATE_EVENT_FORM_ROUTE } from '../../../../../routes/events/list';
import s from './EventModal.module.scss';

interface EventModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const EventModal: FC<EventModalProps> = (props) => {
  const { open, setOpen } = props;
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const goToSettings = () => {
    navigate(CREATE_EVENT_FORM_ROUTE);
  };

  return (
    <Modal open={open} handleClose={handleClose} footer={false}>
      <Modal.Header>
        <Typography type="h4">🎉 Promoter data has been successfully saved</Typography>
      </Modal.Header>
      <Modal.Content>
        <div className={s.wrapper}>
          <div>
            <Typography type="main" color="grey" className={s.text}>
              Data about the promoter is successfully saved, now you can create your first event!
            </Typography>
          </div>
          <Typography type="main" color="grey" className={s.text}>
            {`Click 'Get Started' to create your first event, and start your journey with ShowsManager.`}
          </Typography>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button fullWidth onClick={goToSettings}>
          Get Started
        </Button>
        <Button fullWidth color="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EventModal;
