import { message } from 'ui';

export const handleCopy = (text: string) => {
  try {
    window.navigator.clipboard.writeText(text);
    message.config({ duration: 0.8, maxCount: 1 });
    message.success('Copied to clipboard!');
  } catch (error) {
    message.error('Unable to copy to clipboard!');
    console.error('Unable to copy to clipboard:', error);
  }
};
