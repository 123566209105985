let colors;
let gradientGrey;

function gradientGenerator(myChartRef) {
  gradientGrey = myChartRef.createLinearGradient(0, 0, 0, 250);
  gradientGrey.addColorStop(0, 'rgba(217, 227, 234, 0.2)');
  gradientGrey.addColorStop(1, 'rgba(217, 227, 234, 0)');

  const gradientBlue = myChartRef.createLinearGradient(0, 0, 0, 250);
  gradientBlue.addColorStop(0, 'rgba(0, 178, 255, 0.2)');
  gradientBlue.addColorStop(1, 'rgba(0, 178, 255, 0)');

  const gradientTurquoise = myChartRef.createLinearGradient(0, 0, 0, 250);
  gradientTurquoise.addColorStop(0, 'rgba(0, 220, 255, 0.2)');
  gradientTurquoise.addColorStop(1, 'rgba(0, 220, 255, 0)');

  const gradientOrange = myChartRef.createLinearGradient(0, 0, 0, 250);
  gradientOrange.addColorStop(0, 'rgba(255, 148, 51, 0.2)');
  gradientOrange.addColorStop(1, 'rgba(255, 148, 51, 0)');

  const gradientGreen = myChartRef.createLinearGradient(0, 0, 0, 250);
  gradientGreen.addColorStop(0, 'rgba(44, 210, 111, 0.2)');
  gradientGreen.addColorStop(1, 'rgba(44, 210, 111, 0)');

  const gradientPink = myChartRef.createLinearGradient(0, 0, 0, 250);
  gradientPink.addColorStop(0, 'rgba(193, 145, 255, 0.2)');
  gradientPink.addColorStop(1, 'rgba(193, 145, 255, 0)');

  const gradientPurple = myChartRef.createLinearGradient(0, 0, 0, 250);
  gradientPurple.addColorStop(0, 'rgba(102, 99, 234, 0.2)');
  gradientPurple.addColorStop(1, 'rgba(102, 99, 234, 0)');

  const gradientYellow = myChartRef.createLinearGradient(0, 0, 0, 250);
  gradientYellow.addColorStop(0, 'rgba(255, 215, 0, 0.2)');
  gradientYellow.addColorStop(1, 'rgba(255, 215, 0, 0)');

  const gradientLime = myChartRef.createLinearGradient(0, 0, 0, 250);
  gradientLime.addColorStop(0, 'rgba(173, 255, 63, 0.2)');
  gradientLime.addColorStop(1, 'rgba(173, 255, 63, 0)');

  colors = [
    {
      gradient: gradientBlue,
      color: '#4CC9FF',
    },
    {
      gradient: gradientTurquoise,
      color: '#4CE6FF',
    },
    {
      gradient: gradientGreen,
      color: '#6BDF9A',
    },
    {
      gradient: gradientPurple,
      color: '#9491F0',
    },
    {
      gradient: gradientLime,
      color: '#C5EA78',
    },
    {
      gradient: gradientPink,
      color: '#EE91F0',
    },

    {
      gradient: gradientYellow,
      color: '#FFE34C',
    },
    {
      gradient: gradientOrange,
      color: '#FFB470',
    },
    {
      gradient: gradientBlue,
      color: '#4CC9FF',
    },
    {
      gradient: gradientTurquoise,
      color: '#4CE6FF',
    },
    {
      gradient: gradientGreen,
      color: '#6BDF9A',
    },
    {
      gradient: gradientPurple,
      color: '#9491F0',
    },
    {
      gradient: gradientLime,
      color: '#C5EA78',
    },
    {
      gradient: gradientPink,
      color: '#EE91F0',
    },

    {
      gradient: gradientYellow,
      color: '#FFE34C',
    },
    {
      gradient: gradientOrange,
      color: '#FFB470',
    },
    {
      gradient: gradientBlue,
      color: '#4CC9FF',
    },
    {
      gradient: gradientTurquoise,
      color: '#4CE6FF',
    },
    {
      gradient: gradientGreen,
      color: '#6BDF9A',
    },
    {
      gradient: gradientPurple,
      color: '#9491F0',
    },
    {
      gradient: gradientLime,
      color: '#C5EA78',
    },
    {
      gradient: gradientPink,
      color: '#EE91F0',
    },

    {
      gradient: gradientYellow,
      color: '#FFE34C',
    },
    {
      gradient: gradientOrange,
      color: '#FFB470',
    },
    {
      gradient: gradientBlue,
      color: '#4CC9FF',
    },
    {
      gradient: gradientTurquoise,
      color: '#4CE6FF',
    },
    {
      gradient: gradientGreen,
      color: '#6BDF9A',
    },
    {
      gradient: gradientPurple,
      color: '#9491F0',
    },
    {
      gradient: gradientLime,
      color: '#C5EA78',
    },
    {
      gradient: gradientPink,
      color: '#EE91F0',
    },

    {
      gradient: gradientYellow,
      color: '#FFE34C',
    },
    {
      gradient: gradientOrange,
      color: '#FFB470',
    },
    {
      gradient: gradientBlue,
      color: '#4CC9FF',
    },
    {
      gradient: gradientTurquoise,
      color: '#4CE6FF',
    },
    {
      gradient: gradientGreen,
      color: '#6BDF9A',
    },
    {
      gradient: gradientPurple,
      color: '#9491F0',
    },
    {
      gradient: gradientLime,
      color: '#C5EA78',
    },
    {
      gradient: gradientPink,
      color: '#EE91F0',
    },

    {
      gradient: gradientYellow,
      color: '#FFE34C',
    },
    {
      gradient: gradientOrange,
      color: '#FFB470',
    },
    {
      gradient: gradientBlue,
      color: '#4CC9FF',
    },
    {
      gradient: gradientTurquoise,
      color: '#4CE6FF',
    },
    {
      gradient: gradientGreen,
      color: '#6BDF9A',
    },
    {
      gradient: gradientPurple,
      color: '#9491F0',
    },
    {
      gradient: gradientLime,
      color: '#C5EA78',
    },
    {
      gradient: gradientPink,
      color: '#EE91F0',
    },

    {
      gradient: gradientYellow,
      color: '#FFE34C',
    },
    {
      gradient: gradientOrange,
      color: '#FFB470',
    },
    {
      gradient: gradientBlue,
      color: '#4CC9FF',
    },
    {
      gradient: gradientTurquoise,
      color: '#4CE6FF',
    },
    {
      gradient: gradientGreen,
      color: '#6BDF9A',
    },
    {
      gradient: gradientPurple,
      color: '#9491F0',
    },
    {
      gradient: gradientLime,
      color: '#C5EA78',
    },
    {
      gradient: gradientPink,
      color: '#EE91F0',
    },

    {
      gradient: gradientYellow,
      color: '#FFE34C',
    },
    {
      gradient: gradientOrange,
      color: '#FFB470',
    },
    {
      gradient: gradientBlue,
      color: '#4CC9FF',
    },
    {
      gradient: gradientTurquoise,
      color: '#4CE6FF',
    },
    {
      gradient: gradientGreen,
      color: '#6BDF9A',
    },
    {
      gradient: gradientPurple,
      color: '#9491F0',
    },
    {
      gradient: gradientLime,
      color: '#C5EA78',
    },
    {
      gradient: gradientPink,
      color: '#EE91F0',
    },

    {
      gradient: gradientYellow,
      color: '#FFE34C',
    },
    {
      gradient: gradientOrange,
      color: '#FFB470',
    },
    {
      gradient: gradientBlue,
      color: '#4CC9FF',
    },
    {
      gradient: gradientTurquoise,
      color: '#4CE6FF',
    },
    {
      gradient: gradientGreen,
      color: '#6BDF9A',
    },
    {
      gradient: gradientPurple,
      color: '#9491F0',
    },
    {
      gradient: gradientLime,
      color: '#C5EA78',
    },
    {
      gradient: gradientPink,
      color: '#EE91F0',
    },

    {
      gradient: gradientYellow,
      color: '#FFE34C',
    },
    {
      gradient: gradientOrange,
      color: '#FFB470',
    },
    {
      gradient: gradientBlue,
      color: '#4CC9FF',
    },
    {
      gradient: gradientTurquoise,
      color: '#4CE6FF',
    },
    {
      gradient: gradientGreen,
      color: '#6BDF9A',
    },
    {
      gradient: gradientPurple,
      color: '#9491F0',
    },
    {
      gradient: gradientLime,
      color: '#C5EA78',
    },
    {
      gradient: gradientPink,
      color: '#EE91F0',
    },

    {
      gradient: gradientYellow,
      color: '#FFE34C',
    },
    {
      gradient: gradientOrange,
      color: '#FFB470',
    },
    {
      gradient: gradientBlue,
      color: '#4CC9FF',
    },
    {
      gradient: gradientTurquoise,
      color: '#4CE6FF',
    },
    {
      gradient: gradientGreen,
      color: '#6BDF9A',
    },
    {
      gradient: gradientPurple,
      color: '#9491F0',
    },
    {
      gradient: gradientLime,
      color: '#C5EA78',
    },
    {
      gradient: gradientPink,
      color: '#EE91F0',
    },

    {
      gradient: gradientYellow,
      color: '#FFE34C',
    },
    {
      gradient: gradientOrange,
      color: '#FFB470',
    },
  ];
}

export { gradientGenerator, colors, gradientGrey };
