export enum USER_SETTING_STEPS_ENUM {
  ORGANISER_DETAILS = 'ORGANISER_DETAILS',
  BANK_DETAILS = 'BANK_DETAILS',
}

export enum CREATE_EVENT_FORM_STEPS_ENUM {
  BASIC_INFO = 'BASIC_INFO',
  DETAILS = 'DETAILS',
  TICKETS = 'TICKETS',
  TAXES = 'TAXES',
  PERMISSIONS = 'PERMISSIONS',
  PROMOTE = 'PROMOTE',
}

export type PaymentGatewayType = {
  settingType: string;
  value: string;
  gatewayType: string;
};
