import { FC, useCallback, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';
import { Form, Input, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { activePromoterSelector } from 'selectors/systemSlice.selector';
import { useEditPromoterInfoMutation } from 'services/promoter/promoterApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { useAppSelector } from 'store';

import FormSubmit from 'containers/form-submit';

import s from './BankDetails.module.scss';
import BankDetailsBillingInfoForm from './bank-details-billing-info-form';
import BankDetailsStripeInfoInput from './bank-details-stripe-info-input';
import {
  BANK_DETAILS_FORM_ENUM,
  BANK_DETAILS_FORM_TYPE,
  createPaymentGatewaySettingObj,
} from './bankDetails.utils';
import EventModal from './event-modal';

const BankDetails: FC = () => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const isEditingRef = useRef(false);
  const [editingList, setEditingList] = useState<BANK_DETAILS_FORM_TYPE>({});
  const activePromoter = useAppSelector(activePromoterSelector);
  const [editPromoter] = useEditPromoterInfoMutation();
  const isCheckedNoStripeUsage = Form.useWatch('notStripeUsed', form);
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const [openEventModal, setOpenEventModal] = useState(false);
  const handleChange = (_, all) => {
    setIsEditing(true);
    isEditingRef.current = true;
    setEditingList(all);
  };

  const handleSubmit = useCallback(
    async (data) => {
      const res = await editPromoter({
        ...activePromoter,
        ...data,
        ...(!data?.notStripeUsed &&
          data[BANK_DETAILS_FORM_ENUM.BANK_STRIPE_ACCOUNT_NUMBER] && {
            paymentGatewaySettings: [
              createPaymentGatewaySettingObj(
                'Stripe',
                data[BANK_DETAILS_FORM_ENUM.BANK_STRIPE_ACCOUNT_NUMBER],
              ),
            ],
          }),
        country: 'Malta',
      });

      if ('data' in res) {
        setIsEditing(false);
        isEditingRef.current = false;
        if (currentUser?.hasEvents) {
          setOpenEventModal(true);
        }
      }
    },
    [activePromoter, currentUser?.hasEvents, editPromoter],
  );

  useEffect(() => {
    if (activePromoter) {
      Object.keys(BANK_DETAILS_FORM_ENUM).forEach((key) => {
        if (BANK_DETAILS_FORM_ENUM[key] in activePromoter) {
          const label = BANK_DETAILS_FORM_ENUM[key];
          form?.setFieldValue(BANK_DETAILS_FORM_ENUM[key], activePromoter[label]);
        }
      });
    }
    form.setFieldValue(BANK_DETAILS_FORM_ENUM.BILLING_COUNTRY, 'Malta');
  }, [activePromoter, form]);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  return (
    <div className={s.wrapper}>
      <Typography type="h2" className={s.title}>
        {t('bank_info_billing_details')}
      </Typography>

      <Form form={form} onFinish={handleSubmit} onValuesChange={handleChange}>
        <div className={s.form}>
          <div className={s.info}>
            <BankDetailsBillingInfoForm form={form} />
          </div>

          <div className={s.info}>
            <div>
              <Typography type="small" className={cn(s.label, s.required)}>
                {t('common_vat_number')}
              </Typography>
              <Form.Item
                name={BANK_DETAILS_FORM_ENUM.BILLING_VAT_NUMBER}
                fullWidth
                rules={[formRules.required]}>
                <Input name={BANK_DETAILS_FORM_ENUM.BILLING_VAT_NUMBER} />
              </Form.Item>
            </div>
          </div>
        </div>

        <Typography type="h2" className={cn(s.title, s.smallPadding)}>
          {t('bank_info_bank_details')}
        </Typography>
        <div className={cn(s.form, s.gap)}>
          <div>
            <BankDetailsStripeInfoInput form={form} />
          </div>
          <div className={cn(s.hint, isCheckedNoStripeUsage && s.long)}>
            <b>We value your privacy and security.</b> At ShowsHappening, we do everything to make
            sure your data is safe with us, at all times. Your data is stored securely and is used
            only for internal communications and billing purposes. Please ensure all details are
            correct and updated.
            {isCheckedNoStripeUsage && <div>{t('bank_info_iban_hint')}</div>}
          </div>
        </div>

        <FormSubmit isEditing={isEditing} editingList={editingList} type="bank" />
        <EventModal open={openEventModal} setOpen={setOpenEventModal} />
      </Form>
    </div>
  );
};

export default BankDetails;
