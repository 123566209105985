import React, { FC, useCallback, useEffect, useState } from 'react';

import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import type { UploadProps } from 'antd/es/upload/interface';
import 'antd/lib/modal/style/index';
import 'antd/lib/slider/style/index';
import cn from 'classnames';
import { getBase64 } from 'tools/base64';
import { convertBytes } from 'tools/convertImageSize';
import { handleCutString } from 'tools/cutstring';
import { getImageFormatFromBase64 } from 'tools/getImageType';
import { t } from 'tools/i18n';
import { Icon, Typography } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import { useUploadEventFlyerMutation } from 'services/events/eventsApiService';
import { setActiveEvent, setEventFlyer } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store/index';

import s from './EventCreateFormDetailsUploadImage.module.scss';

const { Dragger } = Upload;

const EventCreateFormDetailsUploadImage: FC = () => {
  const dispatch = useAppDispatch();
  const [imageObj, setImageObj] = useState<{
    name?: string;
    url?: string;
    size?: string;
    format?: string;
    base64?: string;
  }>();
  const [logoUrl, setLogoUrl] = useState<string>();
  const [loadFlyer, setLoadFlyer] = useState<{ loading?: boolean; done?: boolean }>();
  const [uploadText, setUploadText] = useState<string>(t('common_uploading'));
  const [uploadFlyer] = useUploadEventFlyerMutation();
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);

  const props: UploadProps = {
    onRemove: () => {
      setImageObj(undefined);
    },
    beforeUpload: (file) => {
      getBase64(file, (url) => {
        const decodedInfo = getImageFormatFromBase64(url);

        setLoadFlyer({ loading: true, done: false });
        setImageObj({
          name: file.name,
          url,
          size: convertBytes(file.size),
          format: `image/${decodedInfo?.format}`,
          base64: url,
        });
        dispatch(setEventFlyer(url));
        setTimeout(() => {
          setLoadFlyer({ loading: false, done: true });
          setTimeout(() => setUploadText(t('common_completed')), 2500);
        }, 1000);
      });

      return false;
    },
    multiple: false,
    maxCount: 1,
    showUploadList: false,
  };
  const handleDeleteLogo = () => {
    setLogoUrl('');
    setLoadFlyer({});
    setImageObj({});
  };

  const handleFlyerUpload = useCallback(async () => {
    if (imageObj?.base64) {
      const res = await uploadFlyer({
        eventId: activeEvent?.eventId || '',
        flyerBase64: imageObj?.base64,
        extension: imageObj?.format,
      });

      if ('data' in res) {
        dispatch(setActiveEvent(res.data));
      }
    }
  }, [activeEvent?.eventId, dispatch, imageObj?.base64, imageObj?.format, uploadFlyer]);

  useEffect(() => {
    if (imageObj?.url) {
      setLogoUrl(imageObj.url);
      handleFlyerUpload();
    } else {
      setLogoUrl(undefined);
    }
  }, [handleFlyerUpload, imageObj, setLogoUrl]);

  // useEffect(() => {},[])

  useEffect(() => {
    if (activeEvent?.media?.flyer) {
      setLogoUrl(activeEvent.media.flyer);
    }
  }, [activeEvent]);

  return (
    <div>
      <Typography type="h2" className={s.title}>
        {t('event_create_form_event_image')}
      </Typography>

      <div className={s.block}>
        <ImgCrop
          modalProps={{ okButtonProps: { block: true }, cancelButtonProps: { block: true } }}
          aspect={2 / 3}
          aspectSlider
          rotationSlider={false}
          quality={1}>
          <Dragger {...props} className={s.dragger} disabled={!!logoUrl}>
            {logoUrl ? (
              <img src={logoUrl} alt="flyer" className={s.image} />
            ) : (
              <div className={s.hint}>
                <Icon name="thumbnail" size={40} />
                <Typography type="small" className={s.uploadHint}>
                  {t('settings_upload_logo')}
                  <br />
                  <span>
                    A4 sizes are best for our website (and make sure our posters are A4 sizes).
                  </span>
                </Typography>
                <Typography type="small" className={s.uploadHintType}>
                  {t('setting_upload_logo_type')}
                </Typography>
              </div>
            )}
          </Dragger>
        </ImgCrop>

        {imageObj?.url && (
          <div className={s.uploadWrapper}>
            <div>
              <Typography type="main" className={s.uploadText}>
                {t(uploadText)}
              </Typography>
              <Typography type="small" color="grey">
                {handleCutString(imageObj?.name || '')} - {imageObj?.size}
              </Typography>
              <div className={cn(s.upload)}>
                <div
                  className={cn(s.content, {
                    [s.fill]: loadFlyer?.loading,
                    [s.done]: loadFlyer?.done,
                  })}
                />
              </div>
            </div>

            <button onClick={handleDeleteLogo} className={s.delete}>
              <Icon name="trashBox" size={18} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventCreateFormDetailsUploadImage;
