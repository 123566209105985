import { Dispatch, FC, SetStateAction } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { FormInstance, Icon, Typography } from 'ui';

import { useDeleteEventDatesMutation } from 'services/events/eventsApiService';

import { MultipleDatesType } from 'types/events/eventTypes';

import s from './MultipleDateList.module.scss';
import { getDateFormat } from './multipleDateList.utils';

interface MultipleDateListProps {
  data: MultipleDatesType[];
  setShowsDates: Dispatch<SetStateAction<MultipleDatesType[]>>;
  setEditIndex: Dispatch<SetStateAction<number>>;
  form?: FormInstance;
}
const MultipleDateList: FC<MultipleDateListProps> = (props) => {
  const { data, setShowsDates, setEditIndex, form } = props;
  const [deleteDates] = useDeleteEventDatesMutation();

  const handleDeleteDates = (id: string, i) => {
    setShowsDates((prevState) => prevState?.filter((_, index) => index !== i));
    if (id) {
      deleteDates(id);
    }
  };

  const handleEditDates = (date, i) => {
    form?.setFieldsValue({
      showDates: {
        startDate: dayjs(date.startDate, 'YYYY-MM-DD'),
        startTime: dayjs(`${date.startDate} ${date.startTime}`),
        endDate: dayjs(date.endDate, 'YYYY-MM-DD'),
        endTime: dayjs(`${date.endDate} ${date.endTime}`),
      },
    });
    setEditIndex(i);
  };

  return (
    <div className={s.wrapper}>
      {data &&
        data.map((date, i) => {
          const startDate = `${date.startDate} ${date.startTime}`;
          const endDate = `${date.endDate || dayjs().format('YYYY-MM-DD')} ${date.endTime || ''}`;
          const getEventDuration = () => {
            if (date?.endDate && date?.endTime) {
              const hours = dayjs(`${date.endDate || date.startDate} ${date.endTime}`).diff(
                `${date.startDate} ${date.startTime}`,
                'h',
              );
              const minutes =
                dayjs(`${date.endDate || date.startDate} ${date.endTime}`).diff(
                  `${date.startDate} ${date.startTime}`,
                  'm',
                  true,
                ) % 60;

              return `${hours.toFixed(0)}h ${minutes ? `${minutes}m` : ''}`;
            }
          };

          return (
            <div key={`${date.startDate}.${i}`} className={s.item}>
              <Typography type="main" weight={600}>
                {`${getDateFormat(startDate)} ${
                  date?.endDate && date?.endTime
                    ? `- ${getDateFormat(`${date.endDate} ${date.endTime || ''}`)}`
                    : ''
                }`}
              </Typography>
              <div className={s.itemContent}>
                <div className={s.block}>
                  <div className={s.field}>
                    <Typography type="main" color="grey" weight={500}>
                      {t('multiple_date_start_at')}
                    </Typography>
                    <Typography type="main" weight={500}>
                      {dayjs(startDate).format('hh:mm A')}
                    </Typography>
                  </div>
                  <div className={s.field}>
                    <Typography type="main" color="grey" weight={500}>
                      {t('multiple_date_end_at')}
                    </Typography>
                    <Typography type="main" weight={500}>
                      {dayjs(endDate).format('hh:mm A')}
                    </Typography>
                  </div>
                  <div className={s.field}>
                    <Typography type="main" color="grey" weight={500}>
                      {t('event_create_form_duration')}
                    </Typography>
                    <Typography type="main" weight={500}>
                      {getEventDuration() || '-'}
                    </Typography>
                  </div>
                </div>
                <div className={s.actions}>
                  <button
                    className={s.action}
                    onClick={() => handleEditDates(date, i)}
                    type="button">
                    <Icon name="edit" />
                  </button>
                  <button
                    className={s.action}
                    onClick={() => handleDeleteDates(date?.showId || '', i)}
                    type="button">
                    <Icon name="delete" />
                  </button>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default MultipleDateList;
