import { FC, useContext } from 'react';

import { InputNumber } from 'antd';
import 'antd/lib/input-number/style/index';
import { t } from 'tools/i18n';
import { Form, Label, Select, Switch } from 'ui';
import { formRules } from 'utils/formRules';

import {
  EVENT_CREATE_FORM_DETAILS_ENUM,
  createEventFormDetailsContext,
} from '../eventCreateFormDetails.utils';
import s from './EventCreateFormDetailsAges.module.scss';
import { minimumAges, requiredAges } from './eventCreateFormDetailsAges.utils';

const EventCreateFormDetailsAges: FC = () => {
  const { form } = useContext(createEventFormDetailsContext);
  const childTicketRequired = Form.useWatch(
    EVENT_CREATE_FORM_DETAILS_ENUM.CHILD_TICKET_REQUIRED,
    form,
  );
  const minimumAge = Form.useWatch(EVENT_CREATE_FORM_DETAILS_ENUM.MINIMUM_AGES, form);
  const ageLimit = parseInt(minimumAge?.value?.replace(/\D/g, ''), 10) || 0;

  const agesContent = !childTicketRequired ? (
    <div>
      <Label>{t('common_free_to_age')}</Label>
      <Form.Item name={EVENT_CREATE_FORM_DETAILS_ENUM.FREE_UP_TO_AGE} initialValue={5}>
        <InputNumber className={s.input} min={0} max={21} />
      </Form.Item>
    </div>
  ) : (
    <div>
      <Label>{t('common_ages')}</Label>
      <Form.Item name={EVENT_CREATE_FORM_DETAILS_ENUM.AGES_REQUIRING_TICKET}>
        <Select
          placeholder="Select ages"
          options={requiredAges.map((key) => ({
            label: key,
            value: key,
          }))}
        />
      </Form.Item>
    </div>
  );

  return (
    <div className={s.wrapper}>
      <div>
        <Label required>{t('common_minimum_age')}</Label>
        <Form.Item name={EVENT_CREATE_FORM_DETAILS_ENUM.MINIMUM_AGES} rules={[formRules.required]}>
          <Select
            placeholder="Select age"
            options={minimumAges.map((key) => ({
              label: key,
              value: key,
            }))}
          />
        </Form.Item>
      </div>
      {!!minimumAge && ageLimit < 18 && (
        <>
          <div>
            <Label>{t('common_child_ticket_required')}</Label>
            <Form.Item
              name={EVENT_CREATE_FORM_DETAILS_ENUM.CHILD_TICKET_REQUIRED}
              initialValue={true}>
              <Switch />
            </Form.Item>
          </div>
          {agesContent}
        </>
      )}
    </div>
  );
};

export default EventCreateFormDetailsAges;
