import React, { FC } from 'react';

import s from './ProgressCircle.module.scss';

interface ProgressCircleProps {
  percentage: number;
  size: number;
  radius: number;
  stroke: number;
}

const ProgressCircle: FC<ProgressCircleProps> = ({ percentage, size, radius, stroke }) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <svg height={size} width={size}>
      <circle
        className={s.progressCircleBackground}
        strokeWidth={stroke}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={size / 2}
        cy={size / 2}
      />
      <circle
        className={s.progressCircle}
        strokeWidth={stroke}
        strokeDasharray={`${circumference}, 999`}
        style={{ strokeDashoffset }}
        r={normalizedRadius}
        cx={size / 2}
        cy={size / 2}
      />
    </svg>
  );
};

export default ProgressCircle;
