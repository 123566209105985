import { FC } from 'react';

import { Collapse, CollapsePanelProps } from 'antd';

const { Panel } = Collapse;
const CollapsePanel: FC<CollapsePanelProps> = (props) => {
  return <Panel {...props} />;
};

export default CollapsePanel;
