import { FC } from 'react';

import { Upload as AntUpload, UploadProps } from 'antd';
import 'antd/lib/upload/style/index';

const Upload: FC<UploadProps> = (props) => {
  return <AntUpload {...props} />;
};

export default Upload;
