import { FC, ReactElement, useCallback, useEffect, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';
import { DASHBOARD_ROUTE } from 'routes/dashboard/list';
import { Icon, Typography } from 'ui';

import { activePromoterSelector, editingPromoterSelector } from 'selectors/systemSlice.selector';
import { useLazyGetPromoterInfoQuery } from 'services/promoter/promoterApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { setActivePromoterFullInfo, setEditingPromoter } from 'slices/systemSlice';
import { useAppDispatch, useAppSelector } from 'store';

import { PromoterFullInfoType } from 'types/promoters/promoterTypes';

import s from './AppNavigationPromoterInfo.module.scss';

const AppNavigationPromoterInfo: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const [getPromoterFullInfo] = useLazyGetPromoterInfoQuery();
  const activePromoter = useAppSelector(activePromoterSelector);
  const editingPromoter = useAppSelector(editingPromoterSelector);

  const renderUserLogo = useMemo((): ReactElement => {
    if (activePromoter && activePromoter.logo) {
      return <img className={s.logo} src={activePromoter.logo} alt={activePromoter.name} />;
    } else {
      return (
        <div className={s.logoTextWrapper}>
          <div className={s.logoText}>
            {activePromoter ? activePromoter.name[0] : currentUser?.firstName[0]}
          </div>
        </div>
      );
    }
  }, [activePromoter, currentUser]);

  const handleBack = useCallback(() => {
    navigate(DASHBOARD_ROUTE);
  }, [navigate]);

  const handleGetFullPromoterInfo = useCallback(
    async (id?: string) => {
      const promoterId = id || currentUser?.promoters[0].promoterId;
      if (promoterId) {
        const res = await getPromoterFullInfo(promoterId);

        if ('data' in res) {
          dispatch(setActivePromoterFullInfo(res?.data as PromoterFullInfoType));
          dispatch(setEditingPromoter(false));
        }
      }
    },
    [currentUser, getPromoterFullInfo, dispatch],
  );

  useEffect(() => {
    getCurrentUser();

    dispatch(setEditingPromoter(true));
  }, [getCurrentUser, dispatch]);

  useEffect(() => {
    if (editingPromoter && currentUser && !activePromoter) {
      handleGetFullPromoterInfo();
    }
  }, [handleGetFullPromoterInfo, currentUser, editingPromoter, activePromoter]);

  return (
    <div className={s.wrapper}>
      <button className={s.back} onClick={handleBack}>
        <Icon name="arrowRight" size={26} />
      </button>

      <div className={s.promoter}>
        <div className={s.popoverWrapper}>
          {renderUserLogo}

          <div>
            <Typography type="main" className={s.name}>
              {activePromoter?.name || currentUser?.fullName}
            </Typography>
            <Typography type="small" className={s.email}>
              {activePromoter?.email || currentUser?.email}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppNavigationPromoterInfo;
