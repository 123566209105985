import { FC, useCallback, useEffect, useState } from 'react';

import { useSetQuery } from 'tools/hooks';
import { t } from 'tools/i18n';
import { Checkbox, Form, Input, Label, Typography } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import { useAddedTaxesEventMutation } from 'services/events/eventsApiService';
import { useLazyGetPromoterInfoQuery } from 'services/promoter/promoterApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { setActiveEvent } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store';

import FormSubmit from 'containers/form-submit';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { REFUND_POLICY_TYPE } from 'types/events/eventTypes';

import EventCreateFormBasicInfoPromoter from '../event-create-form-basic-info/event-create-form-basic-info-promoter';
import s from './EventCreateFormTaxes.module.scss';
import { EventCreateTaxesFormType, TAXES_FORM_ENUM } from './eventCreateFormTaxes.utils';

const EventCreateFormTaxes: FC = () => {
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);
  const [addedTaxesDetails, { isLoading }] = useAddedTaxesEventMutation();
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const [getPromoterFullInfo, { data: promoter }] = useLazyGetPromoterInfoQuery();
  const [isEditing, setIsEditing] = useState(false);
  const [editingList, setEditingList] = useState<EventCreateTaxesFormType>();
  const dispatch = useAppDispatch();
  const [isDisableVat, setIsDisableVat] = useState(false);
  const setQuery = useSetQuery();

  const handleValuesChange = (_, values) => {
    setIsEditing(true);
    setEditingList(values);
  };

  const handleChangeDisableVat = (value) => {
    setIsDisableVat(value);
    setIsEditing(true);
  };

  const handleSubmit = useCallback(
    async (data) => {
      const res = await addedTaxesDetails({
        eventId: activeEvent?.eventId || '',
        taxDetails: !isDisableVat
          ? {
              ...data,
              refundPolicy: REFUND_POLICY_TYPE.NO_REFUNDS,
            }
          : {
              permitNumber: null,
              vatRate: 0,
              refundPolicy: REFUND_POLICY_TYPE.NO_REFUNDS,
            },
      });

      if ('data' in res) {
        setIsEditing(false);
        dispatch(setActiveEvent(res?.data));
        setQuery(CREATE_EVENT_FORM_STEPS_ENUM.PERMISSIONS);
      }
    },
    [activeEvent?.eventId, addedTaxesDetails, dispatch, isDisableVat, setQuery],
  );

  useEffect(() => {
    getCurrentUser().then((data) => {
      if (data?.data?.promoters?.length) {
        getPromoterFullInfo(data?.data?.promoters[0]?.promoterId || '');
      }
    });
  }, [getCurrentUser, getPromoterFullInfo]);

  return (
    <div className={s.wrapper}>
      <Form onValuesChange={handleValuesChange} onFinish={handleSubmit}>
        <Typography type="h2" className={s.title}>
          {t('common_taxes')}
        </Typography>
        <div className={s.form}>
          <div className={s.info}>
            <Typography type="main">{t('event_create_taxes_text')}</Typography>

            <div className={s.formContent}>
              <div>
                <Checkbox value={isDisableVat} onChange={handleChangeDisableVat}>
                  VAT is not applicable
                </Checkbox>
              </div>
              {!isDisableVat && (
                <>
                  <div className={s.row}>
                    <div className={s.item}>
                      <Label>{t('common_vat_number')}</Label>
                      <Input name="vatNumber" value={promoter?.billingVATNumber} disabled />
                    </div>
                    <div className={s.item}>
                      <Label required>{t('common_vat_permit')}</Label>
                      <Form.Item
                        name={TAXES_FORM_ENUM.PERMIT_NUMBER}
                        initialValue={activeEvent?.taxDetails?.permitNumber}>
                        <Input name="permit" placeholder="123/23" disabled={isDisableVat} />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <Label required>{t('common_vat_rate')}</Label>
                    <Form.Item
                      name={TAXES_FORM_ENUM.VAT_RATE}
                      initialValue={String(activeEvent?.taxDetails?.vATRate)}>
                      <Input name="vatRate" placeholder="0" disabled={isDisableVat} />
                    </Form.Item>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={s.info}>
            <EventCreateFormBasicInfoPromoter />
          </div>
        </div>
        <FormSubmit
          isEditing={isEditing}
          editingList={editingList}
          type={isDisableVat ? 'empty' : 'taxesDetails'}
          fetching={isLoading}
        />
      </Form>
    </div>
  );
};

export default EventCreateFormTaxes;
