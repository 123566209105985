import { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Button, Modal, Typography } from 'ui';

import s from './WarningModal.module.scss';

interface WelcomeModalProps {
  // Define your component props here
}

const WarningModal: FC<WelcomeModalProps> = () => {
  const [open, setOpen] = useState<boolean>(true);
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate('/my-events');
  };

  const goToSettings = () => {
    navigate('/organiser-details');
  };

  return (
    <Modal handleClose={handleClose} open={open} footer={false}>
      <Modal.Header>
        <Typography type="h4">Please fill out the organizer information</Typography>
      </Modal.Header>
      <Modal.Content>
        <div className={s.wrapper}>
          <div>
            <Typography type="main" color="grey" className={s.text}>
              To create an event you need information about the promoter, as it will be specified in
              the event tickets, please fill in the information
            </Typography>
          </div>
          <Typography type="main" color="grey" className={s.text}>
            {`Click 'Fill out' to fill in your organiser details before creating your first event.`}
          </Typography>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button fullWidth onClick={goToSettings}>
          Fill out
        </Button>
        <Button fullWidth color="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default WarningModal;
