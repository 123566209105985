import React, { FC } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';
import { Icon, Layout, Typography } from 'ui';

import { USER_SETTING_STEPS_ENUM } from 'types/core/systemTypes';

import s from '../AppNavigation.module.scss';
import AppNavigationPromoterInfo from '../app-navigation-promoter-info';

interface SettingNavigationTemplateProps {
  handleLogout: () => void;
  sameString: (str: string) => boolean;
  handleNavigate: (path: string) => void;
  isOpenedSidebar: boolean;
  apiVersion?: { versionNumber: string };
}
const SettingNavigationTemplate: FC<SettingNavigationTemplateProps> = (props) => {
  const { handleLogout, handleNavigate, sameString, isOpenedSidebar, apiVersion } = props;

  return (
    <Layout.Sider opened={isOpenedSidebar} theme="light">
      <div className={cn(s.wrapper, s.light)}>
        <div className={s.logoLight}>
          <Icon name="logo" width={252} height={40} />
        </div>
        <AppNavigationPromoterInfo />
        <div className={cn(s.content, s.setting)}>
          {Object.keys(USER_SETTING_STEPS_ENUM).map((key) => (
            <button
              key={key}
              className={cn(s.navLink, sameString(key) && s.active)}
              onClick={() => handleNavigate(key)}>
              <Icon name={key.toLowerCase()} />
              {t(`common_${key.toLowerCase()}`)}
            </button>
          ))}
        </div>
        <button onClick={handleLogout} className={s.actions}>
          <Typography type="main" color="red" weight={500} className={s.logout}>
            <Icon name="logout" />
            {isOpenedSidebar && <span className={s.text}>{t('common_log_out')}</span>}
          </Typography>
        </button>
        <div className={s.version}>{`ShowsManager ${apiVersion?.versionNumber || 'v.1.0'}`}</div>
      </div>
    </Layout.Sider>
  );
};

export default SettingNavigationTemplate;
