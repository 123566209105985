import React, { FC, useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { Breadcrumbs, Button, Table } from 'ui';

import {
  useLazyExportAttendeesQuery,
  useLazyGetEventAttendeesQuery,
} from 'services/events/eventsApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';

import Pagination from '../../../component/pagination';
import SectionTitle from '../../../component/section-title/sectionTitle';
import { MY_EVENTS_ROUTE } from '../../../routes/events/list';
import s from './EventAttendeesList.module.scss';

const initialFilter = {
  PageNumber: 1,
  PageSize: 25,
};
const EventAttendeesList: FC = () => {
  const { eventId } = useParams();
  const [exportAttendees] = useLazyExportAttendeesQuery();
  const [requestFilter, setRequestFilter] = useState(initialFilter);
  const [getAttendees, { data: attendees }] = useLazyGetEventAttendeesQuery();
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const currentEvent = currentUser?.events?.find((event) => event.eventId === eventId)?.eventName;

  const BREADCRUMBS_ROUTES = [
    { name: 'My Events', path: MY_EVENTS_ROUTE },
    { name: `Attendees Report: ${currentEvent || ''}` },
  ];

  const handleDownloadAttendees = useCallback(() => {
    if (eventId) {
      exportAttendees({ id: eventId }).then((data) => {
        if (data.data) {
          const link = document.createElement('a');
          link.href = data.data?.fileName;
          link.setAttribute('download', `attendees-${eventId}.csv`);
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      });
    }
  }, [exportAttendees, eventId]);

  const columns = [
    {
      title: 'Event date',
      dataIndex: 'show',
      key: 'show',
      render: (show: { date: string; time: string }) => {
        return (
          <div>
            {dayjs(show.date).format('DD.MM.YYYY')} {dayjs(show.time).format('HH:mm')}
          </div>
        );
      },
    },
    {
      title: 'Receipt No',
      dataIndex: 'receiptNumber',
      key: 'receiptNumber',
    },
    {
      title: 'Order No',
      dataIndex: 'order',
      key: 'order',
      render: (order: { orderNo: string }) => <div>{order.orderNo}</div>,
    },
    {
      title: 'Order Date',
      dataIndex: 'order',
      key: 'orderDate',
      render: (order: { orderDate: string }) => (
        <div>{dayjs(order.orderDate).format('DD.MM.YYYY HH:mm')}</div>
      ),
    },
    {
      title: 'Payment Type',
      dataIndex: 'order',
      key: 'paymentType',
      render: (order: { paymentType: string }) => <div>{order.paymentType}</div>,
    },
    {
      title: 'Ordered By',
      dataIndex: 'order',
      key: 'orderedBy',
      render: (order: { user: { fullName: string } }) => <div>{order.user.fullName}</div>,
    },
    {
      title: ' User Email',
      dataIndex: 'order',
      key: 'orderUserEmail',
      render: (order: { user: { email: string } }) => <div>{order.user.email}</div>,
    },
    {
      title: 'Name on Ticket',
      dataIndex: 'ticketHolderPersonalInfo',
      key: 'ticketHolderPersonalInfo',
      render: (holderInfo: { firstName: string; lastName: string }) => (
        <div>{`${holderInfo.firstName} ${holderInfo.lastName}`}</div>
      ),
    },
    {
      title: 'Mobile No',
      dataIndex: 'order',
      key: 'userMobile',
      render: (order: { user: { mobileNumber: string } }) => <div>{order.user.mobileNumber}</div>,
    },
    {
      title: 'Ticket Type',
      dataIndex: 'ticketType',
      key: 'ticketType',
      render: (ticketType: { name: string }) => <div>{ticketType.name}</div>,
    },
    {
      title: 'Qty',
      dataIndex: 'numberOfPersons',
      key: 'numberOfPersons',
    },
    {
      title: 'Amount',
      dataIndex: 'subTotal',
      key: 'amount',
      render: (total: number) => <div>{total} €</div>,
    },
    {
      title: 'Barcode Number',
      dataIndex: 'barCode',
      key: 'orderBarcode',
    },
  ];

  const showedElements =
    Number(attendees?.attendees?.length) +
    (Number(attendees?.pageNumber) - 1) * Number(requestFilter.PageSize);

  useEffect(() => {
    if (eventId) {
      getAttendees({ id: eventId, ...requestFilter });
    }
  }, [eventId, getAttendees, requestFilter]);

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  return (
    <div className={s.wrapper}>
      <div className={s.header}>
        <div className={s.block}>
          <div>
            <SectionTitle content="Attendees" className={s.title} withOutMargin />
          </div>
          <Button onClick={handleDownloadAttendees}>Download CSV</Button>
        </div>

        <Breadcrumbs routes={BREADCRUMBS_ROUTES} className={s.breadcrumbs} />
      </div>

      <Table
        columns={columns}
        dataSource={attendees?.attendees}
        scroll={{ x: '100%' }}
        pagination={false}
      />
      <Pagination
        handlePageChange={(page) =>
          setRequestFilter((prevValue) => ({ ...prevValue, PageNumber: page }))
        }
        showedElements={showedElements}
        totalElements={attendees?.totalRecords}
        total={attendees?.totalPages}
        currentPage={attendees?.pageNumber || 1}
        handleSizeChange={(size) =>
          setRequestFilter((prevValue) => ({ ...prevValue, PageSize: size }))
        }
        defaultPageSize={25}
      />
    </div>
  );
};

export default EventAttendeesList;
