import React, { FC } from 'react';

import cn from 'classnames';

import Icon from '../icon';
import Link from '../link';
import Typography, { TypographyTypes } from '../typography';
import WithSkeletonLoader from '../with-skeleton-loader';
import s from './Breadcrumbs.module.scss';

interface ItemRenderRoute {
  path?: string;
  name: string;
}

export interface BreadcrumbsProps {
  labelType?: TypographyTypes;
  isLoading?: boolean;
  divider?: string | React.ReactElement;
  routes: ItemRenderRoute[];
  loader?: React.ReactElement;
  className?: string;
}

const Breadcrumbs: FC<BreadcrumbsProps> = (props) => {
  const {
    loader,
    isLoading,
    divider = <Icon name="simpleArrowRight" className={s.divider} width={4} />,
    routes = [],
    labelType = 'small',
    className = '',
  } = props;

  const breadcrumbRenderer = (breadcrumb: ItemRenderRoute, index: number) => {
    const key = `breadcrumb-${breadcrumb.name}-${index}`;
    const dividerElem = index !== 0 && divider;
    return !breadcrumb.path ? (
      <div className={cn(s.currentLocation, s.breadcrumbWrapper)} key={key}>
        {dividerElem}
        <Typography type={labelType}>{breadcrumb.name}</Typography>
      </div>
    ) : (
      <div className={s.breadcrumbWrapper} key={key}>
        {dividerElem}
        <Link label={breadcrumb.name} to={breadcrumb.path} labelType={labelType} />
      </div>
    );
  };

  return (
    <div className={cn(s.wrapper, { [className]: className })}>
      <WithSkeletonLoader isLoading={isLoading} skeletonLoader={loader}>
        {routes.map(breadcrumbRenderer)}
      </WithSkeletonLoader>
    </div>
  );
};

export default Breadcrumbs;
