import { FC } from 'react';

import { t } from 'tools/i18n';
import { Form, Input, Label, Typography } from 'ui';

import { VIDEO_PLACEHOLDER } from 'constants/core-constants';

import { EVENT_CREATE_FORM_DETAILS_ENUM } from '../eventCreateFormDetails.utils';
import s from './EventCreateFormDetailsSocials.module.scss';
import { socialsFields } from './eventCreateFormDetailsSocials.utils';

const EventCreateFormDetailsSocials: FC = () => {
  const socialsLinksInputs = socialsFields.map(({ name, label, placeholder }) => (
    <div key={name}>
      <Label>{t(label)}</Label>
      <Form.Item name={name}>
        <Input name={name} placeholder={placeholder} />
      </Form.Item>
    </div>
  ));
  return (
    <div className={s.wrapper}>
      <Typography type="h2" className={s.title}>
        {t('common_video')}
      </Typography>
      <div>
        <Label>{t('field_video')}</Label>
        <Form.Item name={EVENT_CREATE_FORM_DETAILS_ENUM.VIDEO_LINK}>
          <Input name={EVENT_CREATE_FORM_DETAILS_ENUM.VIDEO_LINK} placeholder={VIDEO_PLACEHOLDER} />
        </Form.Item>
      </div>

      <Typography type="h2" className={s.title}>
        {t('field_social_media')}
      </Typography>
      <div className={s.socialsLinks}>{socialsLinksInputs}</div>
    </div>
  );
};

export default EventCreateFormDetailsSocials;
