import InternalCollapse from './collapse';
import CollapsePanel from './collapse-panel';

type CompoundedComponents = typeof InternalCollapse & {
  Panel: typeof CollapsePanel;
};

const Collapse = InternalCollapse as CompoundedComponents;
Collapse.Panel = CollapsePanel;
export default Collapse;
