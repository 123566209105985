import { FC } from 'react';

import dayjs from 'dayjs';
import { DatePicker, Form, Label } from 'ui';

import { EVENT_CREATE_FORM_BASIC_INFO_ENUM } from 'pages/events/events-create-event-form/event-create-form-basic-info/basicInfo.utils';

import s from './NoDates.module.scss';

interface NoDatesProps {
  // Define your component props here
}

const NoDates: FC<NoDatesProps> = () => {
  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
  };

  return (
    <div className={s.wrapper}>
      <div>
        <Label>End Sales Date</Label>
        <Form.Item name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.LAST_DATE}>
          <DatePicker
            fullWidth
            disabledDate={(current) => disabledDate(current)}
            format="ddd DD MMMM YYYY"
          />
        </Form.Item>
      </div>
      <div>
        <Label>End Sales Time</Label>
        <Form.Item name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.LAST_TIME}>
          <DatePicker.TimePicker fullWidth format="HH:mm" />
        </Form.Item>
      </div>
    </div>
  );
};

export default NoDates;
