import { Dispatch, FC, SetStateAction } from 'react';

import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';

import { EventTicketType } from 'types/events/eventTypes';

import s from './TicketsList.module.scss';
import TicketItem from './ticket-item';

interface TicketsListProps {
  list: EventTicketType[];
  setList: Dispatch<SetStateAction<EventTicketType[]>>;
  currency: string;
  setActiveEditTicket: Dispatch<SetStateAction<EventTicketType | undefined>>;
}
const TicketsList: FC<TicketsListProps> = (props) => {
  const { list, setList, currency, setActiveEditTicket } = props;

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id && over && over.id !== active.id) {
      const oldIndex = list.findIndex(
        (ticket, index) => `${ticket.name}_${ticket.price}_${index}` === active.id,
      );
      const newIndex = list.findIndex(
        (ticket, index) => `${ticket.name}_${ticket.price}_${index}` === over.id,
      );

      setList((items) => {
        const movedItems = arrayMove(items, oldIndex, newIndex);

        // Update the sortOrder field in the objects
        const updatedItems = movedItems.map((item, index) => {
          return { ...item, sortOrder: index };
        });

        return updatedItems;
      });
    }
  };

  const handleDeleteItem = (id: number) => {
    setList((items) => items.filter((item, i) => i !== id));
  };

  return (
    <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext
        items={list.map((ticket, index) => `${ticket.name}_${ticket.price}_${index}`)}>
        <div className={s.wrapper}>
          {list.map((ticket, i) => (
            <TicketItem
              ticket={ticket}
              index={i}
              currency={currency}
              id={`${ticket?.name}_${ticket.price}_${i}`}
              key={`${ticket?.name}_${ticket.price}_${i}`}
              handleDeleteItem={handleDeleteItem}
              setActiveEditTicket={setActiveEditTicket}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};

export default TicketsList;
