import React, { FC, useEffect, useState } from 'react';

import SectionTitle from 'component/section-title/sectionTitle';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { TICKETS_ORDERS_ROUTE } from 'routes/tikets/list';
import { Breadcrumbs, Dropdown, Icon, Table, Typography } from 'ui';

import { useLazyGetOrderFullInfoQuery } from 'services/tickets/ticketsApiService';

import { orderContent } from 'pages/orders/order-full-info/orderFullInfo.utils';

import { TICKET_STATUS_ENUM } from 'types/tickets/tickets.types';

import s from './OrderFullInfo.module.scss';
import SendTicketModal from './send-ticket-modal';

const DROPDOWN_ITEMS = [{ key: 'send', label: <button className={s.contentItem}>Send</button> }];

const OrderFullInfo: FC = () => {
  const params = useParams<{ id: string }>();
  const [getOrderFullInfo, { data: orderFullInfo }] = useLazyGetOrderFullInfoQuery();
  const [eventName, setEventName] = useState('');
  const [openedDropdown, setOpenedDropdown] = useState(false);
  const [openedSendTicketModal, setOpenedSendTicketModal] = useState(false);

  useEffect(() => {
    if (params.id) {
      getOrderFullInfo(params.id);
    }
  }, [getOrderFullInfo, params.id]);

  const handleClickDropdownItems = (event: { key: string }) => {
    if (event.key === 'send') {
      setOpenedSendTicketModal(true);
      setOpenedDropdown(false);
    }
  };

  useEffect(() => {
    if (orderFullInfo) {
      setEventName(orderFullInfo?.tickets[0]?.ticketType?.name);
    }
  }, [orderFullInfo]);

  const BREADCRUMBS_ROUTES = [
    { name: 'Orders', path: TICKETS_ORDERS_ROUTE },
    { name: `Order: ${!!orderFullInfo && orderFullInfo?.orderNo}` },
  ];

  const ticketsColumns = [
    {
      title: 'PAX',
      dataIndex: 'numberOfPersons',
      key: 'numberOfPersons',
    },
    {
      title: 'Name',
      dataIndex: 'ticketHolderPersonalInfo',
      key: 'ticketHolderPersonalInfo',
      render: (ticketHolderPersonalInfo: { firstName: string; lastName: string }) =>
        ticketHolderPersonalInfo?.firstName,
    },
    {
      title: 'Surname',
      dataIndex: 'ticketHolderPersonalInfo',
      key: 'ticketHolderPersonalInfo',
      render: (ticketHolderPersonalInfo: { firstName: string; lastName: string }) =>
        ticketHolderPersonalInfo?.lastName,
    },
    {
      title: 'Ticket Type',
      dataIndex: 'ticketType',
      key: 'ticketType',
      render: (ticketType: { description: string; id: string; name: string }) => ticketType?.name,
    },
    {
      title: 'Sold At',
      dataIndex: 'subTotal',
      key: 'subTotal',
      render: (value) => `€ ${value.toFixed(2)}`,
    },
    {
      title: 'Attendance Date',
      dataIndex: 'attendanceDate',
      key: 'attendanceDate',
      render: (attendanceDate: string) => dayjs(attendanceDate).format('DD MMM YYYY, HH:mm'),
    },
    {
      title: 'Status',
      dataIndex: 'ticketStatus',
      key: 'ticketStatus',
      render: (ticketStatus: TICKET_STATUS_ENUM) => TICKET_STATUS_ENUM[ticketStatus],
    },
  ];

  return (
    <>
      {!!orderFullInfo && (
        <div className={s.wrapper}>
          <SectionTitle content="Order Full info" className={s.title} />
          <Breadcrumbs routes={BREADCRUMBS_ROUTES} />

          <div className={s.content}>
            {orderContent(orderFullInfo, eventName)?.map((content, index) => (
              <div key={index} className={s.item}>
                {content.map((item, i) => (
                  <div key={i}>
                    <Typography type="small" color="grey">
                      {item.title}
                    </Typography>
                    <Typography type="main" weight={600}>
                      {item.content}
                    </Typography>
                  </div>
                ))}
              </div>
            ))}
          </div>

          <SectionTitle content="Ticket Details" className={s.subTitle} />
          <Table
            dataSource={orderFullInfo.tickets}
            columns={ticketsColumns}
            rowKey="ticketId"
            pagination={{ pageSize: 5, showSizeChanger: false }}
            scroll={{ x: 768 }}
          />

          <div className={s.optionWrapper}>
            <Dropdown
              open={openedDropdown}
              onOpenChange={setOpenedDropdown}
              items={DROPDOWN_ITEMS}
              handleItemClick={handleClickDropdownItems}
              className={s.dropdown}
              placement="top">
              <div className={s.option}>
                <Typography type="main" weight={600}>
                  Ticket Option
                </Typography>
                <Icon name="arrowDropdown" />
              </div>
            </Dropdown>
          </div>
        </div>
      )}

      <SendTicketModal
        open={openedSendTicketModal}
        orderId={orderFullInfo?.orderId || ''}
        handleClose={() => setOpenedSendTicketModal(false)}
      />
    </>
  );
};

export default OrderFullInfo;
