import { FC, memo, useState } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import { handleCutString } from 'tools/cutstring';
import { extractFlyerName } from 'tools/extractFlyerName';
import { formatNumber } from 'tools/formatNumbers';
import { ConfigProvider, Icon, Table, Tooltip, Typography } from 'ui';

import EventListActionsDropdown from 'pages/events/event-list-actions-dropdown/eventListActionsDropdown';

import EventsEmptyState from 'containers/events-empty-state';

import { EventMetricsType, EventWithMetricsType, SingleEventType } from 'types/events/eventTypes';

import { CURRENCIES_SETTING } from '../../../constants/core-constants';
import s from './EventList.module.scss';

interface EventListProps {
  list?: EventWithMetricsType[];
  isLoading?: boolean;
}
const EventsListTableView: FC<EventListProps> = (props) => {
  const { list, isLoading } = props;
  const [isShowedAllDates, setIsShowedAllDates] = useState(false);

  const eventsColumns = [
    {
      dataIndex: 'eventFlyer',
      key: 'eventFlyer',
      render: (eventFlyer: string) => {
        if (extractFlyerName(eventFlyer)) {
          return <img src={eventFlyer} alt="eventFlyer" className={s.flyer} />;
        } else {
          return (
            <div className={s.emptyFlyer}>
              <Icon name="littleLogo" />
            </div>
          );
        }
      },
      width: 70,
    },
    {
      title: 'Event Name',
      dataIndex: 'eventName',
      key: 'eventName',
      render: (eventName: string, row: SingleEventType) => {
        return (
          <div>
            <Typography type="main" weight={500}>
              <Tooltip title={eventName} placement="top">
                {handleCutString(eventName, 35, true)}
              </Tooltip>
            </Typography>
            <Typography type="small" color="grey">
              Owner: <b>{row.promoter.email}</b>
            </Typography>
          </div>
        );
      },
    },
    {
      title: 'Event Date',
      dataIndex: 'dates',
      key: 'dates',
      render: (firstShowDate: { date: string }[], row: SingleEventType) => {
        return (
          <>
            <div
              className={cn(s.dateField, {
                [s.open]: isShowedAllDates && firstShowDate.length > 1,
              })}>
              <Icon name="calendar" size={14} />
              <div>
                <Typography type="main" weight={500} className={s.dateField}>
                  {firstShowDate?.length <= 1 ? (
                    dayjs(firstShowDate[0]?.date).format('DD MMMM YYYY')
                  ) : (
                    <>
                      {dayjs(firstShowDate[0]?.date).format('DD MMMM YYYY')}{' '}
                      <button
                        className={s.showButton}
                        onClick={() => setIsShowedAllDates(!isShowedAllDates)}>
                        <Typography type="small" color="grey" className={s.block}>
                          {isShowedAllDates ? 'Hide' : 'Show'} all dates{' '}
                          <Icon
                            name="arrowDropdown"
                            size={14}
                            className={cn({ [s.active]: isShowedAllDates })}
                          />
                        </Typography>
                      </button>
                    </>
                  )}
                </Typography>
                <div className={s.dates}>
                  {isShowedAllDates &&
                    firstShowDate.slice(1)?.map((date) => (
                      <Typography type="main" weight={500} key={date.date}>
                        {dayjs(date.date).format('DD MMMM YYYY')}
                      </Typography>
                    ))}
                </div>
              </div>
            </div>
            <Typography type="small" color="grey" className={s.dateField}>
              <Icon name="mapMarker" size={14} /> {row.venue.name || '-'}
            </Typography>
          </>
        );
      },
    },
    {
      title: 'Total Revenue',
      dataIndex: 'metrics',
      key: 'metrics',
      render: (metrics: EventMetricsType) => {
        return (
          <>
            <Typography type="main" weight={500}>
              {CURRENCIES_SETTING[metrics?.currency?.toUpperCase() || 'EUR']?.symbol}{' '}
              {formatNumber(metrics.totalSales?.allTime)}
            </Typography>
            <Typography type="small">
              <span className={s.green}>€ {formatNumber(metrics.totalSales?.today)}</span> today
            </Typography>
          </>
        );
      },
    },
    {
      title: 'Tickets Sold',
      dataIndex: 'metrics',
      key: 'metrics',
      render: (metrics: EventMetricsType) => {
        return (
          <>
            <Typography type="main" weight={500}>
              {metrics?.totalTicketsSold?.allTime}
            </Typography>
            <Typography type="small">
              <span className={s.green}>{metrics?.totalTicketsSold?.today}</span> today
            </Typography>
          </>
        );
      },
    },
    {
      title: 'Complimentary',
      dataIndex: 'metrics',
      key: 'metrics',
      render: (metrics: EventMetricsType) => {
        return (
          <>
            <Typography type="main" weight={500}>
              {metrics?.totalComplimentariesIssued?.allTime}
            </Typography>
            <Typography type="small">
              <span className={s.green}>{metrics?.totalComplimentariesIssued?.today}</span> today
            </Typography>
          </>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (row) => {
        return <EventListActionsDropdown event={row} />;
      },
    },
  ];

  const renderEmpty = () => <EventsEmptyState />;

  return (
    <div className={s.wrapper}>
      <ConfigProvider renderEmpty={renderEmpty}>
        <Table
          rowKey="eventId"
          dataSource={list}
          columns={eventsColumns}
          pagination={false}
          loading={isLoading}
          scroll={{ x: 768 }}
          rowClassName={(record) => cn(s.row, { [s.alignTop]: record.dates.length > 1 })}
        />
      </ConfigProvider>
    </div>
  );
};

export default memo(EventsListTableView);
