import React, { FC, useContext } from 'react';

import { Radio } from 'antd';
import { t } from 'tools/i18n';
import { Form, Typography } from 'ui';

import {
  EVENT_CREATE_FORM_BASIC_INFO_ENUM,
  createEventFormBasicInfoContext,
} from '../basicInfo.utils';
import s from './EventCreateFormBasicInfoLocation.module.scss';
import { EVENT_LOCATION_TYPE_ENUM } from './eventCreateFormBasicInfoLocation.utils';
import LocationOther from './location-other';
import LocationVenue from './location-venue';

const EventCreateFormBasicInfoLocation: FC = () => {
  const { form } = useContext(createEventFormBasicInfoContext);
  const locationType = Form.useWatch(EVENT_CREATE_FORM_BASIC_INFO_ENUM.VENUE_TYPE, form);
  const locationContent = () => {
    switch (locationType) {
      case EVENT_LOCATION_TYPE_ENUM.VENUE:
        return <LocationVenue />;
      case EVENT_LOCATION_TYPE_ENUM.NOLOCATION:
        return <LocationOther />;
    }
  };

  const renderRadioButton = () => {
    return Object.keys(EVENT_LOCATION_TYPE_ENUM).map((key) => (
      <Radio.Button value={key} key={key}>
        {t(`common_${key.toLowerCase()}`)}
      </Radio.Button>
    ));
  };

  return (
    <div className={s.wrapper}>
      <Typography type="h2" className={s.title}>
        {t('field_location')}
      </Typography>

      <div className={s.optionsRadio}>
        <Form.Item
          name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.VENUE_TYPE}
          initialValue={EVENT_LOCATION_TYPE_ENUM.VENUE}>
          <Radio.Group buttonStyle="solid">{renderRadioButton()}</Radio.Group>
        </Form.Item>
      </div>

      <div className={s.content}>{locationContent()}</div>
    </div>
  );
};

export default EventCreateFormBasicInfoLocation;
