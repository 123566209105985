import { FC, useContext, useEffect } from 'react';

import { Form, Input } from 'ui';

import { createEventFormBasicInfoContext } from 'pages/events/events-create-event-form/event-create-form-basic-info/basicInfo.utils';

const LocationOther: FC = () => {
  const { form } = useContext(createEventFormBasicInfoContext);

  useEffect(() => {
    form?.resetFields(['location']);
  }, [form]);

  return (
    <Form.Item name={['location', 'locationComment']}>
      <Input name="comment" label="Comment" />
    </Form.Item>
  );
};

export default LocationOther;
