import { FC } from 'react';

import { t } from 'tools/i18n';
import { Form, TextArea, Typography } from 'ui';

import s from '../OrganiserDetails.module.scss';
import { ORGANISER_DETAILS_FORM } from '../organiserDetailsForm.utils';

const AboutCompany: FC = () => {
  return (
    <div>
      <Typography type="small" className={s.label}>
        {t('common_about')}
      </Typography>
      <Form.Item name={ORGANISER_DETAILS_FORM.ABOUT_COMPANY}>
        <TextArea
          rows={7}
          className={s.about}
          autoSize={false}
          maxLength={140}
          showCount
          placeholder="Company description"
          name={ORGANISER_DETAILS_FORM.ABOUT_COMPANY}
        />
      </Form.Item>
    </div>
  );
};

export default AboutCompany;
