import React, { FC, useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import dayjs from 'dayjs';
import { Trans } from 'react-i18next';
import { useSetQuery } from 'tools/hooks';
import { useDebounce } from 'tools/hooks/debounce.hook';
import { t } from 'tools/i18n';
import { Button, CheckableTag, Checkbox, Form, Icon, Input, Select, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import { activePromoterSelector } from 'selectors/systemSlice.selector';
import {
  useAddedTagsMutation,
  useCreateEventBaseInfoMutation,
  useEditEventBaseInfoMutation,
  useLazyGetTagsQuery,
} from 'services/events/eventsApiService';
import { useGetAvailableCurrenciesQuery } from 'services/system/systemApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { setActiveEvent } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store';

import { EVENT_CREATE_FORM_DATES_TYPES } from 'pages/events/events-create-event-form/event-create-form-basic-info/event-create-form-basic-info-dates/eventCreateFormBasicInfoDates.utils';
import EventCreateFormBasicInfoSeatingPlan from 'pages/events/events-create-event-form/event-create-form-basic-info/event-create-form-basic-info-seating-plan';
import WarningModal from 'pages/events/events-create-event-form/warning-modal';

import FormSubmit from 'containers/form-submit';

import { SH_URL } from 'constants/core-constants';
import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { MultipleDatesType } from 'types/events/eventTypes';

import EventTypeSwitcher from '../../../../ui/eventTypeSwitcher';
import s from './BasicInfo.module.scss';
import {
  EVENT_CREATE_FORM_BASIC_INFO_ENUM,
  EVENT_CREATE_FORM_BASIC_INFO_TYPE,
  createEventFormBasicInfoContext,
  prepareEventBasicInfo,
} from './basicInfo.utils';
import EventCreateFormBasicInfoDates from './event-create-form-basic-info-dates';
import EventCreateFormBasicInfoLocation from './event-create-form-basic-info-location';
import EventCreateFormBasicInfoPromoter from './event-create-form-basic-info-promoter';

const CreateEventFormBasicInfo: FC = () => {
  const activePromoter = useAppSelector(activePromoterSelector);
  const activeEditingEvent = useAppSelector(createEventFormActiveEventSelector);
  const [createEvent, { isLoading: isCreatingLoading }] = useCreateEventBaseInfoMutation();
  const [editEvent, { isLoading: isEditingLoading }] = useEditEventBaseInfoMutation();
  const isLoading = isCreatingLoading || isEditingLoading;
  const dispatch = useAppDispatch();
  const [getCurrentUser, { data: currentUser, isFetching: isUserFetching }] =
    useLazyGetCurrentUserQuery();
  const [getTags, { data: tags }] = useLazyGetTagsQuery();
  const [selectedTags, setSelectedTags] = useState<{ name: string; slug: string; id: string }[]>(
    [],
  );
  const [newTag, setNewTag] = useState<string>('');
  const [addedTag] = useAddedTagsMutation();
  const [isAddedTagShow, setIsAddedTagShow] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [editingList, setEditingList] = useState<EVENT_CREATE_FORM_BASIC_INFO_TYPE>({});
  const eventName = Form.useWatch(EVENT_CREATE_FORM_BASIC_INFO_ENUM.EVENT_TITLE, form);
  const debounceEventName = useDebounce(eventName, 1000) as string;
  const primaryCategoryValue = Form.useWatch(EVENT_CREATE_FORM_BASIC_INFO_ENUM.CATEGORIES, form);
  const [needSecondaryCategory, setNeedSecondaryCategory] = useState<boolean>(false);
  const secondaryCategoryValue = Form.useWatch('secondaryCategory', form);
  const [nededPassword, setNededPassword] = useState(false);
  const { data: currencies } = useGetAvailableCurrenciesQuery();
  const [multipleDates, setMultipleDates] = useState<MultipleDatesType[]>([]);
  const setQuery = useSetQuery();
  const handleChange = (_, all) => {
    const { showDates, ...rest } = all;
    setIsEditing(true);
    setEditingList({ ...rest, ...showDates });
  };

  const handleAddTag = useCallback(() => {
    addedTag({ tagName: newTag });
    setIsAddedTagShow(false);
  }, [addedTag, newTag]);

  const handleSubmit = useCallback(
    async (data) => {
      const dateType = data[EVENT_CREATE_FORM_BASIC_INFO_ENUM.DATE_TYPE];
      if (!activeEditingEvent?.eventId) {
        const result = prepareEventBasicInfo(data, currentUser?.promoters[0]?.promoterId);
        if (dateType === EVENT_CREATE_FORM_DATES_TYPES.MULTIPLE_DATES) {
          result.showDates = multipleDates;
        }
        const res = await createEvent(result);

        if ('data' in res) {
          setIsEditing(false);
          dispatch(setActiveEvent(res?.data));
          setQuery(CREATE_EVENT_FORM_STEPS_ENUM.DETAILS);
        }
      } else {
        const result = prepareEventBasicInfo(data, activeEditingEvent?.basicInfo?.promoterId);
        if (dateType === EVENT_CREATE_FORM_DATES_TYPES.MULTIPLE_DATES) {
          result.showDates = multipleDates;
        }
        if (activeEditingEvent?.basicInfo?.dateType === 'recurringEvent') {
          const dates = activeEditingEvent?.basicInfo?.showDates;
          result.showDates = [];
          result.dateType = activeEditingEvent?.basicInfo?.dateType;
          if (dates?.length) {
            const lastDate = dates[dates.length - 1];

            result.lastShowDate = `${dayjs(lastDate.startDate).format('YYYY-MM-DD')} ${
              lastDate.startTime
            }`;
          }
        }
        const res = await editEvent({ basicInfo: result, eventId: activeEditingEvent?.eventId });

        if ('data' in res) {
          setIsEditing(false);
          dispatch(setActiveEvent(res?.data));
        }
      }
    },
    [
      activeEditingEvent,
      createEvent,
      currentUser?.promoters,
      dispatch,
      editEvent,
      multipleDates,
      setQuery,
    ],
  );

  const handleAddSecondaryCategory = useCallback(() => {
    if (!needSecondaryCategory) {
      setNeedSecondaryCategory(true);
    } else {
      setNeedSecondaryCategory(false);
      form.resetFields(['secondaryCategory']);
    }
  }, [form, needSecondaryCategory]);

  useEffect(() => {
    getCurrentUser();
    getTags({ records: 20, term: '' });
  }, [getCurrentUser, getTags]);

  useEffect(() => {
    if (activeEditingEvent?.basicInfo) {
      if (activeEditingEvent?.basicInfo?.eventName) {
        form.setFieldValue(
          EVENT_CREATE_FORM_BASIC_INFO_ENUM.EVENT_TITLE,
          activeEditingEvent?.basicInfo?.eventName,
        );
      }
      form.setFieldValue(
        EVENT_CREATE_FORM_BASIC_INFO_ENUM.TAGS,
        activeEditingEvent?.basicInfo?.tags,
      );
      if (activeEditingEvent?.basicInfo?.tags?.length) {
        setSelectedTags(activeEditingEvent?.basicInfo?.tags);
      }
      form.setFieldValue(EVENT_CREATE_FORM_BASIC_INFO_ENUM.CATEGORIES, {
        value: activeEditingEvent?.basicInfo?.categories[0]?.id,
      });

      form.setFieldValue('privateEvent', activeEditingEvent?.basicInfo?.privateEvent);

      form.setFieldValue(
        EVENT_CREATE_FORM_BASIC_INFO_ENUM.VENUE_TYPE,
        activeEditingEvent?.basicInfo?.venueType?.toUpperCase(),
      );

      form.setFieldValue(
        ['location', 'locationComment'],
        activeEditingEvent?.basicInfo?.location?.locationComment,
      );

      form.setFieldValue(
        'currency',
        activeEditingEvent?.basicInfo?.currency || activePromoter?.currency,
      );

      form.setFieldValue('eventType', {
        value: activeEditingEvent?.basicInfo?.eventType?.toLowerCase(),
      });

      if (activeEditingEvent?.basicInfo?.eventType === 'seated') {
        if (activeEditingEvent?.basicInfo?.seatingPlanId) {
          form.setFieldValue('seatingPlan', {
            value: activeEditingEvent?.basicInfo?.seatingPlanId,
          });
        }
      }
      if (activeEditingEvent?.basicInfo?.showDates?.length === 1) {
        const startDate = dayjs(
          activeEditingEvent?.basicInfo?.showDates[0].startDate,
          'DD-MMM-YYYY',
        );
        const startTime = dayjs(
          `${startDate.format('YYYY-MM-DD')} ${
            activeEditingEvent?.basicInfo?.showDates[0].startTime
          }`,
        );
        const endDate = activeEditingEvent?.basicInfo?.showDates[0].endDate
          ? dayjs(activeEditingEvent?.basicInfo?.showDates[0].endDate, 'DD-MMM-YYYY')
          : null;
        const endTime = activeEditingEvent?.basicInfo?.showDates[0].endTime
          ? dayjs(
              `${endDate?.format('YYYY-MM-DD')} ${
                activeEditingEvent?.basicInfo?.showDates[0].endTime
              }`,
            )
          : null;

        form.setFieldValue(['showDates', 'startDate'], startDate);
        form.setFieldValue(['showDates', 'startTime'], startTime);

        if (endDate) {
          form.setFieldValue(['showDates', 'endDate'], endDate);
        }
        if (endTime) {
          form.setFieldValue(['showDates', 'endTime'], endTime);
        }

        if (activeEditingEvent?.basicInfo?.showDates[0].showId) {
          form.setFieldValue(
            ['showDates', 'showId'],
            activeEditingEvent?.basicInfo?.showDates[0].showId,
          );
        }
      } else {
        setMultipleDates(activeEditingEvent?.basicInfo?.showDates as MultipleDatesType[]);
      }
    } else {
      form.setFieldValue('currency', activePromoter?.currency);
    }
  }, [activeEditingEvent, activePromoter, form]);

  useEffect(() => {
    const convertedUrl = debounceEventName
      ?.toLowerCase()
      .replace(/[^a-zA-Z0-9-]+/g, '-')
      .replace(/-{2,}/g, '-')
      .replace(/-/g, ' ')
      .replace(/\s+/g, '-')
      .replace(/^-+|-+$/g, '');
    form.setFieldValue(EVENT_CREATE_FORM_BASIC_INFO_ENUM.PAGE_URL, convertedUrl);
  }, [debounceEventName, form]);

  useEffect(() => {
    const primCategory = tags?.categorized.find((item) => item.id === primaryCategoryValue?.value);
    const secondaryCategory = tags?.categorized.find(
      (item) => item.id === secondaryCategoryValue?.value,
    );

    if (primCategory?.tags?.length) {
      setSelectedTags([...primCategory.tags, ...(tags?.mytags || [])]);
    }

    if (secondaryCategory?.tags?.length) {
      if (primCategory?.tags?.length) {
        setSelectedTags((prevState) => [...prevState, ...(secondaryCategory?.tags || [])]);
      } else {
        setSelectedTags(secondaryCategory?.tags);
      }
    }
  }, [primaryCategoryValue, secondaryCategoryValue, tags]);

  return (
    <createEventFormBasicInfoContext.Provider value={{ form, onChangeForm: handleChange }}>
      <Form className={s.wrapper} form={form} onFinish={handleSubmit} onValuesChange={handleChange}>
        <Typography type="h2" className={s.title}>
          {t('event_create_form_basic_info')}
        </Typography>

        <div className={s.form}>
          <div className={s.info}>
            <div>
              <Typography type="small" className={cn(s.label)}>
                Event visibility
              </Typography>
              <Form.Item name="privateEvent" initialValue={false}>
                <EventTypeSwitcher />
              </Form.Item>
            </div>
            <div>
              <Typography type="small" className={cn(s.label, s.required)}>
                Event title
              </Typography>
              <Form.Item
                name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.EVENT_TITLE}
                rules={[formRules.required]}>
                <Input name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.EVENT_TITLE} />
              </Form.Item>
            </div>
            <div>
              <Typography type="small" className={cn(s.label, s.required)}>
                Event page URL
              </Typography>
              <div className={s.urlInput}>
                <Typography type="main">
                  {SH_URL}
                  {activePromoter?.url}
                </Typography>

                <Form.Item
                  name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.PAGE_URL}
                  rules={[formRules.eventUrl]}
                  fullWidth>
                  <Input name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.PAGE_URL} />
                </Form.Item>
              </div>
            </div>
            <div>
              <Checkbox value={nededPassword} onChange={setNededPassword}>
                <Typography type="main">Use a passcode?</Typography>
              </Checkbox>
              {nededPassword && (
                <div style={{ marginTop: 10 }}>
                  <Typography type="small" className={cn(s.label)}>
                    Event passcode
                  </Typography>
                  <Form.Item name="inviteCode">
                    <Input name="inviteCode" />
                  </Form.Item>
                </div>
              )}
            </div>
            <div className={s.categories}>
              <div>
                <Typography type="small" className={cn(s.label, s.required)}>
                  {t('common_primary_category')}
                </Typography>
                <Form.Item name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.CATEGORIES}>
                  <Select
                    placeholder={t('common_select_category')}
                    options={tags?.categorized?.map((category) => ({
                      label: category.name,
                      value: category.id,
                    }))}
                  />
                </Form.Item>
              </div>
              {needSecondaryCategory && (
                <div>
                  <Typography type="small" className={cn(s.label)}>
                    {t('common_secondary_category')}
                  </Typography>
                  <Form.Item name="secondaryCategory">
                    <Select
                      placeholder={t('common_secondary_category')}
                      options={tags?.categorized
                        ?.filter((item) => item.id !== primaryCategoryValue?.value)
                        ?.map((category) => ({
                          label: category.name,
                          value: category.id,
                        }))}
                    />
                  </Form.Item>
                </div>
              )}
              <button className={s.addCategory} onClick={handleAddSecondaryCategory}>
                <Typography type="small" className={s.addText}>
                  {!needSecondaryCategory
                    ? '+ Add secondary category'
                    : '- Remove secondary category'}
                </Typography>
              </button>
            </div>

            {!!selectedTags?.length && (
              <div>
                <Typography type="small" className={cn(s.label)}>
                  {t('common_tags')}
                </Typography>
                <Form.Item name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.TAGS}>
                  <CheckableTag
                    tags={selectedTags}
                    onClick={() => setIsAddedTagShow(!isAddedTagShow)}
                    showAddButton={!tags?.mytags}
                  />
                </Form.Item>

                {tags?.mytags && (
                  <div style={{ marginTop: 14 }}>
                    <Typography type="small" className={cn(s.label)}>
                      My tags
                    </Typography>
                    <Form.Item name="myTags">
                      <CheckableTag
                        tags={tags?.mytags || []}
                        onClick={() => setIsAddedTagShow(!isAddedTagShow)}
                        showAddButton={!!tags?.mytags}
                      />
                    </Form.Item>
                  </div>
                )}

                {isAddedTagShow && (
                  <div className={s.addTag}>
                    <Input
                      name="tags"
                      onChange={(e) => setNewTag(e.target.value)}
                      placeholder="Tag name"
                    />
                    <Button onClick={handleAddTag}>Save</Button>
                  </div>
                )}
              </div>
            )}
            <div>
              <Typography type="small" className={s.label}>
                Currency
              </Typography>
              <Form.Item name="currency">
                <Select
                  options={currencies?.map((item) => ({
                    label: `${item.symbol} ${item.name}`,
                    value: item.code,
                  }))}
                  placeholder="Choose currency"></Select>
              </Form.Item>
            </div>
            <EventCreateFormBasicInfoSeatingPlan form={form} />
          </div>
          <div className={s.info}>
            <div className={s.hintWrapper}>
              <div className={s.hint}>
                <div>
                  <Icon name="ideaColor" />
                </div>
                <Trans i18nKey="event_create_form_type_tip" components={{ b: <b /> }} />
              </div>
            </div>
            <EventCreateFormBasicInfoPromoter />
          </div>
        </div>
        <div>
          <Form.Item name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.LOCATION} hidden>
            <div></div>
          </Form.Item>
          <EventCreateFormBasicInfoLocation />
        </div>
        <div>
          <EventCreateFormBasicInfoDates
            setShowDates={setMultipleDates}
            multipleDates={multipleDates}
          />
        </div>
        <FormSubmit
          isEditing={isEditing}
          editingList={editingList}
          type="eventBasic"
          fetching={isLoading}
        />
      </Form>

      {!currentUser?.promoters[0]?.name && !isUserFetching && <WarningModal />}
    </createEventFormBasicInfoContext.Provider>
  );
};

export default CreateEventFormBasicInfo;
