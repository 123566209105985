import { FC, ReactElement, useEffect, useMemo } from 'react';

import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { USER_SETTING_ROUTE } from 'routes/user-management/list';
import { t } from 'tools/i18n';
import { Icon, Typography } from 'ui';

import { activePromoterSelector } from 'selectors/systemSlice.selector';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';
import { useAppSelector } from 'store/index';

import s from './BasicInfoPromoter.module.scss';

const EventCreateFormBasicInfoPromoter: FC = () => {
  const navigate = useNavigate();
  const activePromoter = useAppSelector(activePromoterSelector);
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();

  const renderUserLogo = useMemo((): ReactElement => {
    if ((activePromoter && activePromoter?.logo) || currentUser?.promoters[0]?.logo) {
      return (
        <img
          className={s.logo}
          src={activePromoter?.logo || currentUser?.promoters[0]?.logo}
          alt={activePromoter?.name}
        />
      );
    } else {
      return (
        <div className={s.logoTextWrapper}>
          <div className={s.logoText}>
            {activePromoter ? activePromoter?.name[0] : currentUser?.firstName[0]}
          </div>
        </div>
      );
    }
  }, [activePromoter, currentUser]);

  const handleEditPromoter = (e) => {
    e.stopPropagation();
    e.preventDefault();

    navigate(USER_SETTING_ROUTE);
  };

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  return (
    <div className={s.wrapper}>
      <div className={s.text}>
        <Icon name="warning" />
        <Trans i18nKey={t('event_create_form_promoter_hint')} components={{ b: <b /> }} />
      </div>
      {!!currentUser?.promoters?.length && (
        <div className={s.promoterContent}>
          <div className={s.popoverWrapper}>
            {renderUserLogo}

            <div>
              <Typography type="main" className={s.name}>
                {activePromoter?.name || currentUser?.promoters[0].name}
              </Typography>
              <Typography type="small" className={s.email}>
                {activePromoter?.email || currentUser?.promoters[0].email}
              </Typography>
            </div>

            <div className={s.actions}>
              <button className={s.action} onClick={handleEditPromoter} type="button">
                edit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventCreateFormBasicInfoPromoter;
