import { FC, useEffect } from 'react';

import { Button, Form, Input, Label, Modal, Typography, message } from 'ui';

import { useLazySendTicketToEmailQuery } from 'services/tickets/ticketsApiService';
import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';

import { EMAIL_PLACEHOLDER } from 'constants/core-constants';

import s from './SendTicketModal.module.scss';

interface SendTicketModalProps {
  open: boolean;
  handleClose: () => void;
  orderId: string;
}

const SendTicketModal: FC<SendTicketModalProps> = (props) => {
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const [sendTicketToEmail, { isFetching }] = useLazySendTicketToEmailQuery();
  const { open, handleClose, orderId } = props;
  const [form] = Form.useForm();
  const email = Form.useWatch('Email', form);
  const confirmEmail = Form.useWatch('confirmEmail', form);

  const handleSendTicket = (data) => {
    sendTicketToEmail({
      Email: data?.Email,
      OrderId: orderId,
    }).then((data) => {
      handleClose();
      if ('data' in data) {
        message.success('Tickets sent successfully!');
      }
    });
  };

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  const disableSendButton = email !== confirmEmail || isFetching;

  return (
    <Modal open={open} handleClose={handleClose} footer={false}>
      <Modal.Header>
        <Typography type="h4">Send Ticket</Typography>
      </Modal.Header>

      <Form className={s.form} form={form} onFinish={handleSendTicket}>
        <Modal.Content>
          <div className={s.item}>
            <Label>Recipient Email</Label>
            <Form.Item name="Email" initialValue={currentUser?.email}>
              <Input name="Email" />
            </Form.Item>
          </div>

          <div className={s.item}>
            <Label>Confirm Email</Label>
            <Form.Item name="confirmEmail">
              <Input name="confirmEmail" placeholder={EMAIL_PLACEHOLDER} />
            </Form.Item>
          </div>
        </Modal.Content>

        <Modal.Actions>
          <Button variant="outline" color="grey" onClick={handleClose} fullWidth>
            Cancel
          </Button>
          <Button htmlType="submit" fullWidth disabled={disableSendButton} loading={isFetching}>
            Send
          </Button>
        </Modal.Actions>
      </Form>
    </Modal>
  );
};

export default SendTicketModal;
