import dayjs from 'dayjs';

import { EventShowDateType, MultipleDatesType } from 'types/events/eventTypes';

export const validateFields = [
  ['showDates', 'startDate'],
  ['showDates', 'startTime'],
  ['showDates', 'endDate'],
  ['showDates', 'endTime'],
  ['showDates', 'doorsOpen'],
  ['showDates', 'duration', 'hours'],
  ['showDates', 'duration', 'minutes'],
];

export const prepareMultipleDates = (showDates: EventShowDateType): MultipleDatesType => {
  return {
    startDate: dayjs(showDates.startDate).format('YYYY-MM-DD'),
    endDate: showDates.endDate ? dayjs(showDates.endDate).format('YYYY-MM-DD') : '',
    startTime: dayjs(showDates.startTime).format('HH:mm'),
    endTime: showDates.endTime ? dayjs(showDates.endTime).format('HH:mm') : '',
    doorsOpen: showDates.doorsOpen ? dayjs(showDates.doorsOpen).format('HH:mm') : '',
    duration: Number(
      (showDates.duration && showDates.duration.hours * 60 + showDates.duration.minutes) || 0,
    ),
    ...(showDates.showId && { showId: showDates.showId }),
  };
};
