import React, { FC, useCallback, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useQuery } from 'tools/hooks';

// import { createEventFormActiveStepSelector } from 'selectors/eventsSlice.selector';
import { useLazyGetEventDetailQuery } from 'services/events/eventsApiService';
import { removeActiveEvent, setActiveEvent, setEditingEventId } from 'slices/eventsSlice';
import { useAppDispatch } from 'store';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { EventCreatedType } from 'types/events/eventTypes';

import CreateEventFormBasicInfo from './event-create-form-basic-info';
import EventCreateFormDetails from './event-create-form-details';
import EventCreateFormPermission from './event-create-form-permission';
import EventCreateFormPromotePage from './event-create-form-promote-page';
import EventCreateFormTaxes from './event-create-form-taxes';
import EventCreateFormTickets from './event-create-form-tickets';

const EventsCreateEventForm: FC = () => {
  // const activeStep = useAppSelector(createEventFormActiveStepSelector);
  const [getEventDetail] = useLazyGetEventDetailQuery();
  const { eventId } = useParams();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const step = query.get('step') as CREATE_EVENT_FORM_STEPS_ENUM;
  const handleGetEditingEvent = useCallback(
    async (eventId: string) => {
      const res = await getEventDetail(eventId);

      if ('data' in res) {
        dispatch(setActiveEvent(res.data as EventCreatedType));
      }
    },
    [dispatch, getEventDetail],
  );

  useEffect(() => {
    if (eventId) {
      dispatch(setEditingEventId(eventId));
      handleGetEditingEvent(eventId);
    }
  }, [dispatch, eventId, getEventDetail, handleGetEditingEvent]);

  useEffect(() => {
    return () => {
      dispatch(removeActiveEvent());
    };
  }, [dispatch]);

  const content = {
    [CREATE_EVENT_FORM_STEPS_ENUM.BASIC_INFO]: <CreateEventFormBasicInfo />,
    [CREATE_EVENT_FORM_STEPS_ENUM.DETAILS]: <EventCreateFormDetails />,
    [CREATE_EVENT_FORM_STEPS_ENUM.TICKETS]: <EventCreateFormTickets />,
    [CREATE_EVENT_FORM_STEPS_ENUM.TAXES]: <EventCreateFormTaxes />,
    [CREATE_EVENT_FORM_STEPS_ENUM.PERMISSIONS]: <EventCreateFormPermission />,
    [CREATE_EVENT_FORM_STEPS_ENUM.PROMOTE]: <EventCreateFormPromotePage />,
  };

  return <div>{content[step]}</div>;
};

export default EventsCreateEventForm;
