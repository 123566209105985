import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { getOrdersFullRoute } from 'routes/tikets/list';
import { Table, TableProps } from 'ui';

import { SORT_OPTIONS_ORDERS } from 'constants/sort';
import { OrdersListType, TicketType } from 'types/tickets/tickets.types';

interface OrdersListProps {
  ordersList?: OrdersListType['orders'];
  handleTableChange: TableProps['onChange'];
}

const OrdersList: FC<OrdersListProps> = (props) => {
  const { ordersList, handleTableChange } = props;
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Order Date',
      dataIndex: 'orderDate',
      key: 'orderDate',
      sorter: !!handleTableChange,
      sortDirections: SORT_OPTIONS_ORDERS,
      showSorterTooltip: false,
    },
    { title: 'Order Number', dataIndex: 'orderNo', key: 'orderNo' },
    {
      title: 'Buyer Name',
      dataIndex: 'user',
      key: 'buyerName',
      render: (user) => user.firstName,
    },
    {
      title: 'Buyer Surname',
      dataIndex: 'user',
      key: 'buyerSurname',
      render: (user) => user.lastName,
    },
    { title: 'Status', dataIndex: 'orderStatus', key: 'status' },
    { title: 'Tickets', dataIndex: 'numberOfPersons', key: 'tickets' },
    {
      title: 'Value',
      dataIndex: 'orderSubTotal',
      key: 'value',
      render: (value) => `€ ${value.toFixed(2)}`,
    },
  ];
  return (
    <div>
      <Table
        dataSource={ordersList}
        columns={columns}
        pagination={false}
        onChange={handleTableChange}
        rowKey="orderId"
        onRow={(record: TicketType) => {
          return {
            onClick: () => {
              navigate(getOrdersFullRoute(record.orderId));
            },
          };
        }}
        scroll={{ x: 768 }}
        bordered
      />
    </div>
  );
};

export default OrdersList;
