import { FC, ReactNode } from 'react';

import { Select as SelectAntd } from 'antd';
import type { DefaultOptionType, OptionProps as OptionPropsAntd } from 'antd/lib/select';
import cn from 'classnames';

import s from './Option.module.scss';

export type OptionProps = (DefaultOptionType | OptionPropsAntd) & { children?: ReactNode };

const Option: FC<OptionProps> = (props) => {
  const { children, className = '' } = props;

  return (
    <SelectAntd.Option className={cn(s.wrapper, { [className]: className })}>
      {children}
    </SelectAntd.Option>
  );
};

export default Option;
