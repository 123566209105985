import { Navigate } from 'react-router-dom';

import Calendar from 'pages/calendar';
import Dashboard from 'pages/dashboard';
import Events from 'pages/events';
import EventAttendeesList from 'pages/events/event-attendees-list';
import EventZreadingList from 'pages/events/event-z-reading-list';
import EventTaxReport from 'pages/events/events-tax-report';
import Orders from 'pages/orders';
import OrderFullInfo from 'pages/orders/order-full-info';
import Outlets from 'pages/outlets';
import Redirect from 'pages/redirect';
import ScanPage from 'pages/scan-page';
import Sellers from 'pages/sellers';
import ForgotPassword from 'pages/user-management/forgot-password';
import Login from 'pages/user-management/login';
import Register from 'pages/user-management/register';
import { BankDetails, OrganiserDetails } from 'pages/user-management/user-setting';

import { RouteType } from 'types/core/routeTypes';

import EventsCreateEventForm from '../pages/events/events-create-event-form';
import { CALENDAR_ROUTE, OUTLETS_ROUTE, SELLERS_ROUTE } from './core/list';
import { DASHBOARD_ROUTE } from './dashboard/list';
import {
  ATTENDEES_EVENT_ROUTE,
  CREATE_EVENT_FORM_ROUTE,
  EDIT_EVENT_FORM_ROUTE,
  EVENT_TAX_REPORT_ROUTE,
  MY_EVENTS_ROUTE,
  ZREADING_EVENT_ROUTE,
} from './events/list';
import { TICKETS_ORDERS_FULL, TICKETS_ORDERS_ROUTE, TICKETS_SCANS_ROUTE } from './tikets/list';
import {
  FORGOT_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  REDIRECT_ROUTE,
  REGISTER_ROUTE,
  USER_SETTING_BANK_DETAILS_ROUTE,
  USER_SETTING_ROUTE,
} from './user-management/list';

export const HOME_NON_AUTH_ROUTE = LOGIN_ROUTE;
export const UNKNOWN_ROUTE = '*';

export const AUTH_ROUTES: RouteType[] = [
  { path: DASHBOARD_ROUTE, element: <Dashboard /> },
  { path: MY_EVENTS_ROUTE, element: <Events /> },
  { path: TICKETS_ORDERS_ROUTE, element: <Orders /> },
  { path: CALENDAR_ROUTE, element: <Calendar /> },
  { path: TICKETS_SCANS_ROUTE, element: <ScanPage /> },
  { path: OUTLETS_ROUTE, element: <Outlets /> },
  { path: SELLERS_ROUTE, element: <Sellers /> },
  { path: USER_SETTING_ROUTE, element: <OrganiserDetails /> },
  { path: USER_SETTING_BANK_DETAILS_ROUTE, element: <BankDetails /> },
  { path: CREATE_EVENT_FORM_ROUTE, element: <EventsCreateEventForm /> },
  { path: EDIT_EVENT_FORM_ROUTE, element: <EventsCreateEventForm /> },
  { path: TICKETS_ORDERS_FULL, element: <OrderFullInfo /> },
  { path: ATTENDEES_EVENT_ROUTE, element: <EventAttendeesList /> },
  { path: ZREADING_EVENT_ROUTE, element: <EventZreadingList /> },
  { path: EVENT_TAX_REPORT_ROUTE, element: <EventTaxReport /> },
  { path: UNKNOWN_ROUTE, element: <Navigate to={DASHBOARD_ROUTE} /> },
];

export const NON_AUTH_ROUTES: RouteType[] = [
  { path: LOGIN_ROUTE, element: <Login /> },
  { path: REGISTER_ROUTE, element: <Register /> },
  { path: FORGOT_PASSWORD_ROUTE, element: <ForgotPassword /> },
  { path: REDIRECT_ROUTE, element: <Redirect /> },
  { path: UNKNOWN_ROUTE, element: <Navigate replace to={HOME_NON_AUTH_ROUTE} /> },
];
