export type EventFilterType = {
  PageNumber?: number;
  PageSize?: number;
  IncludeMetrics?: boolean;
  Search: string;
};

export const initialFilter: EventFilterType = {
  PageNumber: 1,
  PageSize: 10,
  IncludeMetrics: true,
  Search: '',
};
