import { UploadProps } from 'antd/lib/upload/interface';

import InternalUpload from './upload';
import UploadDragger from './upload-dragger';

export type CompoundedComponent = typeof InternalUpload & {
  Dragger: typeof UploadDragger;
};

const Upload = InternalUpload as CompoundedComponent;
Upload.Dragger = UploadDragger;

export type { UploadProps };
export default Upload;
