import { ReactElement, forwardRef } from 'react';

import cn from 'classnames';
import { NavLink as RouterNavLink } from 'react-router-dom';

import { MY_EVENTS_ROUTE } from '../../routes/events/list';
import Icon, { TIconsLiteral } from '../icon';
import Typography, { TypographyTypes } from '../typography';
import s from './NavLink.module.scss';

export interface NavLinkProps {
  label: string | ReactElement;
  to: string;
  icon?: TIconsLiteral;
  typographyType?: TypographyTypes;
  eventCounter?: number;
  typographyClassName?: string;
  className?: string;
}

const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  const {
    label,
    to = '',
    icon,
    className = '',
    typographyType = 'h4',
    typographyClassName = '',
    eventCounter = 0,
  } = props;

  return (
    <RouterNavLink
      ref={ref}
      className={({ isActive }) => cn(s.link, { [className]: className, [s.active]: isActive })}
      to={to}>
      <>
        {icon && <Icon name={icon} size={20} />}
        <Typography type={typographyType} className={typographyClassName}>
          {label}{' '}
          {to === MY_EVENTS_ROUTE && eventCounter > 0 && (
            <span className={s.counter}>{eventCounter}</span>
          )}
        </Typography>
      </>
    </RouterNavLink>
  );
});

export default NavLink;
