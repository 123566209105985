import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';

import { Typography } from 'ui';

import { EventTicketType } from 'types/events/eventTypes';

import s from './TicketForm.module.scss';

interface TicketFormProps {
  tickets?: EventTicketType[];
  setTicket: Dispatch<
    SetStateAction<{ name: string; price: number; amount: number; id: string }[]>
  >;
}

const TicketForm: FC<TicketFormProps> = ({ tickets, setTicket }) => {
  const [ticketsList, setTicketsList] = useState<
    { name: string; price: number; amount: number; id: string }[]
  >([]);

  const handleDecrement = useCallback(
    (id: string) => {
      const newTicketList = ticketsList?.map((ticket) => {
        if (ticket.id === id && ticket.amount > 0) {
          ticket.amount -= 1;
        }
        return ticket;
      });

      setTicketsList(newTicketList);
    },
    [ticketsList],
  );

  const handleIncrement = useCallback(
    (id: string) => {
      const newTicketList = ticketsList?.map((ticket) => {
        if (ticket.id === id) {
          ticket.amount += 1;
        }
        return ticket;
      });

      setTicketsList(newTicketList);
    },
    [ticketsList],
  );

  useEffect(() => {
    if (tickets?.length) {
      setTicketsList(
        tickets.map((ticket) => ({
          name: ticket.name,
          price: ticket.price,
          amount: 0,
          id: ticket?.id || '',
        })),
      );
    }
  }, [tickets]);

  useEffect(() => {
    if (ticketsList?.length) {
      setTicket(ticketsList);
    }
  }, [setTicket, ticketsList]);

  return (
    <div className={s.wrapper}>
      {ticketsList?.map((ticket) => (
        <div key={ticket.id} className={s.ticket}>
          <Typography type="h5">{ticket.name}</Typography>
          <div className={s.block}>
            <div className={s.price}>
              <Typography type="h5">{`€ ${ticket.price}`}</Typography>
              <Typography type="main">Face Value</Typography>
            </div>
            <div className={s.counter}>
              <button className={s.button} onClick={() => handleDecrement(ticket.id)}>
                -
              </button>
              <Typography type="main" weight={600}>
                {ticket.amount}
              </Typography>
              <button className={s.button} onClick={() => handleIncrement(ticket.id)}>
                +
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TicketForm;
