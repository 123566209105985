import { FC } from 'react';

import { Icon, Typography } from 'ui';

import s from './ComingSoon.module.scss';

const ComingSoon: FC = () => {
  return (
    <div className={s.wrapper}>
      <div>
        <Icon name="showsManager" width={500} height={150} />
      </div>
      <Typography type="h2">Functionality coming soon</Typography>
    </div>
  );
};

export default ComingSoon;
