import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { DASHBOARD_ROUTE } from 'routes/dashboard/list';
import { RedirectLoader } from 'ui';

import { isLoggedInSelector } from 'selectors/userSlice.selectors';
import { useAppSelector } from 'store';

const Redirect: FC = () => {
  const navigate = useNavigate();
  const isLogged = useAppSelector(isLoggedInSelector);

  if (isLogged) {
    navigate(DASHBOARD_ROUTE);
  }

  return <RedirectLoader />;
};

export default Redirect;
