import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';

import { Radio } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { Trans } from 'react-i18next';
import { t } from 'tools/i18n';
import { Checkbox, DatePicker, Form, Icon, Label, Typography } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import { useAppSelector } from 'store/index';

import {
  EVENT_CREATE_FORM_BASIC_INFO_ENUM,
  createEventFormBasicInfoContext,
} from 'pages/events/events-create-event-form/event-create-form-basic-info/basicInfo.utils';

import { MultipleDatesType } from 'types/events/eventTypes';

import s from './EventCreateFormBasicInfoDates.module.scss';
import { EVENT_CREATE_FORM_DATES_TYPES } from './eventCreateFormBasicInfoDates.utils';
import MultipleDates from './multiple-dates';
import NoDates from './no-dates';
import SingleDate from './single-date';

interface EventCreateFormBasicInfoDatesProps {
  setShowDates: Dispatch<SetStateAction<MultipleDatesType[]>>;
  multipleDates: MultipleDatesType[];
}

const EventCreateFormBasicInfoDates: FC<EventCreateFormBasicInfoDatesProps> = ({
  setShowDates,
  multipleDates,
}) => {
  const { form } = useContext(createEventFormBasicInfoContext);
  const [nededLaunchDate, setNededLaunchDate] = useState(false);
  const activeEditingEvent = useAppSelector(createEventFormActiveEventSelector);
  const dateType = Form.useWatch(EVENT_CREATE_FORM_BASIC_INFO_ENUM.DATE_TYPE, form);
  const scheduleType = activeEditingEvent?.basicInfo?.dateType === 'recurringEvent';

  const renderRadioButton = () => {
    return Object.keys(EVENT_CREATE_FORM_DATES_TYPES).map((key) => (
      <Radio.Button value={key} key={key}>
        {t(`date_type_${key.toLowerCase()}`)}
      </Radio.Button>
    ));
  };

  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
  };

  const renderDateContent = () => {
    switch (dateType) {
      case EVENT_CREATE_FORM_DATES_TYPES.SINGLE_DATE:
        return <SingleDate />;
      case EVENT_CREATE_FORM_DATES_TYPES.MULTIPLE_DATES:
        return <MultipleDates setShowsDates={setShowDates} multipleDates={multipleDates} />;
      case EVENT_CREATE_FORM_DATES_TYPES.NO_DATES:
        return <NoDates />;
    }
  };

  useEffect(() => {
    if (activeEditingEvent?.basicInfo?.showDates && !scheduleType) {
      const dates = activeEditingEvent?.basicInfo?.showDates;
      if (activeEditingEvent?.basicInfo?.dateType === 'noDates') {
        form?.setFieldValue(
          EVENT_CREATE_FORM_BASIC_INFO_ENUM.DATE_TYPE,
          EVENT_CREATE_FORM_DATES_TYPES.NO_DATES,
        );

        if (dates?.length === 1) {
          if (dates[0]?.doorsOpen) {
            form?.setFieldValue(
              EVENT_CREATE_FORM_BASIC_INFO_ENUM.LAST_DATE,
              dayjs(`${dates[0]?.endDate} ${dates[0]?.endTime}`),
            );
            form?.setFieldValue(
              EVENT_CREATE_FORM_BASIC_INFO_ENUM.LAST_TIME,
              dayjs(`${dates[0]?.endDate} ${dates[0]?.endTime}`),
            );
          }
        }
      } else {
        form?.setFieldValue(
          EVENT_CREATE_FORM_BASIC_INFO_ENUM.DATE_TYPE,
          dates?.length > 1
            ? EVENT_CREATE_FORM_DATES_TYPES.MULTIPLE_DATES
            : EVENT_CREATE_FORM_DATES_TYPES.SINGLE_DATE,
        );
      }

      if (dates?.length === 1) {
        if (dates[0]?.doorsOpen) {
          form?.setFieldValue(
            ['showDates', 'doorsOpen'],
            dayjs(`${dates[0]?.startDate} ${dates[0]?.doorsOpen}`),
          );
        }
      }
    }
  }, [activeEditingEvent, form, scheduleType]);

  return (
    <div className={s.wrapper}>
      <Typography type="h2" className={s.title}>
        {t('common_dates')}
      </Typography>

      {scheduleType && (
        <div className={s.glassWrapper}>
          <div className={s.glass}>
            <div className={s.glassContent}>
              <Icon name="logo" width={250} height={70} />
              <Typography type="h3" className={s.glassText}>
                Currently schedule settings have not yet been added to the ShowsManager, please use
                the dashboard in{' '}
                <a
                  className={s.link}
                  href="https://www.showshappening.com/myevents"
                  target="_blank">
                  ShowsHappening
                </a>
              </Typography>
            </div>
          </div>
        </div>
      )}
      <div className={cn(s.dateWrapper, { [s.blur]: scheduleType })}>
        <div className={s.content}>
          <div className={s.launchBlock}>
            <Checkbox value={nededLaunchDate} onChange={setNededLaunchDate}>
              <Typography type="main" weight={600}>
                Would you like to add a start date for the ticket launch?
              </Typography>
              <Typography type="main">
                By doing this you can publish the event now and share the link and then open ticket
                sales on the date and time that you set.
              </Typography>
            </Checkbox>

            {nededLaunchDate && (
              <div className={s.block}>
                <div>
                  <Label>Start Sales Date</Label>
                  <Form.Item name="salesDate">
                    <DatePicker
                      fullWidth
                      disabledDate={(current) => disabledDate(current)}
                      format="ddd DD MMMM YYYY"
                    />
                  </Form.Item>
                </div>
                <div>
                  <Label>Start Sales Time</Label>
                  <Form.Item name="salesTime">
                    <DatePicker.TimePicker fullWidth format="HH:mm" />
                  </Form.Item>
                </div>
              </div>
            )}
          </div>

          <div className={s.radioWrapper}>
            <Form.Item
              name={EVENT_CREATE_FORM_BASIC_INFO_ENUM.DATE_TYPE}
              initialValue={EVENT_CREATE_FORM_DATES_TYPES.SINGLE_DATE}>
              <Radio.Group buttonStyle="solid" className={s.tabs}>
                {renderRadioButton()}
              </Radio.Group>
            </Form.Item>
          </div>
          {renderDateContent()}
        </div>
        <div className={s.hintWrapper}>
          <div className={s.hint}>
            <div>
              <Icon name="ideaColor" />
            </div>
            <Trans i18nKey="event_create_form_launch_date_tip" components={{ b: <b /> }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCreateFormBasicInfoDates;
