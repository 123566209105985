import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';

import TicketPublicVisibilityCheckbox from 'component/ticket-public-visibility-checkbox';
import { t } from 'tools/i18n';
import { Button, Checkbox, Form, Input, Label, Modal, TextArea, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { EventTicketType } from 'types/events/eventTypes';

import { EVENT_CREATE_EVENT_TICKET_ENUM } from '../eventCreateFormTickets.utils';
import s from './CreateTicketModal.module.scss';

interface CreateTicketModalProps {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  setTickets: Dispatch<SetStateAction<EventTicketType[]>>;
  activeEditTicket?: EventTicketType;
  setActiveEditTicket: Dispatch<SetStateAction<EventTicketType | undefined>>;
  currency: string;
}
const CreateTicketModal: FC<CreateTicketModalProps> = (props) => {
  const { open, onClose, setTickets, currency, activeEditTicket, setActiveEditTicket } = props;
  const [hasNoMaxQuantity, setHasNoMaxQuantity] = useState<boolean>(false);
  const [additionalSetting, setAdditionalSetting] = useState<boolean>(false);
  const [form] = Form.useForm();

  const handleClose = useCallback(() => {
    onClose(false);
    form.resetFields();
    setHasNoMaxQuantity(false);

    if (activeEditTicket?.name) {
      setActiveEditTicket(undefined);
    }
  }, [activeEditTicket?.name, form, onClose, setActiveEditTicket]);

  const handleResetAdditionalSetting = useCallback(() => {
    form.resetFields([
      EVENT_CREATE_EVENT_TICKET_ENUM.MIN_QTY_PER_ORDER,
      EVENT_CREATE_EVENT_TICKET_ENUM.MAX_QTY_PER_ORDER,
      EVENT_CREATE_EVENT_TICKET_ENUM.SHOW_REMAINING_TICKETS,
      EVENT_CREATE_EVENT_TICKET_ENUM.HIDE_FROM_PUBLIC,
    ]);
  }, [form]);

  const handleCreateTicket = useCallback(
    (data) => {
      const result = {
        ...data,
        ...(hasNoMaxQuantity && {
          [EVENT_CREATE_EVENT_TICKET_ENUM.MAX_NUMBER_OF_TICKETS_TO_BE_SOLD]: 0,
        }),
      };

      if (activeEditTicket?.name) {
        setTickets((prev) => {
          const ticketsPrev = prev.map((ticket, i) => ({
            ...ticket,
            sortOrder: i,
          }));

          return [
            ...ticketsPrev.filter((ticket) => ticket.name !== activeEditTicket.name),
            { ...activeEditTicket, ...result },
          ];
        });
      }

      if (!activeEditTicket?.name) {
        setTickets((prev) => {
          if (prev.length) {
            const ticketsPrev = prev.map((ticket, i) => ({
              ...ticket,
              sortOrder: i,
            }));

            return [...ticketsPrev, { ...result, sortOrder: ticketsPrev.length }];
          } else {
            return [{ ...result, sortOrder: 0 }];
          }
        });
      }
      handleClose();
    },
    [activeEditTicket, handleClose, hasNoMaxQuantity, setTickets],
  );

  useEffect(
    () => handleResetAdditionalSetting(),
    [additionalSetting, handleResetAdditionalSetting],
  );

  useEffect(() => {
    if (activeEditTicket) {
      form.setFieldsValue(activeEditTicket);
    }
  }, [activeEditTicket, form]);

  return (
    <Modal handleClose={handleClose} open={open} footer={null}>
      <Modal.Header
        label={t(
          !activeEditTicket?.name
            ? 'event_create_form_create_ticket'
            : 'event_create_form_edit_ticket',
        )}
      />
      <Form form={form} onFinish={handleCreateTicket}>
        <Modal.Content className={s.content}>
          <div className={s.item}>
            <Label required>{t('event_create_form_ticket_name')}</Label>
            <Form.Item
              fullWidth
              name={EVENT_CREATE_EVENT_TICKET_ENUM.TICKET_NAME}
              rules={[formRules.required]}>
              <Input
                name={EVENT_CREATE_EVENT_TICKET_ENUM.TICKET_NAME}
                placeholder="Example: General Admission or VIP"
              />
            </Form.Item>
          </div>
          <div className={s.row}>
            <div className={s.item}>
              <Label required>{t('event_create_max_ticket_capacity')}</Label>
              <Form.Item
                name={EVENT_CREATE_EVENT_TICKET_ENUM.MAX_NUMBER_OF_TICKETS_TO_BE_SOLD}
                fullWidth
                rules={!hasNoMaxQuantity ? [formRules.required] : []}>
                <Input.Number
                  placeholder="0"
                  disabled={hasNoMaxQuantity}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </div>
            <div>
              <Checkbox value={hasNoMaxQuantity} onChange={setHasNoMaxQuantity}>
                <Typography type="main">Ticket type has no max quantity.</Typography>
              </Checkbox>
            </div>
          </div>
          <div className={s.price}>
            <Label required>{t('common_price')}</Label>
            <Form.Item
              fullWidth
              name={EVENT_CREATE_EVENT_TICKET_ENUM.TICKET_PRICE}
              rules={[formRules.required]}>
              <Input.Number currencyMask={currency} style={{ width: '100%' }} placeholder="0.00" />
            </Form.Item>
          </div>
          <div className={s.item}>
            <Label>{t('common_ticket_description')}</Label>
            <Form.Item name={EVENT_CREATE_EVENT_TICKET_ENUM.TICKET_DESCRIPTION}>
              <TextArea
                name={EVENT_CREATE_EVENT_TICKET_ENUM.TICKET_DESCRIPTION}
                maxLength={100}
                placeholder="Tell your attendees more about this ticket type."
              />
            </Form.Item>
          </div>
          {additionalSetting && (
            <div className={s.additionalContent}>
              <div className={s.item}>
                <Typography type="main" weight={600}>
                  {t('common_ticket_per_order')}
                </Typography>
                <div className={s.quantityContent}>
                  <div>
                    <Label>{t('common_minimum_quantity')}</Label>
                    <Form.Item name={EVENT_CREATE_EVENT_TICKET_ENUM.MIN_QTY_PER_ORDER} fullWidth>
                      <Input.Number placeholder="0" style={{ width: '100%' }} />
                    </Form.Item>
                  </div>
                  <div>
                    <Label>{t('common_maximum_quantity')}</Label>
                    <Form.Item name={EVENT_CREATE_EVENT_TICKET_ENUM.MAX_QTY_PER_ORDER} fullWidth>
                      <Input.Number placeholder="0" style={{ width: '100%' }} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div>
                <Typography type="main" weight={600}>
                  {t('event_create_display_tickets_remaining')}
                </Typography>
                <Form.Item
                  name={EVENT_CREATE_EVENT_TICKET_ENUM.SHOW_REMAINING_TICKETS}
                  className={s.content}>
                  <Checkbox>
                    <Typography type="main">{t('event_create_tickets_remaining_text')}</Typography>
                  </Checkbox>
                </Form.Item>
              </div>
              <div>
                <Label>{t('common_visibility')}</Label>
                <Form.Item
                  name={EVENT_CREATE_EVENT_TICKET_ENUM.HIDE_FROM_PUBLIC}
                  initialValue={false}>
                  <TicketPublicVisibilityCheckbox />
                </Form.Item>
              </div>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <button
            className={s.additional}
            onClick={() => setAdditionalSetting(!additionalSetting)}
            type="button">
            {t('common_additional_setting')}
          </button>
          <div className={s.actions}>
            <Button variant="outline" color="grey" onClick={handleClose}>
              Cancel
            </Button>
            <Button htmlType="submit">
              {activeEditTicket?.name ? 'Update Ticket' : 'Add Ticket'}
            </Button>
          </div>
        </Modal.Actions>
      </Form>
    </Modal>
  );
};

export default CreateTicketModal;
