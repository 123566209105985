import Content from './contetnt';
import Header from './header';
import ParentLayout, { LayoutProps } from './layout';
import Sider from './sider';

type LayoutParentType = typeof ParentLayout;

interface LayoutType extends LayoutParentType {
  Header: typeof Header;
  Content: typeof Content;
  Sider: typeof Sider;
}

const Layout = ParentLayout as LayoutType;

Layout.Header = Header;
Layout.Content = Content;
Layout.Sider = Sider;

export type { LayoutProps };
export default Layout;
