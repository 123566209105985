import React, { FC } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { Icon } from 'ui';

import s from './PhoneMockup.module.scss';
import Empty from './empty.png';
import Phone from './phone.png';

interface PhoneMockProps {
  fileType?: 'image' | 'video';
  fileUrl?: string;
  artistTag: { artistName: string; artistTag: string; artistSite: string }[];
  sponsorTag: { sponsorName: string; sponsorTag: string; sponsorSite: string }[];
}

const PhoneMockup: FC<PhoneMockProps> = ({ fileType, fileUrl, sponsorTag, artistTag }) => {
  // Write your component code here

  return (
    <div className={s.phoneWrapper}>
      <img src={Phone} alt="phone" className={s.phone} />
      <div className={s.back}>
        <div className={s.header}>
          <div>{dayjs().format('HH:mm')}</div>
          <div className={s.icons}>
            <Icon name="wifi" size={20} />
            <Icon name="charge" size={20} />
          </div>
        </div>
        <div className={s.user}>
          <div className={s.timeLine}>
            <div className={s.line}></div>
          </div>
          <div className={s.info}>
            <div className={s.nameBlock}>
              <div className={s.logo}>
                <Icon name="smallLogo" size={18} />
              </div>
              <div className={s.name}>Showshappening</div>
            </div>
            <div className={s.blockIcons}>
              <Icon name="dots" size={20} color="white" />
              <Icon name="close" size={20} color="white" />
            </div>
          </div>
        </div>
        {!fileUrl && (
          <div>
            <img src={Empty} alt="" className={s.body} />
          </div>
        )}
        {fileType === 'image' && (
          <div>
            <img src={fileUrl} alt="" className={classNames(s.body, s.body_opacity)} />
          </div>
        )}
        {fileType === 'video' && (
          <div>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              src={fileUrl}
              controls={false}
              className={classNames(s.body, s.body_opacity)}
              autoPlay={true}
              playsInline={false}
            />
          </div>
        )}
        {artistTag?.map((tag, i) => (
          <div key={i} className={classNames(s.tag, s[`tag_${i + 1}`])}>
            <span>{tag.artistName}</span>
            <span>{tag.artistTag}</span>
            <span>{tag.artistSite}</span>
          </div>
        ))}
        {sponsorTag?.map((tag, i) => (
          <div key={i} className={classNames(s.tag, s[`tag_sponsor_${i + 1}`])}>
            <span>{tag.sponsorName}</span>
            <span>{tag.sponsorTag}</span>
            <span>{tag.sponsorSite}</span>
          </div>
        ))}
        <div className={s.sendBlock}>
          <div className={s.send}>Send message</div>
          <Icon name="send" color="#727678" />
        </div>
        <div className={s.footer}></div>
      </div>
    </div>
  );
};

export default PhoneMockup;
