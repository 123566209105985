import { FC, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { MY_EVENTS_ROUTE } from 'routes/events/list';
import { t } from 'tools/i18n';
import { Icon, Typography } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import { useAppSelector } from 'store';

import s from './AppNavigationEventInfo.module.scss';

const AppNavigationEventInfo: FC = () => {
  const navigate = useNavigate();
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);
  const basicEventInfo = activeEvent?.basicInfo;
  const handleBack = useCallback(() => {
    navigate(MY_EVENTS_ROUTE);
  }, [navigate]);

  return (
    <div className={s.wrapper}>
      <button className={s.back} onClick={handleBack}>
        <Icon name="arrowRight" size={26} />
      </button>
      <div className={s.eventInfo}>
        <div className={s.flyerWrapper}>
          {activeEvent?.media?.flyer ? (
            <img src={activeEvent?.media?.flyer} alt="eventFlyer" className={s.flyer} />
          ) : (
            <Icon name="littleLogo" />
          )}
        </div>
        <div className={s.hideMobile}>
          <Typography type="main" weight={700}>
            {basicEventInfo ? basicEventInfo.eventName : t('common_your_event')}
          </Typography>
          <Typography className={s.text}>{t('common_fill_in_details')}</Typography>
        </div>
      </div>
    </div>
  );
};

export default AppNavigationEventInfo;
