import { FC } from 'react';

import { Typography } from 'ui';

import s from 'containers/publish-event-modal/PublishEventModal.module.scss';

const PublishEventModalContent: FC = () => {
  return (
    <div className={s.terms}>
      <div className={s.termsItem} id="service_1">
        <div>
          <Typography type="main" weight={600}>
            Service 1
          </Typography>
        </div>
        SHOWSHAPPENING hereby grants you a non-exclusive, non-transferable, non-sublicensable right
        to access and use the Services solely for the purposes of creating an event page with
        respect to, and promoting, managing, tracking, and collecting Event Registration Fees for,
        an event that you have registered on the Site, in each case (i) in compliance with this TOS,
        and (ii) to the extent permitted under all applicable laws and regulations (foreign and
        domestic). Notwithstanding the foregoing, you shall not, and shall not permit anyone else
        to, directly or indirectly: (i) modify, reproduce or otherwise create derivatives of any
        part of the Services or Site Content (as defined below); (ii) reverse engineer, disassemble,
        decompile or otherwise attempt to discover the source code or structure, sequence and
        organization of all or any part of the Services (except that this restriction shall not
        apply to the limited extent restrictions on reverse engineering are prohibited by applicable
        local law); (iii) rent, lease, resell, distribute or use the Services for timesharing,
        service bureau, or commercial purposes (except for the limited commercial purpose of
        collecting Event Registration Fees through the Site as an Organizer in accordance with this
        TOS); (iv) remove or alter any proprietary notices or labels on or in the Services or Site
        Content; or (v) engage in any activity that interferes with or disrupts the Services.
      </div>
      <div className={s.termsItem} id="service_2">
        <div>
          <Typography type="main" weight={600}>
            Service 2
          </Typography>
        </div>
        <div>
          SHOWSHAPPENING hereby grants you a non-exclusive, non-transferable, non-sublicensable
          right to access and use the Services solely for the purposes of creating an event page
          with respect to, and promoting, managing, tracking, and collecting Event Registration Fees
          for, an event that you have registered on the Site, in each case (i) in compliance with
          this TOS, and (ii) to the extent permitted under all applicable laws and regulations
          (foreign and domestic). Notwithstanding the foregoing, you shall not, and shall not permit
          anyone else to, directly or indirectly: (i) modify, reproduce or otherwise create
          derivatives of any part of the Services or Site Content (as defined below); (ii) reverse
          engineer, disassemble, decompile or otherwise attempt to discover the source code or
          structure, sequence and organization of all or any part of the Services (except that this
          restriction shall not apply to the limited extent restrictions on reverse engineering are
          prohibited by applicable local law); (iii) rent, lease, resell, distribute or use the
          Services for timesharing, service bureau, or commercial purposes (except for the limited
          commercial purpose of collecting Event Registration Fees through the Site as an Organizer
          in accordance with this TOS); (iv) remove or alter any proprietary notices or labels on or
          in the Services or Site Content; or (v) engage in any activity that interferes with or
          disrupts the Services.
        </div>
      </div>
      <div className={s.termsItem} id="service_3">
        <div>
          <Typography type="main" weight={600}>
            Service 3
          </Typography>
        </div>
        <div>
          SHOWSHAPPENING hereby grants you a non-exclusive, non-transferable, non-sublicensable
          right to access and use the Services solely for the purposes of creating an event page
          with respect to, and promoting, managing, tracking, and collecting Event Registration Fees
          for, an event that you have registered on the Site, in each case (i) in compliance with
          this TOS, and (ii) to the extent permitted under all applicable laws and regulations
          (foreign and domestic). Notwithstanding the foregoing, you shall not, and shall not permit
          anyone else to, directly or indirectly: (i) modify, reproduce or otherwise create
          derivatives of any part of the Services or Site Content (as defined below); (ii) reverse
          engineer, disassemble, decompile or otherwise attempt to discover the source code or
          structure, sequence and organization of all or any part of the Services (except that this
          restriction shall not apply to the limited extent restrictions on reverse engineering are
          prohibited by applicable local law); (iii) rent, lease, resell, distribute or use the
          Services for timesharing, service bureau, or commercial purposes (except for the limited
          commercial purpose of collecting Event Registration Fees through the Site as an Organizer
          in accordance with this TOS); (iv) remove or alter any proprietary notices or labels on or
          in the Services or Site Content; or (v) engage in any activity that interferes with or
          disrupts the Services.
        </div>
      </div>
      <div className={s.termsItem} id="service_4">
        <div>
          <Typography type="main" weight={600}>
            Service 4
          </Typography>
        </div>
        <div>
          SHOWSHAPPENING hereby grants you a non-exclusive, non-transferable, non-sublicensable
          right to access and use the Services solely for the purposes of creating an event page
          with respect to, and promoting, managing, tracking, and collecting Event Registration Fees
          for, an event that you have registered on the Site, in each case (i) in compliance with
          this TOS, and (ii) to the extent permitted under all applicable laws and regulations
          (foreign and domestic). Notwithstanding the foregoing, you shall not, and shall not permit
          anyone else to, directly or indirectly: (i) modify, reproduce or otherwise create
          derivatives of any part of the Services or Site Content (as defined below); (ii) reverse
          engineer, disassemble, decompile or otherwise attempt to discover the source code or
          structure, sequence and organization of all or any part of the Services (except that this
          restriction shall not apply to the limited extent restrictions on reverse engineering are
          prohibited by applicable local law); (iii) rent, lease, resell, distribute or use the
          Services for timesharing, service bureau, or commercial purposes (except for the limited
          commercial purpose of collecting Event Registration Fees through the Site as an Organizer
          in accordance with this TOS); (iv) remove or alter any proprietary notices or labels on or
          in the Services or Site Content; or (v) engage in any activity that interferes with or
          disrupts the Services.
        </div>
      </div>
      <div className={s.termsItem} id="service_5">
        <div>
          <Typography type="main" weight={600}>
            Service 5
          </Typography>
        </div>
        <div>
          SHOWSHAPPENING hereby grants you a non-exclusive, non-transferable, non-sublicensable
          right to access and use the Services solely for the purposes of creating an event page
          with respect to, and promoting, managing, tracking, and collecting Event Registration Fees
          for, an event that you have registered on the Site, in each case (i) in compliance with
          this TOS, and (ii) to the extent permitted under all applicable laws and regulations
          (foreign and domestic). Notwithstanding the foregoing, you shall not, and shall not permit
          anyone else to, directly or indirectly: (i) modify, reproduce or otherwise create
          derivatives of any part of the Services or Site Content (as defined below); (ii) reverse
          engineer, disassemble, decompile or otherwise attempt to discover the source code or
          structure, sequence and organization of all or any part of the Services (except that this
          restriction shall not apply to the limited extent restrictions on reverse engineering are
          prohibited by applicable local law); (iii) rent, lease, resell, distribute or use the
          Services for timesharing, service bureau, or commercial purposes (except for the limited
          commercial purpose of collecting Event Registration Fees through the Site as an Organizer
          in accordance with this TOS); (iv) remove or alter any proprietary notices or labels on or
          in the Services or Site Content; or (v) engage in any activity that interferes with or
          disrupts the Services.
        </div>
      </div>
      <div className={s.termsItem} id="service_6">
        <div>
          <Typography type="main" weight={600}>
            Service 6
          </Typography>
        </div>
        <div>
          SHOWSHAPPENING hereby grants you a non-exclusive, non-transferable, non-sublicensable
          right to access and use the Services solely for the purposes of creating an event page
          with respect to, and promoting, managing, tracking, and collecting Event Registration Fees
          for, an event that you have registered on the Site, in each case (i) in compliance with
          this TOS, and (ii) to the extent permitted under all applicable laws and regulations
          (foreign and domestic). Notwithstanding the foregoing, you shall not, and shall not permit
          anyone else to, directly or indirectly: (i) modify, reproduce or otherwise create
          derivatives of any part of the Services or Site Content (as defined below); (ii) reverse
          engineer, disassemble, decompile or otherwise attempt to discover the source code or
          structure, sequence and organization of all or any part of the Services (except that this
          restriction shall not apply to the limited extent restrictions on reverse engineering are
          prohibited by applicable local law); (iii) rent, lease, resell, distribute or use the
          Services for timesharing, service bureau, or commercial purposes (except for the limited
          commercial purpose of collecting Event Registration Fees through the Site as an Organizer
          in accordance with this TOS); (iv) remove or alter any proprietary notices or labels on or
          in the Services or Site Content; or (v) engage in any activity that interferes with or
          disrupts the Services.
        </div>
      </div>
      <div className={s.termsItem} id="service_7">
        <div>
          <Typography type="main" weight={600}>
            Service 7
          </Typography>
        </div>
        <div>
          SHOWSHAPPENING hereby grants you a non-exclusive, non-transferable, non-sublicensable
          right to access and use the Services solely for the purposes of creating an event page
          with respect to, and promoting, managing, tracking, and collecting Event Registration Fees
          for, an event that you have registered on the Site, in each case (i) in compliance with
          this TOS, and (ii) to the extent permitted under all applicable laws and regulations
          (foreign and domestic). Notwithstanding the foregoing, you shall not, and shall not permit
          anyone else to, directly or indirectly: (i) modify, reproduce or otherwise create
          derivatives of any part of the Services or Site Content (as defined below); (ii) reverse
          engineer, disassemble, decompile or otherwise attempt to discover the source code or
          structure, sequence and organization of all or any part of the Services (except that this
          restriction shall not apply to the limited extent restrictions on reverse engineering are
          prohibited by applicable local law); (iii) rent, lease, resell, distribute or use the
          Services for timesharing, service bureau, or commercial purposes (except for the limited
          commercial purpose of collecting Event Registration Fees through the Site as an Organizer
          in accordance with this TOS); (iv) remove or alter any proprietary notices or labels on or
          in the Services or Site Content; or (v) engage in any activity that interferes with or
          disrupts the Services.
        </div>
      </div>
      <div className={s.termsItem} id="service_8">
        <div>
          <Typography type="main" weight={600}>
            Service 8
          </Typography>
        </div>
        <div>
          SHOWSHAPPENING hereby grants you a non-exclusive, non-transferable, non-sublicensable
          right to access and use the Services solely for the purposes of creating an event page
          with respect to, and promoting, managing, tracking, and collecting Event Registration Fees
          for, an event that you have registered on the Site, in each case (i) in compliance with
          this TOS, and (ii) to the extent permitted under all applicable laws and regulations
          (foreign and domestic). Notwithstanding the foregoing, you shall not, and shall not permit
          anyone else to, directly or indirectly: (i) modify, reproduce or otherwise create
          derivatives of any part of the Services or Site Content (as defined below); (ii) reverse
          engineer, disassemble, decompile or otherwise attempt to discover the source code or
          structure, sequence and organization of all or any part of the Services (except that this
          restriction shall not apply to the limited extent restrictions on reverse engineering are
          prohibited by applicable local law); (iii) rent, lease, resell, distribute or use the
          Services for timesharing, service bureau, or commercial purposes (except for the limited
          commercial purpose of collecting Event Registration Fees through the Site as an Organizer
          in accordance with this TOS); (iv) remove or alter any proprietary notices or labels on or
          in the Services or Site Content; or (v) engage in any activity that interferes with or
          disrupts the Services.
        </div>
      </div>
      <div className={s.termsItem} id="service_9">
        <div>
          <Typography type="main" weight={600}>
            Service 9
          </Typography>
        </div>
        <div>
          SHOWSHAPPENING hereby grants you a non-exclusive, non-transferable, non-sublicensable
          right to access and use the Services solely for the purposes of creating an event page
          with respect to, and promoting, managing, tracking, and collecting Event Registration Fees
          for, an event that you have registered on the Site, in each case (i) in compliance with
          this TOS, and (ii) to the extent permitted under all applicable laws and regulations
          (foreign and domestic). Notwithstanding the foregoing, you shall not, and shall not permit
          anyone else to, directly or indirectly: (i) modify, reproduce or otherwise create
          derivatives of any part of the Services or Site Content (as defined below); (ii) reverse
          engineer, disassemble, decompile or otherwise attempt to discover the source code or
          structure, sequence and organization of all or any part of the Services (except that this
          restriction shall not apply to the limited extent restrictions on reverse engineering are
          prohibited by applicable local law); (iii) rent, lease, resell, distribute or use the
          Services for timesharing, service bureau, or commercial purposes (except for the limited
          commercial purpose of collecting Event Registration Fees through the Site as an Organizer
          in accordance with this TOS); (iv) remove or alter any proprietary notices or labels on or
          in the Services or Site Content; or (v) engage in any activity that interferes with or
          disrupts the Services.
        </div>
      </div>
      <div className={s.termsItem} id="service_10">
        <div>
          <Typography type="main" weight={600}>
            Service 10
          </Typography>
        </div>
        <div>
          SHOWSHAPPENING hereby grants you a non-exclusive, non-transferable, non-sublicensable
          right to access and use the Services solely for the purposes of creating an event page
          with respect to, and promoting, managing, tracking, and collecting Event Registration Fees
          for, an event that you have registered on the Site, in each case (i) in compliance with
          this TOS, and (ii) to the extent permitted under all applicable laws and regulations
          (foreign and domestic). Notwithstanding the foregoing, you shall not, and shall not permit
          anyone else to, directly or indirectly: (i) modify, reproduce or otherwise create
          derivatives of any part of the Services or Site Content (as defined below); (ii) reverse
          engineer, disassemble, decompile or otherwise attempt to discover the source code or
          structure, sequence and organization of all or any part of the Services (except that this
          restriction shall not apply to the limited extent restrictions on reverse engineering are
          prohibited by applicable local law); (iii) rent, lease, resell, distribute or use the
          Services for timesharing, service bureau, or commercial purposes (except for the limited
          commercial purpose of collecting Event Registration Fees through the Site as an Organizer
          in accordance with this TOS); (iv) remove or alter any proprietary notices or labels on or
          in the Services or Site Content; or (v) engage in any activity that interferes with or
          disrupts the Services.
        </div>
      </div>
      <div className={s.termsItem} id="service_11">
        <div>
          <Typography type="main" weight={600}>
            Service 11
          </Typography>
        </div>
        <div>
          SHOWSHAPPENING hereby grants you a non-exclusive, non-transferable, non-sublicensable
          right to access and use the Services solely for the purposes of creating an event page
          with respect to, and promoting, managing, tracking, and collecting Event Registration Fees
          for, an event that you have registered on the Site, in each case (i) in compliance with
          this TOS, and (ii) to the extent permitted under all applicable laws and regulations
          (foreign and domestic). Notwithstanding the foregoing, you shall not, and shall not permit
          anyone else to, directly or indirectly: (i) modify, reproduce or otherwise create
          derivatives of any part of the Services or Site Content (as defined below); (ii) reverse
          engineer, disassemble, decompile or otherwise attempt to discover the source code or
          structure, sequence and organization of all or any part of the Services (except that this
          restriction shall not apply to the limited extent restrictions on reverse engineering are
          prohibited by applicable local law); (iii) rent, lease, resell, distribute or use the
          Services for timesharing, service bureau, or commercial purposes (except for the limited
          commercial purpose of collecting Event Registration Fees through the Site as an Organizer
          in accordance with this TOS); (iv) remove or alter any proprietary notices or labels on or
          in the Services or Site Content; or (v) engage in any activity that interferes with or
          disrupts the Services.
        </div>
      </div>
      <div className={s.termsItem} id="service_12">
        <div>
          <Typography type="main" weight={600}>
            Service 12
          </Typography>
        </div>
        <div>
          SHOWSHAPPENING hereby grants you a non-exclusive, non-transferable, non-sublicensable
          right to access and use the Services solely for the purposes of creating an event page
          with respect to, and promoting, managing, tracking, and collecting Event Registration Fees
          for, an event that you have registered on the Site, in each case (i) in compliance with
          this TOS, and (ii) to the extent permitted under all applicable laws and regulations
          (foreign and domestic). Notwithstanding the foregoing, you shall not, and shall not permit
          anyone else to, directly or indirectly: (i) modify, reproduce or otherwise create
          derivatives of any part of the Services or Site Content (as defined below); (ii) reverse
          engineer, disassemble, decompile or otherwise attempt to discover the source code or
          structure, sequence and organization of all or any part of the Services (except that this
          restriction shall not apply to the limited extent restrictions on reverse engineering are
          prohibited by applicable local law); (iii) rent, lease, resell, distribute or use the
          Services for timesharing, service bureau, or commercial purposes (except for the limited
          commercial purpose of collecting Event Registration Fees through the Site as an Organizer
          in accordance with this TOS); (iv) remove or alter any proprietary notices or labels on or
          in the Services or Site Content; or (v) engage in any activity that interferes with or
          disrupts the Services.
        </div>
      </div>
      <div className={s.termsItem} id="service_13">
        <div>
          <Typography type="main" weight={600}>
            Service 13
          </Typography>
        </div>
        <div>
          SHOWSHAPPENING hereby grants you a non-exclusive, non-transferable, non-sublicensable
          right to access and use the Services solely for the purposes of creating an event page
          with respect to, and promoting, managing, tracking, and collecting Event Registration Fees
          for, an event that you have registered on the Site, in each case (i) in compliance with
          this TOS, and (ii) to the extent permitted under all applicable laws and regulations
          (foreign and domestic). Notwithstanding the foregoing, you shall not, and shall not permit
          anyone else to, directly or indirectly: (i) modify, reproduce or otherwise create
          derivatives of any part of the Services or Site Content (as defined below); (ii) reverse
          engineer, disassemble, decompile or otherwise attempt to discover the source code or
          structure, sequence and organization of all or any part of the Services (except that this
          restriction shall not apply to the limited extent restrictions on reverse engineering are
          prohibited by applicable local law); (iii) rent, lease, resell, distribute or use the
          Services for timesharing, service bureau, or commercial purposes (except for the limited
          commercial purpose of collecting Event Registration Fees through the Site as an Organizer
          in accordance with this TOS); (iv) remove or alter any proprietary notices or labels on or
          in the Services or Site Content; or (v) engage in any activity that interferes with or
          disrupts the Services.
        </div>
      </div>
      <div className={s.termsItem} id="service_14">
        <div>
          <Typography type="main" weight={600}>
            Service 14
          </Typography>
        </div>
        <div>
          SHOWSHAPPENING hereby grants you a non-exclusive, non-transferable, non-sublicensable
          right to access and use the Services solely for the purposes of creating an event page
          with respect to, and promoting, managing, tracking, and collecting Event Registration Fees
          for, an event that you have registered on the Site, in each case (i) in compliance with
          this TOS, and (ii) to the extent permitted under all applicable laws and regulations
          (foreign and domestic). Notwithstanding the foregoing, you shall not, and shall not permit
          anyone else to, directly or indirectly: (i) modify, reproduce or otherwise create
          derivatives of any part of the Services or Site Content (as defined below); (ii) reverse
          engineer, disassemble, decompile or otherwise attempt to discover the source code or
          structure, sequence and organization of all or any part of the Services (except that this
          restriction shall not apply to the limited extent restrictions on reverse engineering are
          prohibited by applicable local law); (iii) rent, lease, resell, distribute or use the
          Services for timesharing, service bureau, or commercial purposes (except for the limited
          commercial purpose of collecting Event Registration Fees through the Site as an Organizer
          in accordance with this TOS); (iv) remove or alter any proprietary notices or labels on or
          in the Services or Site Content; or (v) engage in any activity that interferes with or
          disrupts the Services.
        </div>
      </div>
      <div className={s.termsItem} id="service_15">
        <div>
          <Typography type="main" weight={600}>
            Service 15
          </Typography>
        </div>
        <div>
          SHOWSHAPPENING hereby grants you a non-exclusive, non-transferable, non-sublicensable
          right to access and use the Services solely for the purposes of creating an event page
          with respect to, and promoting, managing, tracking, and collecting Event Registration Fees
          for, an event that you have registered on the Site, in each case (i) in compliance with
          this TOS, and (ii) to the extent permitted under all applicable laws and regulations
          (foreign and domestic). Notwithstanding the foregoing, you shall not, and shall not permit
          anyone else to, directly or indirectly: (i) modify, reproduce or otherwise create
          derivatives of any part of the Services or Site Content (as defined below); (ii) reverse
          engineer, disassemble, decompile or otherwise attempt to discover the source code or
          structure, sequence and organization of all or any part of the Services (except that this
          restriction shall not apply to the limited extent restrictions on reverse engineering are
          prohibited by applicable local law); (iii) rent, lease, resell, distribute or use the
          Services for timesharing, service bureau, or commercial purposes (except for the limited
          commercial purpose of collecting Event Registration Fees through the Site as an Organizer
          in accordance with this TOS); (iv) remove or alter any proprietary notices or labels on or
          in the Services or Site Content; or (v) engage in any activity that interferes with or
          disrupts the Services.
        </div>
      </div>
      <div className={s.termsItem} id="service_16">
        <div>
          <Typography type="main" weight={600}>
            Service 16
          </Typography>
        </div>
        <div>
          SHOWSHAPPENING hereby grants you a non-exclusive, non-transferable, non-sublicensable
          right to access and use the Services solely for the purposes of creating an event page
          with respect to, and promoting, managing, tracking, and collecting Event Registration Fees
          for, an event that you have registered on the Site, in each case (i) in compliance with
          this TOS, and (ii) to the extent permitted under all applicable laws and regulations
          (foreign and domestic). Notwithstanding the foregoing, you shall not, and shall not permit
          anyone else to, directly or indirectly: (i) modify, reproduce or otherwise create
          derivatives of any part of the Services or Site Content (as defined below); (ii) reverse
          engineer, disassemble, decompile or otherwise attempt to discover the source code or
          structure, sequence and organization of all or any part of the Services (except that this
          restriction shall not apply to the limited extent restrictions on reverse engineering are
          prohibited by applicable local law); (iii) rent, lease, resell, distribute or use the
          Services for timesharing, service bureau, or commercial purposes (except for the limited
          commercial purpose of collecting Event Registration Fees through the Site as an Organizer
          in accordance with this TOS); (iv) remove or alter any proprietary notices or labels on or
          in the Services or Site Content; or (v) engage in any activity that interferes with or
          disrupts the Services.
        </div>
      </div>
    </div>
  );
};

export default PublishEventModalContent;
