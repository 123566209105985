import { SERVICES_TAGS } from 'constants/servicesTags';
import initialApiService from 'services/initialApiService';

import { EventBasicInfoType, EventCreatedType, EventType } from 'types/events/eventTypes';

import {
  AcceptTermsAndConditionsPropsType,
  EventAttendeesResponseType,
  EventCategoriesWithTag,
  EventCreateFormDetailsPropsType,
  EventCreateFormTaxesPropsType,
  EventFlyerPropsType,
  EventMetricsResponseType,
  EventPermissionResponseType,
  EventPromoDetailsPropsType,
  EventResponseType,
  EventTaxReportPropsType,
  EventTaxReportResponseType,
  EventTicketMetricsResponseType,
  EventTicketsPropsType,
  OrdersListPropsType,
  OrdersListResponseType,
  SeatingPlansResponseType,
  ZReadingResponseType,
} from './evetns.api.type';

const eventsApiService = initialApiService.injectEndpoints({
  endpoints: (build) => ({
    getCategories: build.query<EventType[], void>({
      query: () => ({
        url: 'events/categories',
        method: 'GET',
      }),
    }),
    getTags: build.query<
      EventCategoriesWithTag,
      { term: string; records: number; categoryIds?: string }
    >({
      query: (params) => ({
        url: 'events/tags',
        method: 'GET',
        params,
      }),
      providesTags: ['GET_CATEGORIES'],
    }),
    createEventBaseInfo: build.mutation<EventCreatedType, EventBasicInfoType>({
      query: (data) => ({
        url: 'events/eventbasicinfo',
        method: 'POST',
        body: data,
      }),
    }),
    editEventBaseInfo: build.mutation<
      EventCreatedType,
      { eventId: string; basicInfo: EventBasicInfoType }
    >({
      query: (data) => ({
        url: 'events/eventbasicinfo',
        method: 'PATCH',
        body: data,
      }),
    }),
    createEventDetails: build.mutation<EventCreatedType, EventCreateFormDetailsPropsType>({
      query: (data) => ({
        url: 'events/otherdetails',
        method: 'PATCH',
        body: data,
      }),
    }),
    getEvent: build.query<EventResponseType, string>({
      query: (id) => ({
        url: `events/getevent/${id}`,
        method: 'GET',
      }),
    }),
    getEventDetail: build.query<EventCreatedType, string>({
      query: (id) => ({
        url: `events/detail/${id}`,
        method: 'GET',
      }),
      providesTags: ['EventDetail', 'restartEvent', 'pauseEvent', 'deleteEvent'],
    }),
    deleteEvent: build.mutation<void, string>({
      query: (id) => ({
        url: `events/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICES_TAGS.GET_USERS_EVENT],
    }),
    uploadEventFlyer: build.mutation<EventCreatedType, EventFlyerPropsType>({
      query: (data) => ({
        url: 'events/flyer',
        method: 'PATCH',
        body: data,
      }),
    }),
    addedTicketType: build.mutation<EventCreatedType, EventTicketsPropsType>({
      query: (data) => ({
        url: `events/tickettypes`,
        method: 'PATCH',
        body: data,
      }),
    }),
    addedTaxesEvent: build.mutation<EventCreatedType, EventCreateFormTaxesPropsType>({
      query: (data) => ({
        url: `events/taxdetails`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['EventDetail'],
    }),
    publishEvent: build.mutation<EventCreatedType, { id?: string }>({
      query: (body) => ({
        url: `events/publish`,
        method: 'PATCH',
        body,
      }),
    }),
    pauseEvent: build.mutation<EventCreatedType, { id?: string }>({
      query: (body) => ({
        url: `events/pause`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['pauseEvent'],
    }),
    restartEvent: build.mutation<EventCreatedType, { id?: string }>({
      query: (body) => ({
        url: `events/restart`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['restartEvent'],
    }),
    getEventMetrics: build.query<EventMetricsResponseType, string>({
      query: (id) => ({
        url: `eventmetrics/ticketsold`,
        method: 'GET',
        params: {
          id,
        },
      }),
    }),
    getEventTicketMetrics: build.query<
      EventTicketMetricsResponseType,
      { EventId: string; NumberOfPastDays: number }
    >({
      query: (params) => ({
        url: `eventmetrics/ticketsboughtperday`,
        method: 'GET',
        params,
      }),
    }),
    getEventOrders: build.query<OrdersListResponseType, OrdersListPropsType>({
      query: (params) => ({
        url: `events/orders`,
        method: 'GET',
        params,
      }),
    }),
    getEventPermissions: build.query<EventPermissionResponseType, string>({
      query: (id) => ({
        url: `events/permissions/${id}`,
        method: 'GET',
      }),
      providesTags: ['permissions'],
    }),
    setUserEventPermissions: build.mutation<
      EventPermissionResponseType,
      { email: string; eventId: string; roleCode: string }
    >({
      query: (body) => ({
        url: `events/permission`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['permissions'],
    }),
    deleteUserEventPermissions: build.mutation<EventPermissionResponseType, string>({
      query: (id) => ({
        url: `events/permission`,
        method: 'DELETE',
        params: {
          id,
        },
      }),
      invalidatesTags: ['permissions'],
    }),
    acceptTermsAndConditions: build.mutation<EventCreatedType, AcceptTermsAndConditionsPropsType>({
      query: (body) => ({
        url: 'events/accepttermsandconditions',
        method: 'PATCH',
        body,
      }),
    }),
    addedTags: build.mutation<void, { tagName: string }>({
      query: (body) => ({
        url: '/events/suggesttag',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GET_CATEGORIES'],
    }),
    getEventAttendees: build.query<
      EventAttendeesResponseType,
      { id: string; PageNumber?: number; PageSize?: number }
    >({
      query: (params) => ({
        url: `events/attendees`,
        method: 'GET',
        params,
      }),
    }),
    exportAttendees: build.query<
      {
        fileName: string;
      },
      { id: string }
    >({
      query: (params) => ({
        url: `events/exportAttendees`,
        method: 'GET',
        params: {
          EventId: params.id,
        },
      }),
    }),
    getZreading: build.query<ZReadingResponseType, { id: string }>({
      query: (params) => ({
        url: 'events/zreading',
        method: 'GET',
        params,
      }),
    }),
    exportZreading: build.query<
      {
        fileName: string;
      },
      { id: string }
    >({
      query: (params) => ({
        url: 'events/exportZreading',
        method: 'GET',
        params: {
          EventId: params.id,
        },
      }),
    }),
    getTaxReport: build.query<EventTaxReportResponseType, { id: string }>({
      query: (params) => ({
        url: 'events/taxreport',
        method: 'GET',
        params,
      }),
    }),
    exportTaxReport: build.query<
      {
        fileName: string;
      },
      EventTaxReportPropsType
    >({
      query: (body) => ({
        url: 'events/exportTaxReport',
        method: 'POST',
        body,
      }),
    }),
    deleteEventDates: build.mutation<void, string>({
      query: (id) => ({
        url: `events/date/${id}`,
        method: 'DELETE',
      }),
    }),
    getSeatingPlans: build.query<SeatingPlansResponseType, void>({
      query: () => ({
        url: '/seatingplans',
        method: 'GET',
      }),
    }),
    setEventPromoDetails: build.mutation<void, EventPromoDetailsPropsType>({
      query: (body) => ({
        url: 'events/promotedetails',
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetCategoriesQuery,
  useLazyGetTagsQuery,
  useCreateEventBaseInfoMutation,
  useLazyGetEventQuery,
  useLazyGetEventMetricsQuery,
  useLazyGetEventTicketMetricsQuery,
  useUploadEventFlyerMutation,
  useCreateEventDetailsMutation,
  useAddedTicketTypeMutation,
  useAddedTaxesEventMutation,
  useDeleteEventMutation,
  useLazyGetEventDetailQuery,
  useLazyGetEventOrdersQuery,
  useLazyGetEventPermissionsQuery,
  useSetUserEventPermissionsMutation,
  useDeleteUserEventPermissionsMutation,
  usePublishEventMutation,
  usePauseEventMutation,
  useRestartEventMutation,
  useAcceptTermsAndConditionsMutation,
  useEditEventBaseInfoMutation,
  useAddedTagsMutation,
  useLazyGetEventAttendeesQuery,
  useLazyExportAttendeesQuery,
  useLazyGetZreadingQuery,
  useLazyExportZreadingQuery,
  useLazyGetTaxReportQuery,
  useLazyExportTaxReportQuery,
  useDeleteEventDatesMutation,
  useLazyGetSeatingPlansQuery,
  useSetEventPromoDetailsMutation,
} = eventsApiService;
