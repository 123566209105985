import { FC } from 'react';

import { Button, Form, Icon, Input } from 'ui';

import s from './OrdersHeader.module.scss';

const OrdersHeader: FC = () => {
  return (
    <div className={s.header}>
      <div className={s.searchBlock}>
        <Form.Item name="eventName" className={s.range}>
          <Input
            name="eventName"
            placeholder="Enter name, surname, email or order number"
            icon="search"
            fullWidth
          />
        </Form.Item>
      </div>

      <div className={s.actions}>
        <Button color="tertiary">
          <Icon name="refreshArrows" />
        </Button>
      </div>
    </div>
  );
};

export default OrdersHeader;
