import { ReactElement, forwardRef, memo } from 'react';

import cn from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import Icon, { TIconsLiteral } from '../icon';
import Typography, { TypographyTypes } from '../typography';
import s from './Link.module.scss';

export interface LinkProps {
  label: string | ReactElement;
  labelType?: TypographyTypes;
  to: string;
  group?: string;
  icon?: TIconsLiteral;
  isActive?: boolean;
  className?: string;
}

const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { label, to = '', icon, isActive, className = '', labelType } = props;

  const isOuterLink = to.includes('http');

  const linkContent = (
    <>
      {icon && <Icon name={icon} />}
      <Typography type={labelType}>{label}</Typography>
    </>
  );

  if (isOuterLink)
    return (
      <a className={cn(s.link, { [className]: className })} href={to} target="_blank" ref={ref}>
        <Typography type={labelType}>{linkContent}</Typography>
      </a>
    );

  return (
    <RouterLink
      to={to}
      className={cn(s.link, { [s.active]: isActive, [className]: className })}
      ref={ref}>
      <Typography type={labelType}>{linkContent}</Typography>
    </RouterLink>
  );
});

export default memo(Link);
