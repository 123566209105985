import { FC, useContext, useEffect, useState } from 'react';

import { InputNumber } from 'antd';
import 'antd/lib/input-number/style/index';
import cn from 'classnames';
import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { DatePicker, Form, Icon, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { createEventFormBasicInfoContext } from '../../basicInfo.utils';
import { TIME_FORMAT } from '../eventCreateFormBasicInfoDates.utils';
import s from './SingleDate.module.scss';

interface SingleDateProps {
  validateStartDate?: boolean;
}

const SingleDate: FC<SingleDateProps> = (props) => {
  const { validateStartDate } = props;
  const { form } = useContext(createEventFormBasicInfoContext);
  const startDate = Form.useWatch(['showDates', 'startDate'], form);
  const startTime = Form.useWatch(['showDates', 'startTime'], form);
  const endDate = Form.useWatch(['showDates', 'endDate'], form);
  const endTime = Form.useWatch(['showDates', 'endTime'], form);
  const [disabledTimeInfo, setDisabledTimeInfo] = useState<{
    disabledHours: number[];
    disabledMinutes: number[];
    disabledSeconds: number[];
  }>({
    disabledHours: [],
    disabledMinutes: [],
    disabledSeconds: [],
  });
  const [isShowDoorOpened, setIsShowDoorOpened] = useState<boolean>(false);
  const startDateTime = `${dayjs(startDate).format('YYYY-MM-DD')} ${dayjs(startTime).format(
    TIME_FORMAT,
  )}`;
  const endDateTime = `${dayjs(endDate).format('YYYY-MM-DD')} ${dayjs(endTime).format(
    TIME_FORMAT,
  )}`;
  const disabledDate = (current) => {
    return {
      startDate:
        current && endDate
          ? current < dayjs().startOf('day') || current > dayjs(endDate).endOf('day')
          : current < dayjs().startOf('day'),
      endDate: current && current < dayjs(startDate).startOf('day'),
    };
  };

  useEffect(() => {
    const duration = dayjs(endDateTime).diff(dayjs(startDateTime), 'millisecond');
    const eventDuration = {
      days: Math.floor(duration / (1000 * 3600 * 24)),
      hours: Math.floor((duration % (1000 * 3600 * 24)) / (1000 * 3600)),
      mins: Math.floor((duration % (1000 * 3600)) / (1000 * 60)),
    };

    if (endDate && endTime) {
      form?.setFieldValue(['showDates', 'duration', 'days'], eventDuration.days || 0);
      form?.setFieldValue(['showDates', 'duration', 'hours'], eventDuration.hours || 0);
      form?.setFieldValue(['showDates', 'duration', 'minutes'], eventDuration.mins || 0);
    }
  }, [endDate, endDateTime, endTime, form, startDateTime]);

  useEffect(() => {
    if (startTime) {
      form?.setFieldValue(['showDates', 'doorsOpen'], startTime.subtract(1, 'hours'));
    }
  }, [form, startTime]);

  useEffect(() => {
    if (startTime) {
      const hour = startTime.hour();
      const minute = startTime.minute();

      const disabledHours: number[] = [];
      const disabledMinutes: number[] = [];
      const disabledSeconds: number[] = [];

      for (let h = hour + 1; h < 24; h++) {
        disabledHours.push(h);
      }
      if (hour === startTime.hour()) {
        for (let m = minute + 1; m < 60; m++) {
          disabledMinutes.push(m);
        }
      }

      setDisabledTimeInfo({ disabledHours, disabledMinutes, disabledSeconds });
    }
  }, [startTime]);

  return (
    <div className={s.wrapper}>
      <div className={s.item}>
        <div className={s.field}>
          <Typography type="small" className={cn(s.label, s.required)}>
            {t('event_create_form_start_date')}
          </Typography>
          <Form.Item name={['showDates', 'showId']} hidden>
            <div></div>
          </Form.Item>
          <Form.Item
            name={['showDates', 'startDate']}
            valuePropName="value"
            rules={!validateStartDate ? [formRules.required] : []}>
            <DatePicker
              fullWidth
              format="dddd DD MMMM YYYY"
              disabledDate={(current) => disabledDate(current).startDate}
              nextIcon={<Icon name="doubleRightArrow" size={18} />}
              prevIcon={<Icon name="doubleLeftArrow" size={18} />}
            />
          </Form.Item>
        </div>
        <div className={s.field}>
          <Typography type="small" className={cn(s.label, s.required)}>
            {t('event_create_form_start_time')}
          </Typography>
          <Form.Item
            name={['showDates', 'startTime']}
            rules={!validateStartDate ? [formRules.required] : []}>
            <DatePicker.TimePicker fullWidth format={TIME_FORMAT} />
          </Form.Item>
        </div>
      </div>
      <div className={s.item}>
        <div className={s.field}>
          <Typography type="small" className={cn(s.label)}>
            {t('event_create_form_end_date')}
          </Typography>
          <Form.Item name={['showDates', 'endDate']}>
            <DatePicker
              fullWidth
              format="dddd DD MMMM YYYY"
              defaultPickerValue={startDate}
              nextIcon={<Icon name="doubleRightArrow" size={18} />}
              prevIcon={<Icon name="doubleLeftArrow" size={18} />}
              disabledDate={(current) => disabledDate(current).endDate}
            />
          </Form.Item>
        </div>
        <div className={s.field}>
          <Typography type="small" className={cn(s.label)}>
            {t('event_create_form_end_time')}
          </Typography>
          <Form.Item name={['showDates', 'endTime']}>
            <DatePicker.TimePicker fullWidth format={TIME_FORMAT} />
          </Form.Item>
        </div>
      </div>

      <div className={s.item}>
        <div className={s.field}>
          <Typography type="small" className={cn(s.label)}>
            {t('event_create_form_duration')}
          </Typography>
          <div className={s.duration}>
            <Form.Item name={['showDates', 'duration', 'days']}>
              <InputNumber min={0} max={24} className={s.input} />
            </Form.Item>
            <span className={cn(s.label, s.duration)}>days</span>
            <Form.Item name={['showDates', 'duration', 'hours']}>
              <InputNumber min={0} max={24} className={s.input} />
            </Form.Item>
            <span className={cn(s.label, s.duration)}>hrs</span>
            <Form.Item name={['showDates', 'duration', 'minutes']}>
              <InputNumber min={0} max={60} className={s.input} />
            </Form.Item>
            <span className={cn(s.label, s.duration)}>mins</span>
          </div>
        </div>
        {startTime && (
          <>
            {!isShowDoorOpened && (
              <button className={s.openingButton} onClick={() => setIsShowDoorOpened(true)}>
                Add door opening time +
              </button>
            )}
            {isShowDoorOpened && (
              <div className={s.field}>
                <Typography type="small" className={cn(s.label)}>
                  {t('event_create_form_doors_open')}
                </Typography>
                <Form.Item name={['showDates', 'doorsOpen']}>
                  <DatePicker.TimePicker
                    fullWidth
                    format={TIME_FORMAT}
                    defaultValue={dayjs('12:30', 'HH:mm')}
                    disabledTime={() => ({
                      disabledHours: () => disabledTimeInfo.disabledHours,
                      disabledMinutes: () => disabledTimeInfo.disabledMinutes,
                      disabledSeconds: () => disabledTimeInfo.disabledSeconds,
                    })}
                  />
                </Form.Item>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SingleDate;
