import { FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { USER_SETTING_ROUTE } from 'routes/user-management/list';
import { handleCutString } from 'tools/cutstring';
import { useHover } from 'tools/hooks';
import { Icon, Link } from 'ui';

import { PromoterType, UserProfileType } from 'types/core/userTypes';

import s from './AppNavigationUserInfo.module.scss';

interface AppNavigationUserInfoProps {
  user?: UserProfileType;
  isOpenedSidebar: boolean;
}

const AppNavigationUserInfo: FC<AppNavigationUserInfoProps> = (props) => {
  const { user, isOpenedSidebar } = props;
  const navigate = useNavigate();
  const [promoter, setPromoter] = useState<PromoterType | null>(null);
  const hoverRef = useRef(null);
  const isHoverUserInfo = useHover(hoverRef);

  const renderUserLogo = useMemo((): ReactElement => {
    if (promoter && promoter.logo) {
      return <img className={s.logoImg} src={promoter.logo} alt={promoter.name} />;
    } else {
      return <div className={s.logo}>{promoter ? promoter.name[0] : user?.firstName[0]}</div>;
    }
  }, [promoter, user]);

  const handleGoToSetting = useCallback(() => {
    navigate(USER_SETTING_ROUTE);
  }, [navigate]);

  useEffect(() => {
    if (user?.promoters) {
      setPromoter(user.promoters[0]);
    }
  }, [user]);

  const keyPressHandler = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      return;
    }
  };

  return (
    <div className={s.wrapper} ref={hoverRef}>
      <>
        <div
          className={s.promoter}
          onClick={handleGoToSetting}
          role="button"
          tabIndex={0}
          onKeyPress={keyPressHandler}>
          {isHoverUserInfo && (
            <div className={s.settingLogo}>
              <Icon name="setting" size={28} />
            </div>
          )}
          {renderUserLogo}
        </div>
        <div className={s.info}>
          <span>
            {isOpenedSidebar
              ? promoter?.name || user?.fullName
              : handleCutString(promoter?.name || user?.fullName || '', 13)}
          </span>
          {!isHoverUserInfo && (
            <span>{isOpenedSidebar ? user?.email : handleCutString(user?.email || '', 13)}</span>
          )}
          {isHoverUserInfo && (
            <Link
              to={USER_SETTING_ROUTE}
              label="Manage account"
              className={s.link}
              labelType="small"
            />
          )}
        </div>
      </>
    </div>
  );
};

export default AppNavigationUserInfo;
