export enum EVENT_CREATE_EVENT_TICKET_ENUM {
  TICKET_NAME = 'name',
  TICKET_DESCRIPTION = 'description',
  TICKET_PRICE = 'price',
  SORT_ORDER = 'sortOrder',
  MAX_NUMBER_OF_TICKETS_TO_BE_SOLD = 'maxNumberOfTicketsToBeSold',
  SHOW_REMAINING_TICKETS = 'showRemainingTickets',
  HIDE_FROM_PUBLIC = 'hideFromPublic',
  MIN_QTY_PER_ORDER = 'minQtyPerOrder',
  MAX_QTY_PER_ORDER = 'maxQtyPerOrder',
}
