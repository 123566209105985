import React, { FC } from 'react';

import { t } from 'tools/i18n';
import { Icon, Layout, NavLink, Typography } from 'ui';

import { setOpenedSider } from 'slices/systemSlice';
import { useAppDispatch } from 'store/index';

import { UserProfileType } from 'types/core/userTypes';

import s from '../AppNavigation.module.scss';
import AppNavigationUserInfo from '../app-navigation-user-info';
import { NAVIGATIONS_CONFIG } from '../appNavigation.utils';

interface CoreNavigationTemplateProps {
  isOpenedSidebar: boolean;
  currentUser?: UserProfileType;
  apiVersion?: { versionNumber: string };
  handleLogout: () => void;
}

const CoreNavigationTemplate: FC<CoreNavigationTemplateProps> = (props) => {
  const { isOpenedSidebar, currentUser, apiVersion, handleLogout } = props;
  const dispatch = useAppDispatch();

  const handleCloseSidebar = () => {
    if (window.innerWidth < 767) {
      dispatch(setOpenedSider(false));
    }
  };

  return (
    <Layout.Sider opened={isOpenedSidebar}>
      <div className={s.wrapper}>
        <div className={s.logo}>
          <Icon name="logo" width={252} height={40} />
        </div>
        <AppNavigationUserInfo user={currentUser} isOpenedSidebar={isOpenedSidebar} />
        <div className={s.content}>
          {NAVIGATIONS_CONFIG.map((config) => (
            <button key={config.label} className={s.navLinkWrap} onClick={handleCloseSidebar}>
              <NavLink
                label={config.label}
                to={config.to}
                icon={config.icon}
                className={s.link}
                eventCounter={currentUser?.events?.length || 0}
              />
            </button>
          ))}
          <button onClick={handleLogout} className={s.actions}>
            <Typography type="main" weight={500} className={s.logout}>
              <Icon name="logout" />
              {isOpenedSidebar && <span className={s.text}>{t('common_log_out')}</span>}
            </Typography>
          </button>
        </div>

        <div className={s.version}>{`ShowsManager ${apiVersion?.versionNumber || 'v.1.0'}`}</div>
      </div>
    </Layout.Sider>
  );
};

export default CoreNavigationTemplate;
