import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { useSetQuery } from 'tools/hooks';
import { Form } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import { useCreateEventDetailsMutation } from 'services/events/eventsApiService';
import { setActiveEvent } from 'slices/eventsSlice';
import { useAppDispatch, useAppSelector } from 'store';

import FormSubmit from 'containers/form-submit';

import { CREATE_EVENT_FORM_STEPS_ENUM } from 'types/core/systemTypes';
import { EventCreatedType } from 'types/events/eventTypes';

import s from './EventCreateFormDetails.module.scss';
import EventCreateFormDetailsAges from './event-create-form-details-ages';
import EventCreateFormDetailsContacts from './event-create-form-details-contacts';
import EventCreateFormDetailsDescription from './event-create-form-details-description';
import EventCreateFormDetailsSocials from './event-create-form-details-socials';
import EventCreateFormDetailsTerms from './event-create-form-details-terms';
import EventCreateFormDetailsUploadImage from './event-create-form-details-upload-image';
import {
  EVENT_CREATE_FORM_DETAILS_ENUM,
  EventCreateFormDetailsListType,
  createEventFormDetailsContext,
  prepareEventCreateFormDetails,
} from './eventCreateFormDetails.utils';

const EventCreateFormDetails: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingList, setEditingList] = useState<EventCreateFormDetailsListType>({});
  const activeCreatedEvent = useAppSelector(createEventFormActiveEventSelector);
  const [addEventDetails, { isLoading }] = useCreateEventDetailsMutation();
  const setQuery = useSetQuery();

  const handleChange = (_, all) => {
    setIsEditing(true);
    setEditingList(all);
  };

  const handleSubmit = useCallback(
    async (data) => {
      if (activeCreatedEvent?.eventId) {
        const res = await addEventDetails({
          eventId: activeCreatedEvent.eventId,
          otherDetails: prepareEventCreateFormDetails({
            ...data,
          }),
        });

        if ('data' in res) {
          setIsEditing(false);
          dispatch(setActiveEvent(res.data as EventCreatedType));
          setQuery(CREATE_EVENT_FORM_STEPS_ENUM.TICKETS);
        }
      }
    },
    [activeCreatedEvent, addEventDetails, dispatch, setQuery],
  );

  useEffect(() => {
    if (activeCreatedEvent?.eventId && activeCreatedEvent?.otherDetails) {
      const details = activeCreatedEvent.otherDetails;
      for (const key in details) {
        if (details[key]) {
          form.setFieldValue(key, details[key]);
        }
      }

      if (details?.minimumAge) {
        form.setFieldValue(EVENT_CREATE_FORM_DETAILS_ENUM.MINIMUM_AGES, {
          value: details.minimumAge,
        });
      }

      if (details?.agesRequiringTicket) {
        form.setFieldValue(EVENT_CREATE_FORM_DETAILS_ENUM.AGES_REQUIRING_TICKET, {
          value: details.agesRequiringTicket,
        });
      }
    }
  }, [activeCreatedEvent, form]);

  useEffect(() => {
    document.querySelector('#main')?.scrollTo(0, 0);
  }, []);

  return (
    <createEventFormDetailsContext.Provider value={{ form }}>
      <div className={s.wrapper} ref={ref}>
        <Form form={form} onValuesChange={handleChange} onFinish={handleSubmit}>
          <EventCreateFormDetailsUploadImage />
          <EventCreateFormDetailsDescription />
          <EventCreateFormDetailsAges />
          <EventCreateFormDetailsSocials />
          <EventCreateFormDetailsTerms />
          <EventCreateFormDetailsContacts />
          {/*<EventCreateFormDetailsFaq />*/}
          <FormSubmit
            isEditing={isEditing}
            editingList={editingList}
            type="eventDetails"
            fetching={isLoading}
          />
        </Form>
      </div>
    </createEventFormDetailsContext.Provider>
  );
};

export default EventCreateFormDetails;
