import React, { FC, useCallback, useState } from 'react';

// import { Upload, message } from 'antd';
import { Button, Form, Input, Typography, message } from 'ui';

import { createEventFormActiveEventSelector } from 'selectors/eventsSlice.selector';
import { useSetEventPromoDetailsMutation } from 'services/events/eventsApiService';
import { useAppSelector } from 'store/index';

// import SiteFeatures from 'pages/events/events-create-event-form/event-create-form-promote-page/event-create-form-promote-page-features';
import PhoneMockup from 'pages/events/events-create-event-form/event-create-form-promote-page/event-create-form-promote-page-phone/PhoneMockup';
import TicketForm from 'pages/events/events-create-event-form/event-create-form-promote-page/event-create-form-promote-page-tickets';

import s from './EventCreateFormPromotePage.module.scss';

interface EventCreateFormPromotePageProps {
  // Define your component props here
}

const EventCreateFormPromotePage: FC<EventCreateFormPromotePageProps> = () => {
  const activeEvent = useAppSelector(createEventFormActiveEventSelector);
  const [setPromoDetails, { isLoading }] = useSetEventPromoDetailsMutation();
  // const [fileType, setFileType] = useState<'image' | 'video'>();
  const [ticketsForGiveAway, setTicketsForGiveAway] = useState<
    { name: string; price: number; amount: number; id: string }[]
  >([]);
  // const [fileUrl, setFileUrl] = useState<string>();
  const [artist, setArtist] = useState<
    {
      artistName: string;
      artistTag: string;
      artistSite: string;
    }[]
  >([]);
  const [sponsor, setSponsor] = useState<
    {
      sponsorName: string;
      sponsorTag: string;
      sponsorSite: string;
    }[]
  >([]);
  const [artistForm] = Form.useForm();
  const [sponsorForm] = Form.useForm();
  // const [featuresForm] = Form.useForm();

  const handleSend = useCallback(async () => {
    const res = await setPromoDetails({
      eventId: activeEvent?.eventId || '',
      promoteDetails: {
        ticketTypesToConsiderForPromotionalOffers: ticketsForGiveAway.map((item) => ({
          qty: item.amount,
          name: item.name,
          id: item.id,
        })),
        eventArtistTags: artist.map((item) => ({
          name: item.artistName,
          atName: item.artistTag,
          url: item.artistSite,
        })),
        eventSponsorTags: sponsor.map((item) => ({
          name: item.sponsorName,
          atName: item.sponsorTag,
          url: item.sponsorSite,
        })),
      },
    });

    if ('data' in res) {
      message.success('Promo data successfully added!');
    }
  }, [setPromoDetails, activeEvent, ticketsForGiveAway, artist, sponsor]);

  // const customRequest = async (options) => {
  //   const { file, onSuccess, onError } = options;
  //
  //   try {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //
  //     const response = await fetch('https://luhchenko.xyz/file/upload', {
  //       method: 'POST',
  //       body: formData,
  //     });
  //
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //
  //     const responseData = await response.json();
  //     onSuccess(responseData, file);
  //     if (responseData?.url) {
  //       setFileUrl(responseData.url);
  //     }
  //   } catch (error) {
  //     onError(error);
  //   }
  // };

  const handleAddTagsInfo = (data, field) => {
    if (field === 'artist') {
      setArtist((prev) => [...prev, data]);
      artistForm.resetFields();
    }

    if (field === 'sponsor') {
      setSponsor((prev) => [...prev, data]);
      sponsorForm.resetFields();
    }
  };

  return (
    <div className={s.wrapper}>
      <Typography type="h2" className={s.title}>
        Promote your event
      </Typography>
      <div className={s.content}>
        <div className={s.container}>
          <div>
            <div className={s.block}>
              <div className={s.subtitle}>
                We can help you connect with the right audiences to boost your tickets sales.
                Included Promotion (free of charge)
              </div>
            </div>

            {/*<div className={s.uploadWrapper}>*/}
            {/*  <Typography type="h3" weight={500}>*/}
            {/*    Promotional images / video (1080х1920)*/}
            {/*  </Typography>*/}
            {/*  <Typography type="main" color="grey">*/}
            {/*    Upload any promotional photo or videos you may have relating to your event and we’ll*/}
            {/*    consider sharing these on our Social Media channels as unsponsored posts at no*/}
            {/*    additional charge. T&Cs apply.*/}
            {/*  </Typography>*/}
            {/*  <div className={s.upload}>*/}
            {/*    <Upload*/}
            {/*      customRequest={customRequest}*/}
            {/*      accept=".jpg, .jpeg, .png, .gif, .mp4"*/}
            {/*      maxCount={1}*/}
            {/*      showUploadList={false}*/}
            {/*      beforeUpload={(file) => {*/}
            {/*        const isImageOrVideo =*/}
            {/*          file.type.startsWith('image/') || file.type.startsWith('video/');*/}
            {/*        setFileType(file.type.startsWith('image/') ? 'image' : 'video');*/}
            {/*        if (!isImageOrVideo) {*/}
            {/*          message.error('Only images and video files can be uploaded!');*/}
            {/*        }*/}
            {/*        return isImageOrVideo;*/}
            {/*      }}*/}
            {/*      onChange={(info) => {*/}
            {/*        if (info.file.status === 'done') {*/}
            {/*          message.success(`${info.file.name} success upload!`);*/}
            {/*        } else if (info.file.status === 'error') {*/}
            {/*          message.error(`${info.file.name} the download failed.`);*/}
            {/*        }*/}
            {/*      }}>*/}
            {/*      <div className={s.uploadBlock}>*/}
            {/*        <Typography type="main" color="grey">*/}
            {/*          Drag & Drop files here or*/}
            {/*        </Typography>*/}
            {/*        <Button>Browse Files</Button>*/}
            {/*      </div>*/}
            {/*    </Upload>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>

          <Form
            className={s.fullWidth}
            onFinish={(data) => handleAddTagsInfo(data, 'artist')}
            form={artistForm}>
            <div className={s.subtitle}>Artists Tags</div>
            <div className={s.inputBlock}>
              <Typography type="main" weight={500}>
                Confirm the event artists Social Media accounts and website so we may promote your
                event on social media.
              </Typography>
              <div className={s.form}>
                <Form.Item name="artistName">
                  <Input name="artistName" placeholder="Michael Jackson" />
                </Form.Item>
                <Form.Item name="artistTag">
                  <Input name="artistTag" placeholder="@michaeljackson" />
                </Form.Item>
                <Form.Item name="artistSite">
                  <Input name="artistSite" placeholder="michaeljackson.com" />
                </Form.Item>
              </div>
              <div>
                <Button type="primary" htmlType="submit" disabled={artist.length === 2}>
                  + Add artist
                </Button>
              </div>
            </div>
          </Form>

          <Form
            className={s.fullWidth}
            onFinish={(data) => handleAddTagsInfo(data, 'sponsor')}
            form={sponsorForm}>
            <div className={s.subtitle}>Sponsor Tags</div>
            <div className={s.inputBlock}>
              <Typography type="main" weight={500}>
                Confirm the event’s sponsors Social Media accounts and website.
              </Typography>
              <div className={s.form}>
                <Form.Item name="sponsorName">
                  <Input name="sponsorName" placeholder="Coca-Cola" />
                </Form.Item>
                <Form.Item name="sponsorTag">
                  <Input name="sponsorTag" placeholder="@cocacola" />
                </Form.Item>
                <Form.Item name="sponsorSite">
                  <Input name="sponsorSite" placeholder="www.coca-cola.com" />
                </Form.Item>
              </div>
              <div>
                <Button type="primary" htmlType="submit" disabled={sponsor.length === 2}>
                  + Add sponsor
                </Button>
              </div>
            </div>
          </Form>
          <div className={s.fullWidth}>
            <div className={s.subtitle}>Ticket give-aways on social media</div>
            <Typography type="main" weight={500}>
              Offer up some complimentary tickets which we will use to create hype around your
              event. This could take the form of a competition or other types of online engagement.
              Let us know the quantity and type of complimentary tickets that we can use to promote
              your event and we’ll be in touch with a proposed way forward.
            </Typography>
            <TicketForm tickets={activeEvent?.ticketTypes} setTicket={setTicketsForGiveAway} />
          </div>
        </div>
        <PhoneMockup artistTag={artist} sponsorTag={sponsor} />
      </div>
      {/*<div className={s.fullWidth} style={{ marginTop: 32 }}>*/}
      {/*  <div className={s.subtitle}>ShowsHappening.com website features</div>*/}
      {/*  <Form form={featuresForm}>*/}
      {/*    <SiteFeatures />*/}
      {/*  </Form>*/}
      {/*</div>*/}

      <Button
        htmlType="submit"
        size="large"
        block
        type="primary"
        className={s.button}
        onClick={handleSend}
        isLoading={isLoading}>
        <Typography type="main">Send</Typography>
      </Button>
    </div>
  );
};

export default EventCreateFormPromotePage;
