export const MY_EVENTS_ROUTE = '/my-events';

export const CREATE_EVENT_FORM_ROUTE = `${MY_EVENTS_ROUTE}/create-event`;

export const EDIT_EVENT_FORM_ROUTE = `${MY_EVENTS_ROUTE}/edit-event/:eventId`;
export const ATTENDEES_EVENT_ROUTE = `${MY_EVENTS_ROUTE}/attendees/:eventId`;
export const ZREADING_EVENT_ROUTE = `${MY_EVENTS_ROUTE}/zreading/:eventId`;

export const EVENT_TAX_REPORT_ROUTE = `${MY_EVENTS_ROUTE}/tax-report/:eventId`;

export const getEditEventFormRoute = (eventId: string) =>
  EDIT_EVENT_FORM_ROUTE.replace(':eventId', eventId);

export const getAttendeesRoute = (eventId: string) => `${MY_EVENTS_ROUTE}/attendees/${eventId}`;

export const getZreadingRoute = (eventId: string) => `${MY_EVENTS_ROUTE}/zreading/${eventId}`;

export const getTaxReportRoute = (eventId: string) => `${MY_EVENTS_ROUTE}/tax-report/${eventId}`;
