import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';
import { CREATE_EVENT_FORM_ROUTE } from 'routes/events/list';
import { t } from 'tools/i18n';
import { Button, Checkbox, Modal, Typography } from 'ui';

import { useAcceptTermsAndConditionsMutation } from 'services/events/eventsApiService';
import { AcceptTermsAndConditionsPropsType } from 'services/events/evetns.api.type';

import { EventCreatedType } from 'types/events/eventTypes';
import { PromoterFullInfoType } from 'types/promoters/promoterTypes';

import s from '../../PublishEventModal.module.scss';
import { PublishEventModalStepsType, TERMS_LINKS_ENUM } from '../../publishEventModal.utils';
import PublishEventModalContent from './publish-event-modal-content';

interface PublishEventModalFirstStepProps {
  handleClose: () => void;
  setActiveStep: Dispatch<SetStateAction<PublishEventModalStepsType>>;
  promoter?: PromoterFullInfoType;
  activeEvent?: EventCreatedType;
  isChargeVat: boolean;
}

const PublishEventModalFirstStep: FC<PublishEventModalFirstStepProps> = ({
  handleClose,
  setActiveStep,
  promoter,
  activeEvent,
  isChargeVat,
}) => {
  const navigate = useNavigate();
  const [isReadTerms, setIsReadTerms] = useState<boolean>(false);
  const [checkedTerms, setCheckedTerms] = useState<boolean>(false);
  const [isTermsViewed, setIsTermsViewed] = useState<boolean>(false);
  const [acceptTerms] = useAcceptTermsAndConditionsMutation();
  const acceptedTermsPayload = useMemo(
    () => ({
      id: activeEvent?.eventId,
      permitNumber: activeEvent?.taxDetails?.permitNumber,
      vatNumber: promoter?.billingVATNumber,
      promoterCompanyName: promoter?.name,
      promoterAddress: promoter?.address,
      promoterTown: promoter?.town,
      promoterMobileNumber: promoter?.mobileNumber,
      promoterEmail: promoter?.email,
      promoterPostCode: promoter?.postcode,
      ibanNumber: promoter?.ibanNumber,
      bankAccountHolderName: promoter?.bankHolderName,
      chargeVAT: activeEvent?.taxDetails?.permitNumber === null ? false : isChargeVat,
    }),
    [activeEvent, isChargeVat, promoter],
  );

  const handleAcceptTerms = useCallback(async () => {
    const res = await acceptTerms(acceptedTermsPayload as AcceptTermsAndConditionsPropsType);

    if ('data' in res) {
      setActiveStep('second');
    }
  }, [acceptTerms, acceptedTermsPayload, setActiveStep]);

  const scrollableElRef = useRef<HTMLDivElement>(null);

  const handleCloseModal = () => {
    setIsTermsViewed(false);
    setIsReadTerms(false);
    setCheckedTerms(false);
    navigate(CREATE_EVENT_FORM_ROUTE);
    handleClose();
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollableEl = scrollableElRef.current;
      if (scrollableEl) {
        const scrollTop = scrollableEl.scrollTop;
        const scrollHeight = scrollableEl.scrollHeight;
        const clientHeight = scrollableEl.clientHeight;
        const bottomThreshold = scrollHeight - clientHeight;

        if (scrollTop >= bottomThreshold - 300) {
          setIsReadTerms(true);
          setIsTermsViewed(true);
        } else {
          if (!isTermsViewed) {
            setIsReadTerms(false);
          }
        }
      }
    };

    const scrollableEl = scrollableElRef.current;

    if (scrollableEl) {
      scrollableEl.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableEl) {
        scrollableEl.removeEventListener('scroll', handleScroll);
      }
    };
  }, [setIsReadTerms, scrollableElRef, isTermsViewed]);

  return (
    <>
      <Modal.Header>
        <Typography type="h3" className={s.modalText}>
          {t('publish_event_modal_title')}
        </Typography>
      </Modal.Header>

      <Modal.Content>
        <Typography type="main" color="grey" className={s.modalText}>
          Effective March 31, 2020 | <span className={s.pdf}>Download PDF</span>
        </Typography>

        <div className={s.content} ref={scrollableElRef}>
          <div className={s.hint}>
            <Typography type="main" className={s.modalText}>
              {t('publish_event_modal_hint')}
            </Typography>
          </div>

          <Typography type="h5" className={s.modalText}>
            {t('publish_event_modal_covered_terms')}
          </Typography>

          <ol className={s.list}>
            {Object.values(TERMS_LINKS_ENUM).map((label, i) => (
              <a key={label} href={`#service_${i + 1}`} className={s.link}>
                <Typography type="main" weight={600} className={s.listItem}>
                  <li>{label}</li>
                </Typography>
              </a>
            ))}
          </ol>

          <PublishEventModalContent />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div className={s.actionsBlock}>
          <Checkbox value={checkedTerms} onChange={setCheckedTerms} disabled={!isReadTerms}>
            I have read and agree to the ShowsHappening Terms of Service.
          </Checkbox>
          <div className={s.actions}>
            <Button variant="outline" color="grey" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button disabled={!isReadTerms || !checkedTerms} onClick={handleAcceptTerms}>
              Proceed
            </Button>
          </div>
        </div>
      </Modal.Actions>
    </>
  );
};

export default PublishEventModalFirstStep;
