import { PropsWithChildren, forwardRef, memo, useMemo } from 'react';

import { Button as ButtonAntd, ButtonProps as ButtonPropsAntd } from 'antd';
import 'antd/lib/button/style/index';
import type { SizeType } from 'antd/lib/config-provider/SizeContext';
import cn from 'classnames';

import Icon, { TIconsLiteral } from '../icon';
import s from './Button.module.scss';

export type ButtonSizeType = SizeType | 'smallSquare';

export interface ButtonProps extends PropsWithChildren, Omit<ButtonPropsAntd, 'size'> {
  onClick?: () => void;
  fullWidth?: boolean;
  icon?: TIconsLiteral;
  variant?: 'default' | 'outline';
  iconColor?: string;
  isLoading?: boolean;
  size?: ButtonSizeType;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    children,
    fullWidth = false,
    className = '',
    size = 'middle',
    color = 'primary',
    variant = 'default',
    disabled,
    icon,
    iconColor,
    htmlType = 'button',
    onClick,
    isLoading = false,
    ...otherProps
  } = props;
  const onlyIcon = useMemo(() => !!icon && !children, [icon, children]);

  return (
    <ButtonAntd
      className={cn(s.wrapper, s[size], s[color], {
        [s.outline]: variant === 'outline',
        [s.hidePointer]: !onClick || disabled,
        [s.isLoading]: isLoading,
        [s.withIcon]: !!icon,
        [s.fullWidth]: fullWidth,
        [s.onlyIcon]: onlyIcon,
        [className]: className,
      })}
      ref={ref}
      onClick={onClick}
      disabled={!!disabled || isLoading}
      htmlType={htmlType}
      {...otherProps}>
      {isLoading ? <div className={s.dotFlash} /> : children}
      {icon && <Icon className={s.icon} color={iconColor} name={icon} size={24} />}
    </ButtonAntd>
  );
});

export default memo(Button);
