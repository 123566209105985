import { FC } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';
import { Form, Input, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { EMAIL_PLACEHOLDER } from 'constants/core-constants';

import s from '../OrganiserDetails.module.scss';
import { ORGANISER_DETAILS_FORM } from '../organiserDetailsForm.utils';

const NameInputs: FC = () => {
  return (
    <div className={s.column}>
      <div>
        <Typography type="small" className={cn(s.label, s.required)}>
          {t('common_company_name')}
        </Typography>
        <Form.Item
          fullWidth
          name={ORGANISER_DETAILS_FORM.COMPANY_NAME}
          rules={[formRules.required]}>
          <Input name={ORGANISER_DETAILS_FORM.COMPANY_NAME} placeholder="Example LTD" />
        </Form.Item>
      </div>

      <div>
        <Typography type="small" className={cn(s.label, s.required)}>
          {t('common_company_email')}
        </Typography>
        <Form.Item
          fullWidth
          name={ORGANISER_DETAILS_FORM.COMPANY_EMAIL}
          className={s.required}
          rules={[formRules.required, formRules.email]}>
          <Input name={ORGANISER_DETAILS_FORM.COMPANY_EMAIL} placeholder={EMAIL_PLACEHOLDER} />
        </Form.Item>
      </div>
    </div>
  );
};

export default NameInputs;
