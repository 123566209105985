import { FC } from 'react';

import { Trans } from 'react-i18next';
import mt from 'react-phone-input-2';
import PhoneInput from 'react-phone-input-2';
import { t } from 'tools/i18n';
import { Form, Icon, Input, Label, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import { EVENT_CREATE_FORM_DETAILS_ENUM } from '../eventCreateFormDetails.utils';
import s from './EventCreateFormDetailsContacts.module.scss';

const EventCreateFormDetailsContacts: FC = () => (
  <div className={s.wrapper}>
    <Typography type="h2" className={s.title}>
      {t('common_contact_details')}
    </Typography>
    <Typography type="main">{t('event_create_form_contact_details_text')}</Typography>

    <div className={s.form}>
      <div className={s.block}>
        <div>
          <Label required>{t('common_email')}</Label>
          <Form.Item
            name={EVENT_CREATE_FORM_DETAILS_ENUM.CONTACT_EMAIL}
            rules={[formRules.required, formRules.email]}>
            <Input name={EVENT_CREATE_FORM_DETAILS_ENUM.CONTACT_EMAIL} />
          </Form.Item>
        </div>
        <div>
          <Label required>{t('common_phone_number')}</Label>
          <Form.Item
            name={EVENT_CREATE_FORM_DETAILS_ENUM.CONTACT_PHONE}
            rules={[formRules.required]}>
            <PhoneInput
              containerClass={s.phoneContainer}
              inputClass={s.phone}
              buttonClass={s.phoneButton}
              regions="europe"
              preferredCountries={['mt']}
              country="mt"
              localization={mt}
            />
          </Form.Item>
        </div>
      </div>
      <div className={s.hintBlock}>
        <div className={s.hint}>
          <div>
            <Icon name="warning" />
          </div>
          <Trans i18nKey="event_create_form_contact_hint" components={{ b: <b /> }} />
        </div>
      </div>
    </div>
  </div>
);
export default EventCreateFormDetailsContacts;
