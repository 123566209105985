import { FC, SVGProps } from 'react';

import { ReactComponent as Benefits } from './benefit.svg';
import { ReactComponent as BrowseEvent } from './browse-event.svg';
import { ReactComponent as BuyTickets } from './buy-tickets.svg';
import { ReactComponent as LatestEvent } from './latest-event.svg';
import { ReactComponent as SellTickets } from './sell-ticket.svg';

export const RegisterIcons: Record<string, FC<SVGProps<SVGSVGElement>>> = {
  registerLatestEvent: LatestEvent,
  registerBuyTickets: BuyTickets,
  registerSellTickets: SellTickets,
  registerBrowseEvent: BrowseEvent,
  registerBenefits: Benefits,
};
