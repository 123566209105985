import { FC } from 'react';

import cn from 'classnames';
import mt from 'react-phone-input-2';
import PhoneInput from 'react-phone-input-2';
import { t } from 'tools/i18n';
import { Form, Input, Typography } from 'ui';
import { formRules } from 'utils/formRules';

import s from '../OrganiserDetails.module.scss';
import { ORGANISER_DETAILS_FORM } from '../organiserDetailsForm.utils';

const AddressInfo: FC = () => {
  return (
    <>
      <div>
        <Typography type="small" className={cn(s.label, s.required)}>
          {t('common_company_address')}
        </Typography>
        <Form.Item name={ORGANISER_DETAILS_FORM.COMPANY_ADDRESS} rules={[formRules.required]}>
          <Input name={ORGANISER_DETAILS_FORM.COMPANY_ADDRESS} />
        </Form.Item>
      </div>

      <div className={s.row}>
        <div>
          <Typography type="small" className={cn(s.label, s.required)}>
            {t('common_town')}
          </Typography>
          <Form.Item name={ORGANISER_DETAILS_FORM.COMPANY_TOWN} rules={[formRules.required]}>
            <Input name={ORGANISER_DETAILS_FORM.COMPANY_TOWN} />
          </Form.Item>
        </div>
        <div>
          <Typography type="small" className={cn(s.label)}>
            {t('common_postcode')}
          </Typography>
          <Form.Item name={ORGANISER_DETAILS_FORM.COMPANY_POSTCODE}>
            <Input name={ORGANISER_DETAILS_FORM.COMPANY_POSTCODE} />
          </Form.Item>
        </div>
      </div>
      <div>
        <Typography type="small" className={s.label}>
          {t('common_country')}
        </Typography>
        <Form.Item name={ORGANISER_DETAILS_FORM.COMPANY_COUNTRY} initialValue="Malta">
          <Input name={ORGANISER_DETAILS_FORM.COMPANY_COUNTRY} />
        </Form.Item>
      </div>
      <div>
        <Typography type="small" className={cn(s.label, s.required)}>
          {t('common_phone_number')}
        </Typography>
        <Form.Item name={ORGANISER_DETAILS_FORM.COMPANY_PHONE} rules={[formRules.required]}>
          <PhoneInput
            containerClass={s.phoneContainer}
            inputClass={s.phone}
            buttonClass={s.phoneButton}
            regions="europe"
            preferredCountries={['mt']}
            country="mt"
            localization={mt}
          />
        </Form.Item>
      </div>
    </>
  );
};

export default AddressInfo;
