import { FC, useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { CREATE_EVENT_FORM_ROUTE } from 'routes/events/list';
import { handleCutString } from 'tools/cutstring';
import { extractFlyerName } from 'tools/extractFlyerName';
import { useQuery } from 'tools/hooks';
import { t } from 'tools/i18n';
import { Button, Icon, Input, Popover } from 'ui';

import { useLazyGetCurrentUserQuery } from 'services/user-management/userManagementApiService';

import { UserEventType } from 'types/core/userTypes';

import s from './EventPopover.module.scss';

interface EventPopoverProps {
  handleEvent: (event: UserEventType) => void;
}

const EventPopover: FC<EventPopoverProps> = (props) => {
  const { handleEvent } = props;
  const [getCurrentUser, { data: currentUser }] = useLazyGetCurrentUserQuery();
  const [openedPopover, setOpenedPopover] = useState(false);
  const [activeEvent, setActiveEvent] = useState<UserEventType>();
  const [isSearchDirty, setIsSearchDirty] = useState(false);
  const navigate = useNavigate();
  const [searchedEvents, setSearchedEvents] = useState<UserEventType[]>([]);
  const goToEventCreate = () => navigate(CREATE_EVENT_FORM_ROUTE);
  const query = useQuery();
  const location = useLocation();

  const handleSetActiveEvent = useCallback(
    (event: UserEventType) => {
      setActiveEvent(event);
      handleEvent(event);
      setOpenedPopover(false);
      navigate(`${location.pathname}?event=${event.eventId}`);
    },
    [handleEvent, location.pathname, navigate],
  );

  const searchEventsByLetter = useCallback(
    (letter) => {
      setIsSearchDirty(true);
      const lowercaseLetter = letter.toLowerCase(); // Convert the letter to lowercase for case-insensitive matching
      const matchingEvents: UserEventType[] = [];

      if (currentUser?.events) {
        currentUser.events.forEach((event) => {
          if (event.eventName.toLowerCase().startsWith(lowercaseLetter)) {
            matchingEvents.push(event);
          }
        });
      }

      return matchingEvents;
    },
    [currentUser?.events],
  );

  const handleSearch = (event) => {
    const letter = event.target.value;
    setSearchedEvents(searchEventsByLetter(letter));
  };

  useEffect(() => {
    if (!searchedEvents?.length && !isSearchDirty) {
      setSearchedEvents(currentUser?.events || []);
    }
  }, [currentUser?.events, isSearchDirty, searchedEvents?.length]);

  const content = (
    <div className={s.contentWrapper}>
      <div className={s.search}>
        <Input
          size="small"
          name="search"
          placeholder="Search event"
          icon="search"
          onChange={handleSearch}
        />
      </div>
      <div className={s.eventsList}>
        {searchedEvents?.map((event) => (
          <button
            key={event.eventId}
            className={s.event}
            onClick={() => handleSetActiveEvent(event)}>
            {extractFlyerName(event.eventFlyer) ? (
              <img src={event.eventFlyer} alt="flyer" className={s.img} />
            ) : (
              <div className={s.emptyFlyer}>
                <Icon name="littleLogo" />
              </div>
            )}

            {handleCutString(event?.eventName, 35, true)}
          </button>
        ))}
      </div>
      <Button fullWidth className={s.createButton} onClick={goToEventCreate}>
        {t('common_create_event')}
      </Button>
    </div>
  );

  useEffect(() => {
    getCurrentUser();
  }, [getCurrentUser]);

  useEffect(() => {
    if (currentUser?.events?.length && !activeEvent?.eventId) {
      setActiveEvent(currentUser?.events[0]);
      handleEvent(currentUser?.events[0]);
    }
  }, [activeEvent, currentUser, handleEvent, setActiveEvent]);

  useEffect(() => {
    const eventId = query.get('event');
    if (eventId) {
      const event = searchedEvents?.find((event) => event.eventId === eventId);
      if (event) {
        setActiveEvent(event);
        handleEvent(event);
      }
    }
  }, [currentUser, handleEvent, query, searchedEvents]);

  return (
    <Popover isOpened={openedPopover} handleOpenChange={setOpenedPopover} circle content={content}>
      <div className={cn(s.event)}>
        {extractFlyerName(activeEvent?.eventFlyer || '') ? (
          <img src={activeEvent?.eventFlyer} alt="flyer" className={s.img} />
        ) : (
          <div className={s.emptyFlyer}>
            <Icon name="littleLogo" />
          </div>
        )}
        <div className={s.eventButton}>
          {activeEvent && handleCutString(activeEvent?.eventName, 35, true)}
          <Icon name="arrowDropdown" className={cn({ [s.arrowIcon]: openedPopover })} />
        </div>
      </div>
    </Popover>
  );
};

export default EventPopover;
