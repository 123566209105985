import { FC, useMemo } from 'react';

import { formatNumber } from 'tools/formatNumbers';
import { Typography } from 'ui';

import { UserEventType } from 'types/core/userTypes';
import { EventMetricsType } from 'types/events/eventTypes';

import s from './DashboardSummaryStats.module.scss';

interface DashboardSummaryStatsProps {
  activeEvent?: UserEventType;
  eventMetricData?: EventMetricsType;
}

const DashboardSummaryStats: FC<DashboardSummaryStatsProps> = (props) => {
  const { eventMetricData } = props;

  const eventSalesStats = useMemo(() => {
    if (eventMetricData?.eventId) {
      return [
        {
          all: `€ ${formatNumber(eventMetricData.totalSales.allTime)}`,
          today: `€ ${formatNumber(eventMetricData.totalSales.today)}`,
          label: 'Total Revenue',
        },
        {
          all: eventMetricData?.totalTicketsSold.allTime,
          today: eventMetricData?.totalTicketsSold.today,
          label: 'Tickets Sold',
        },
        {
          all: eventMetricData.totalComplimentariesIssued.allTime,
          today: eventMetricData.totalComplimentariesIssued.today,
          label: 'Complimentary',
        },
      ];
    }
  }, [eventMetricData]);

  return (
    <div className={s.stats}>
      {!!eventSalesStats?.length &&
        eventSalesStats.map((item, i) => (
          <div key={i} className={s.item}>
            <Typography type="h5" color="grey" className={s.title}>
              {item.label}
            </Typography>
            <Typography type="h4" className={s.subtitle}>
              {item?.all}
            </Typography>
            <Typography type="main" weight={500} className={s.value}>
              <span className={s.green}>{item?.today}</span> today
            </Typography>
          </div>
        ))}
    </div>
  );
};

export default DashboardSummaryStats;
